footer{
	position: relative;
	z-index: 5;

	&.careers-footer{

		.footer-top-outer{
	
			.container{
				padding-bottom: 4.5rem;
			}
		}
	}

	.footer-top-outer{
		background-color: $blue-dark;

		.container{
			padding-top: 5.7rem;
			padding-bottom: 5.4rem;
			max-width: 130.8rem;

			@media (max-width: $md){
				padding-top: 5.1rem;
				padding-bottom: 5rem;
			}
		}

		*{
			color: $white;
		}

		#footer-logo{
			display: block;
			height: auto;
			margin: 0;
			z-index: 2;
			transition: 0s;
			display: flex;
			width: 10.9rem;
			transition: $time;
		
			@media (max-width: $md){
				width: 10.9rem;
				z-index: 3;
				position: relative;
			}
		
			img,
			svg{
				width: 100%;
			}
		}

		.logo-con{
			
			@media (max-width: $md){
				order: 1;
			}
		}

		.social-con{
			align-self: center;

			@media (max-width: $md){
				order: 3;
				margin-top: 4.8rem;
			}
		}

		.social-menu{
			justify-content: flex-end;

			@media (max-width: $md){
				justify-content: center;

			}
		}

		.sign-con{
			margin-top: 6.6rem;
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			@media (max-width: $md){
				order: 8;
				margin-top: 5.7rem;
			}

			@media (min-width: $md + 1){
				flex: 0 0 48%;
				max-width: 48%;

				& + .menu-con.menu-con{
					flex: 0 0 52%;
					max-width: 52%;
				}
			}

			.btn{
				margin-top: .3rem;

				@media (max-width: $md){
					margin-top: 3.4rem;
					width: 100%;
				}
			}

			.copy{
				@include font-size(18);
				@include line-height(18,21);
				width: 62.9%;

				@media (max-width: $md){
					width: 100%;
				}
			}

		}

		.form-con{
			margin-top: 6.6rem;

			@media (min-width: $md + 1){
				flex: 0 0 45.9%;
				max-width: 45.9%;
				padding-right: 28rem;
			}

			@media (max-width: $md){
				order: 4;
				margin-top: 5.5rem;
			}

			p.medium{
				@include line-height(18,21);
				margin-bottom: 1em;
			}

			.gfield{

				::-webkit-input-placeholder {
					@include font-size(18);
					color: rgba($white,.25);
				}
				 
				:-moz-placeholder { /* Firefox 18- */
					@include font-size(18);
					color: rgba($white,.25);
				}
				
				::-moz-placeholder {  /* Firefox 19+ */
					@include font-size(18);
					color: rgba($white,.25);
				}
				
				:-ms-input-placeholder {
					@include font-size(18);
					color: rgba($white,.25);
				}

				input{
					@include font-size(18);
					border-bottom: .1rem solid $blue-light;
					padding: 1rem 0rem;
				}
	
				.gfield_validation_message{
					width: 100%;
				}
		
				.ginput_container{
					width: auto;
					flex-grow: 1;
				}
			}
	
			.gform_footer{
				margin-top: .9rem;
				display: flex;
				flex-wrap: wrap;

				@media (max-width: $md){
					margin-top: .5rem;	
				}

				[type="submit"]{
					border-color: $blue-light;
					background-color: $blue-light;
					padding-left: 2.7rem;
					padding-right: 2.7rem;
	
					&:hover,
					&:focus-visible{
						border-color: $blue;
						background-color: $blue;
					}
				}
			}
		}

		.menu-con{
			margin-top: 6.7rem;

			@media (min-width: $md + 1){
				flex: 0 0 9.875%;
				max-width: 9.875%;

				&:nth-of-type(4){
					flex: 0 0 14.6%;
					max-width: 14.6%;
				}
			}

			@media (max-width: $md){
				order: 2;
				margin-top: 5rem;

				+ .menu-con{
					margin-top: 1.5rem;
				}
			}
		}

		h6{
			@include font-size(16);
			@include line-height(16,20);
			color: $blue-light;
			margin-bottom: 1.9em;

			@media (max-width: $md){
				@include font-size(18);
				@include line-height(18,23);
				margin-bottom: 1.1em;
				padding-right: 2.5rem;
				position: relative;
				cursor: pointer;

				&::after{
					content: '';
					position: absolute;
					top: 50%;
					right: .3rem;
					transform: translateY(-50%) rotateX(0deg);
					backface-visibility: visible!important;
					background-image: url(../images/select-icon-white.svg);
					background-repeat: no-repeat;
					aspect-ratio: 11.64/6.3;
					width: 1.3rem;
					background-size: 100% auto;
				}

				&.open{

					&:after{
						transform: translateY(-50%) rotateX(180deg);
					}

					+ .footer-menu{
						padding-top: .2rem;
						padding-bottom: .9rem;
						max-height: 30rem;
					}
				}
			}
		}

		.footer-menu{
			@include font-size(14);
			@include line-height(14,16);
			display: flex;
			flex-wrap: wrap;

			@media (max-width: $md){
				padding-top: 0rem;
				padding-bottom: 0rem;
				border-bottom: 1px solid rgba($white,.3);
				max-height: 0;
				overflow: hidden;
				transition: $time;
			}

			li{
				@include font-size(14);
				@include line-height(14,16);
				display: block;
				width: 100%;
				margin-bottom: 1.1rem;

				&.menu-item-has-children{
					display: none
				}
			}

			a{
				@include font-size(14);
				@include line-height(14,16);
				width: auto;
				color: $white;
				text-decoration: none!important;
				font-weight: 400;
				position: relative;
				font-family: $alt-font;
				padding: 0;

				&:hover,
				&:focus-visible{
					color: $blue-light;
				}
			}
		}

		.simple-menu{
			@include font-size(16);
			@include line-height(16,20);
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-end;

			@media (max-width: $md){
				justify-content: flex-start;
				margin-top: -1.6rem;
			}

			li{
				@include font-size(16);
				@include line-height(16,20);
				display: block;

				@media (max-width: $md){
					@include font-size(18);
					@include line-height(18,23);
				}

				@media (max-width: $md){
					width: 100%;
				}

				&.menu-item-has-children{
					display: none
				}

				@media (min-width: $md + 1){
					
					+ li{
						margin-left: 4.05rem;
					}
				}
			}

			a{
				@include font-size(16);
				@include line-height(16,20);
				width: auto;
				color: $blue-light;
				text-decoration: none!important;
				font-weight: 600;
				position: relative;
				font-family: $alt-font;
				padding: 0;

				@media (max-width: $md){
					@include font-size(18);
					@include line-height(18,23);
					padding: 1.75rem 0;
					display: inline-block;
					border-bottom: 1px solid rgba($white,.3);
					width: 100%;
				}

				&:hover,
				&:focus-visible{
					color: #88D1FC;
				}
			}
		}
	}

	.footer-bottom-outer{
		background-color: $blue;

		.container{
			padding-top: 2.4rem;
			padding-bottom: 2.4rem;
			max-width: 130.8rem;

			@media (max-width: $md){
				padding-top: 1.8rem;
				padding-bottom: .7rem;
				padding-right: .5rem;
				padding-left: .5rem;
			}
		}

		.copy{
			@include font-size(14);
			@include line-height(14,16);
			color: rgba($white,.6);

			@media (max-width: $md){
				text-align: center;
				padding: 0 6.5rem;
				margin-bottom: 2.9rem;
			}
		}

		.small-footer-menu{
			@include font-size(14);
			@include line-height(14,16);
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-end;

			@media (max-width: $md){
				justify-content: center;
			}

			li{
				@include font-size(14);
				@include line-height(14,16);
				display: block;

				&.menu-item-has-children{
					display: none
				}

				@media (min-width: $md + 1){
					
					+ li{
						margin-left: 2rem;
					}
				}

				@media (max-width: $md){
					margin: 0 1rem 1rem;
				}
			}

			a{
				@include font-size(14);
				@include line-height(14,16);
				width: auto;
				color: rgba($white,.6);
				text-decoration: none!important;
				font-weight: 400;
				position: relative;
				font-family: $alt-font;
				padding: 0;

				&:hover,
				&:focus-visible{
					color: $blue-light;
				}
			}
		}
	}
}