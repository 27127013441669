//!!SCALE SETTINGS
$scale: true; //false, true
$vw-viewport: 1400; //If false does nothing. if true set this to the width of the design sent over

//!!GRID SETTINGS
$grid-gutter-widthWO: 16 !default; //most common gutter width

//!!PAGE WIDTH
$base-widthWO:		1196; //most common container width

//!!COLOURS
$red:				#D11242;
$red-dark:			#BC113B;

$green:				#2ba300;

$blue-lighter:		#F2FAFF;
$blue-light:		#2B99D9;
$blue:				#005596;
$blue-dark:			#002A57;

$grey:				#8C8B8B;

$white:				#FFFFFF;
$black:				#000000;
 
$colour: 			$blue-dark;
$main-bg-colour:	$white;

//!!FONTS

@font-face {
	font-family: 'Gilroy';
	src: url('../fonts/Gilroy-Regular.woff2') format('woff2'),
		url('../fonts/Gilroy-Regular.woff') format('woff'),
		url('../fonts/Gilroy-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Gilroy';
	src: url('../fonts/Gilroy-Medium.woff2') format('woff2'),
		url('../fonts/Gilroy-Medium.woff') format('woff'),
		url('../fonts/Gilroy-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Gilroy';
	src: url('../fonts/Gilroy-Semibold.woff2') format('woff2'),
		url('../fonts/Gilroy-Semibold.woff') format('woff'),
		url('../fonts/Gilroy-Semibold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}





$font-gilroy:		'Gilroy', sans-serif;
//regular - 400
//medium - 500
//semi - 600

$main-font:			$font-gilroy;
$alt-font:			$font-gilroy;

//!!SITE TRANSITIONS
$time:				.3s;

//BREAK POINTS
$xl:				$base-widthWO - 1px ; //$base-width + - 1px
$lg:				992px; //992px - 1px
$md:				769px - 1px; //769px - 1px
$sm:				576px - 1px; //576px - 1px

//xl: $base-width
//lg: 992px
//md: 768px
//sm: 576px



