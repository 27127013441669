//!!BUTTON
button:focus-visible,
.btn:focus-visible{
	outline: none;
}

.btn-con{
	display: flex;
	align-items: center;
	margin: 0 -.75rem;
	max-width: calc(100% + .75rem + .75rem);
	flex-wrap: wrap;

	&:has(.small){

		@media (min-width: $md + 1){
			margin-left: -.35rem;
			margin-right: -.35rem;
			max-width: calc(100% + .35rem + .35rem);

			.btn{
				margin: 0 .35rem 1rem;
			}
		}
	}

	@media (max-width: $md){
		margin: 0 -.75rem;
		max-width: calc(100% + .75rem + .75rem);
	}

	.btn{
		margin: 0 .75rem 1rem;

		@media (max-width: $md){
			margin: 0 .75rem 1rem;
		}

		&:first-of-type:last-of-type{
			margin-bottom: 0;
		}
	}

	&.btn-con-center{
		justify-content: center;
	}

	&.btn-con-left{
		justify-content: flex-start;
	}

	&.btn-con-right{
		justify-content: flex-end;
	}
}

.btn, %btn{
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 1.8rem!important;
	@include line-height(18,21);
	font-weight: 500;
	color: $white!important;
	text-decoration: none!important;
	font-family: $alt-font;
	text-align: center;
	padding: 1.034rem 2.75rem;
	background-color: $red;
	border: .1rem solid $red;
	border-radius: .2rem;
	box-shadow: none;
	letter-spacing: 0em;
	cursor: pointer;
	position: relative;
	transition: $time;
	z-index: 2;

	@media (max-width: $md){
		font-size: 1.6rem!important;
		padding: .95rem 1.85rem;
	}

	i{
		margin-left: 1.4rem!important;
		position: relative;
		backface-visibility: visible !important;

		svg{
			width: 100%;
		}
	}

	&:hover,
	&:focus-visible{
		outline: none;
		background-color: $red-dark;
		border-color: $red-dark;
		color: $white!important;
	}

	&.disabled,
	&:disabled{
		opacity: .25;
		pointer-events: none;
	}
}

.btn{

	&.blue-light{
		background-color: $blue-light;
		border-color: $blue-light;
		color: $white!important;

		&:hover,
		&:focus-visible{
			background-color: darken($blue-light, 4.5%);
			border-color: darken($blue-light, 4.5%);
			color: $white!important;
		}
	}

	&.blue{
		background-color: $blue;
		border-color: $blue;
		color: $white!important;

		&:hover,
		&:focus-visible{
			background-color: darken($blue, 4.5%);
			border-color: darken($blue, 4.5%);
			color: $white!important;
		}
	}

	&.white{
		background-color: $white;
		border-color: $white;
		color: $red!important;

		&:hover,
		&:focus-visible{
			background-color: $red;
			border-color: $red;
			color: $white!important;

			i{

				svg{
	
					*{
						fill: $white;
					}
				}
			}
		}

		i{

			svg{

				*{
					fill: $red;
				}
			}
		}
	}

	&.blue-dark{
		background-color: $blue-dark;
		border-color: $blue-dark;
		color: $white!important;

		&:hover,
		&:focus-visible{
			background-color: darken($blue-dark, 4.5%);
			border-color: darken($blue-dark, 4.5%);
			color: $white!important;
		}
	}

	&.tel{
		padding-left: 2.1rem;
		padding-right: 2.1rem;

		i{
			aspect-ratio: 15.367/15.291;
			width: 1.5367rem;
			margin-left: 0!important;
			margin-right: 1rem;
		}
	}

	&.pin{
		padding-left: 2.1rem;
		padding-right: 2.1rem;

		i{
			aspect-ratio: 13.146/19.729;
			width: 1.3146rem;
			margin-left: 0!important;
			margin-right: 1rem;
		}
	}

	&.line-blue-dark.line-blue-dark.line-blue-dark{
		background-color: transparent;
		border-color: $blue-dark;
		color: $blue-dark!important;

		i{

			svg{

				*{
					fill: $blue-dark!important;
				}
			}
		}

		&:hover,
		&:focus-visible{
			background-color: $blue;
			border-color: $blue;
			color: $white!important;

			svg{

				*{
					fill: $white!important;
				}
			}
		}
	}

	&.line-white.line-white.line-white{
		background-color: transparent;
		border-color: $white;
		color: $white!important;

		i{
			
			svg{

				*{
					fill: $white!important;
				}
			}
		}

		&:hover,
		&:focus-visible{
			background-color: $blue-dark;
			border-color: $blue-dark;
			color: $white!important;
		}
	}

	&.login{
		min-width: 13.167rem;

		i{
			aspect-ratio: 13.726/7.924;
			width: 1.3726rem;
			max-width: 1.3726rem;
		}
	}

	&.connect{
		border-radius: 0;
		background-color: $blue-dark;
		border-color: $blue-dark;
		transition: $time 0s, font-size 0s 0s, padding 0s 0s;

		i{
			aspect-ratio: 20.789/11.455;
			width: 2.0789rem;
			max-width: 2.0789rem;
		}

		&:hover,
		&:focus-visible{
			background-color: darken($blue-dark, 4.5%);
			border-color: darken($blue-dark, 4.5%);
		}
	}

	&.arrow{

		i{
			max-width: 0;
			overflow: hidden;
			display: flex;
			justify-content: flex-end;
			margin-right: -1.4rem;

			@media (max-width: $md){
				display: none!important;
			}

			svg{
				aspect-ratio: 33.28/12.91;
				width: 3.328rem;
				max-width: 3.328rem;
				min-width: 3.328rem;
				height: 1.291rem;
			}
		}

		&:hover,
		&:focus-visible{
			
			i{
				max-width: 3.328rem;
				margin-right: 0rem;
			}
		}
	}

	&.external{

		i{
			aspect-ratio: 12.54/12.14;
			width: 1.254rem;
			max-width: 1.254rem;

			svg{
				width: 1.254rem;

				&:nth-of-type(2){
					position: absolute;
					top: 0;
					left: 0;
				}
			}
		}

		&:hover,
		&:focus-visible{
			
			i{

				svg{

					&:nth-of-type(2){
						transform: translate(.15rem, -.15rem);
					}
				}
			}
		}
	}

	&.big{
		font-size: 2.2rem!important;
		padding: 1.9rem 3.3rem;

		i{
			margin-left: 2.1rem;
		}
	}
 
	&.small{
		font-size: 1.6rem!important;
		padding: .45rem .45rem;
		padding: .45rem 1.2rem;
		min-width: 13.562rem;
	}
}