//!!MENU
header[role="banner"]{
	z-index: 50;
	width: 100%;
	transform: translateZ(0);
	perspective: 999999px;
	position: fixed;
	top: 0;
	left: 0;
	transition: $time;
	
	@media (max-width: $md){
		
		.pop-open &{
			z-index: 0;
			transition: 0s;
		}
	}
}

#site-logo{
	display: block;
	height: auto;
	margin: 0;
	z-index: 2;
	transition: 0s;
	display: flex;
	width: 10.9rem;
	transition: $time;

	@media (max-width: $md){
		width: 9.498rem;
		min-width: 9.498rem;
		z-index: 3;
		position: relative;
	}

	img,
	svg{
		width: 100%;
	}
}

// body.scroll-up{

// 	.menu-bottom-outer{
// 		transition: $time;

// 		.menu-open &{
// 			transform: translateX(0%);
// 		}
// 	}
// }

// body.scroll-down{

// 	header{
// 		transform: translateY(-100%);
// 		transition: $time/2;
// 	}
	
// 	.burger{
// 		pointer-events: none;
// 	}
// }

body.scroll-down,
body.scroll-up{

	// @media (min-width: $md + 1){
		
	// 	#site-logo{
	// 		width: 13.825rem;
	// 	}
	
	// 	.menu-top-outer{
	// 		padding-top: 2rem;
	// 		padding-bottom: 2rem;
	
	// 		@media (min-width: $md + 1){
	// 			position: relative;
	
	// 			&:after{
	// 				content: '';
	// 				position: absolute;
	// 				top: 100%;
	// 				left: 0;
	// 				width: 100%;
	// 				height: 4rem;
	// 				opacity: .05;
	// 				background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
	// 				pointer-events: none;
	// 			}
				
	// 		}
	// 	}
	// }
}

.menu-top-outer{
	transition: $time;
	background-color: $white;
	position: relative;

	@media (max-width: $md){
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}

	.careers-header &{
		background-color: $blue-dark;

		.btn{
			padding-left: 2.4rem;
			padding-right: 2.4rem;
			margin-left: 3.5rem;

			@media (max-width: $md){
				display: none;
			}
		}

		.burger.burger.burger{

			&.open{

				.line1{
					background-color: transparent;
				}
			}

			.line1{
				background-color: $white;

				&:after,
				&:before{
					background-color: $white;
				}
			}
		}
	}

	&:after{
		content: '';
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		height: 1rem;
		background: linear-gradient(to bottom, rgba($black,.16) 0%, rgba($black,0) 100%);
		opacity: .4;
		pointer-events: none;
	}

	.container{
		max-width: 130.6rem;
		transition: $time;
		z-index: 2;
	}
	
	.col-12{
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: static;

		.left,
		.right{
			display: flex;
			align-items: center;
		}

		.left{
			align-content: flex-start;
		}

		.right{
			justify-content: flex-end;
		}
	}

	.search-con{
		margin-left: 3rem;
		position: relative;

		@media (max-width: $md){
			margin-left: 0;
			position: absolute;
			top: 2.6rem;
			right: 6.4rem;
            z-index: 10;
		}

		a{
			aspect-ratio: 20.1213/18.4512;
			width: 2.01213rem;
			display: block;
			
			&:hover,
			&:focus-visible{
				
				svg{

					&:nth-of-type(2){
						opacity: 1;
						visibility: visible;
					}
				}
			}

			svg{
				width: 100%;

				*{
					fill: $red;

					@media (max-width: $md){
						fill: $blue-dark;
					}
				}

				&:nth-of-type(2){
					position: absolute;
					top: 0;
					left: 0;
					opacity: 0;
					visibility: hidden;
					transition: $time;
				}
			}
		}
	}

	.login-con{
		position: relative;
		margin-left: 2.2rem;
		display: flex;
		align-items: center;

		@media (max-width: $md){
			position: static;
		}

		&.open{

			.btn.login.login{

				i{
					transform: translateY(0rem) rotateX(180deg);
				}
			}

			.login-dropdown{
				opacity: 1;
				visibility: visible;
				pointer-events: auto;
			}
		}

		.btn.login{

			@media (max-width: $md){
				background-image: url(../images/account.svg);
				background-repeat: no-repeat;
				background-position: 50% 50%;
				font-size: 0!important;
				border: none;
				background-color: transparent;
				background-size: 1.369rem auto;
				aspect-ratio: 13.69/17.2;
				padding: 0;
				min-width: 0;
				width: 1.369rem;

				i{
					display: none;
				}
			}

			i{
				transform: rotateX(0deg);
			}

			&:hover,
			&:focus-visible{

				i{
					transform: translateY(.2rem) rotateX(0deg);
				}
			}
		}

		.login-dropdown{
			position: absolute;
			top: calc(100% + 1.2rem);
			right: 0;
			opacity: 0;
			visibility: hidden;
			pointer-events: none;
			width: 46rem;
			max-width: 46rem;
			box-shadow: 0 0 1rem rgba($black,.16);
			background-color: $white;
			padding: 3rem 3rem 3rem;
			z-index: 1;
			transition: $time;
			
			@media (max-width: $md){
				top: 6.8rem;
				height: calc(100dvh - 6.8rem);
				width: 100dvw;
				max-width: 100dvw;
				background-color: $blue-lighter;
				box-shadow: none;
				padding: 4.9rem 3.1rem 3rem;

				h5{
					@include font-size(20);
					margin-bottom: .54em;
				}

				p{
					margin-bottom: 1.4em;
				}
			}

			.l-item{
				padding-bottom: 2rem;
				margin-bottom: 1.6rem;
				border-bottom: 1px solid rgba($blue-dark,.2);

				&:last-of-type{
					margin-bottom: 0;

					@media (min-width: $md + 1){
						padding-bottom: 0;
						border-bottom: none;
					}
				}
			}

			.btn-con{
				margin-top: 1.9rem;

				.btn{
					width: 100%;
				}
			}
		}
	}
}

.mob-only.mob-only{
	width: 100%;

	@media (min-width: $md + 1){
		display: none;
	}

	.social-links{
		justify-content: center;
	}
}

@import 'burger';

#mainMenu{
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	@media (max-width: $md){
		margin: 0 auto;
		position: fixed;
		top: 6.8rem;
		left: 0;
		width: 100%;
		max-width: 100%;
		// transition: $time;
		// padding: 0rem 2rem;
		flex-direction: column;
		flex-wrap: nowrap;
		display: flex;
		justify-content: flex-start;
		// overflow: hidden;
		z-index: -10;
		max-height: calc(100dvh - 6.8rem);
		height: 100vh;
		background-color: $blue-lighter;
		transform: translateX(100%);
		transition: $time;
		padding: 4.3rem 3.1rem 7.6rem 3.1rem;

		.careers-header &{
			padding: 2.1rem 3.1rem 7.6rem 3.1rem;
		}

		&:has(li.open){
			overflow: hidden;
		}

		.menu-open &{
			transform: translateX(0);
		} 
	}

	.small-menu{
		display: block;
		width: 100%;
		margin-top: 2.7rem;

		.careers-header &{
			padding-bottom: 2.7em;
			border-bottom: 1px solid rgba($blue-light,.4);
		}

		a{
			padding: .9rem 0;
			justify-content: flex-start;
		}

		@media (min-width: $md + 1){
			display: none;
		}
	}

	.login-bottom{
		width: 100%;
		margin-top: auto;
		margin-bottom: 1.9rem;

		.careers-header &{
			margin-top: 5.2rem;
		}

		@media (min-width: $md + 1){
			display: none;
		}

		.buttons{

			.btn-con{
				margin-left: 0;
				max-width: 100%;
			}
		}

		.btn{
			width: 100%;
			margin: 0 0 1rem;

			.careers-header &{
				display: block;
			}
		}
	}

	.social-menu{
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 9;
		width: 100%;
		background-color: $blue-lighter;
		justify-content: center;
		padding: 2.6rem 3.1rem;

		&:before{
			content: '';
			position: absolute;
			left: 3.1rem;
			right: 3.1rem;
			top: 0;
			height: .1rem;
			background-color: rgba($blue-light,.4);
		}

		a{
			margin: 0 1.6rem 0 0;

			&:last-of-type{
				margin-right: 0;
			}
		}
		
		@media (min-width: $md + 1){
			display: none;
		}

	}
}

@media (min-width: $md + 1){

	// #mainMenu .main-menu .current-menu-ancestor:not(.menu-item-object-custom),
	// #mainMenu .main-menu .current-page-ancestor:not(.menu-item-object-custom),
	// #mainMenu .main-menu .current-menu-parent:not(.menu-item-object-custom),
	// #mainMenu .main-menu .current_page_parent:not(.menu-item-object-custom),
	// #mainMenu .main-menu .current-menu-item:not(.menu-item-object-custom),
	.right.right.right .small-menu .current-menu-parent,
	.right.right.right .small-menu .current_page_parent,
	.right.right.right .small-menu .current-menu-item,
	#mainMenu .main-menu .current-menu-ancestor,
	#mainMenu .main-menu .current-page-ancestor,
	#mainMenu .main-menu .current-menu-parent,
	#mainMenu .main-menu .current_page_parent,
	#mainMenu .main-menu .current-menu-item{

		> a{
			
			&:after{
				opacity: 1;
				visibility: visible;
			}
		}
	}

	#mainMenu .main-menu .sub-menu .current-menu-ancestor,
	#mainMenu .main-menu .sub-menu .current-page-ancestor,
	#mainMenu .main-menu .sub-menu .current-menu-parent,
	#mainMenu .main-menu .sub-menu .current_page_parent,
	#mainMenu .main-menu .sub-menu .current-menu-item{

		> a{

			&:after{
				opacity: 1;
				visibility: visible;
			}
		}
	}
}

ul.main-menu.main-menu{
	margin: 0 0;
	padding: 0;
	@include font-size(0);
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;

	@media (min-width: $md + 1){
		flex-wrap: nowrap;
		margin-left: 4.55rem;
	}

	@media (max-width: $md){
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		counter-reset: section;
		max-height: calc(100vh - 8rem - 1.2rem);
		overflow: auto;
		flex-wrap: nowrap;
		justify-content: flex-start;
		padding-bottom: 3.7rem;
		border-bottom: 1px solid rgba($blue-light,.4);
	}

	> li{

		* *{
			text-align: left;
		}

		@media (min-width: $md + 1){
			
			&:hover,
			&:focus-visible{
				
				&:has(.post){

					> .sub-menu.sub-menu.sub-menu > li > .sub-menu.sub-menu.sub-menu{
						pointer-events: auto;
					}
				}
			}


			&.open:not(:has(> ul > li.has-button)){

				> .sub-menu.sub-menu.sub-menu:not(:has(> li:nth-of-type(1n + 2):hover)){
					
					> li:nth-of-type(1){

						> a{
							color: $blue-light;

							&:before{
								opacity: 1;
								visibility: visible;
							}
						}

						> .sub-menu.sub-menu.sub-menu{
							opacity: 1;
							visibility: visible;
							pointer-events: auto;
						}
					}
				}
			}
		}
		
		@media (max-width: $md){
			
			&.open{

				> .sub-menu.sub-menu.sub-menu{
					
				}
			}
		
			&.open{

				> ul > li.has-button{

					> .sub-menu.sub-menu.sub-menu.sub-menu.sub-menu.sub-menu{
						pointer-events: auto;
					}
				}

				> ul > li.simple-sub{

					> .sub-menu.sub-menu.sub-menu.sub-menu.sub-menu.sub-menu{
						pointer-events: auto;
					}
				}
			}
		}



		> .sub-menu.sub-menu.sub-menu{
			left: 0;
			height: calc(100dvh - 10rem);
			width: 38.1rem;
			max-width: 38.1rem;
			background-color: $blue-dark;
			padding: 7.7rem 0 7.7rem 5.4rem;

			@media (max-width: $md){
				top: 0;
				z-index: 4;
				height: 35rem;
				width: 100%;
				max-width: 100%;
				padding: 8.2rem 0 .5rem 3.1rem;
			}

			&:after{
				content: '';
				position: absolute;
				top: 0;
				left: 100%;
				width: calc(100vw - 38.1rem);
				max-width: calc(100vw - 38.1rem);
				height: 100%;
				background-color: $blue-lighter;
				z-index: -1;

				@media (max-width: $md){
					width: 100%;
					max-width: 100%;
					top: 100%;
					left: 0;
					height: calc(100dvh - 35rem - 14.2rem);
					overflow: auto;
				}
			}

			h2{
				color: $white;
				margin-bottom: 1.15em;
				padding-right: 4.4rem;

				@media (max-width: $md){
					position: relative;

					&::before{
						content: 'Back';
						@include font-size(14);
						@include line-height(14,16);
						position: absolute;
						top: -3.1rem;
						font-weight: 400;
						padding-left: 1.4rem;
						width: 100%;
						background-image: url(../images/menu-back.svg);
						background-repeat: no-repeat;
						background-position: 0 45%;
						background-size: .5561rem auto;
					}
				}
			}

			@media (min-width: $md + 1){
						
				&:hover:has(> li.has-button){

					> li > .sub-menu.sub-menu.sub-menu{
						pointer-events: auto;
					}
				}
			}

			> li{
				width: 100%;

				@media (max-width: $md){
					background-image: url(../images/menu-arrow-close.svg);
					background-size: 1.2552rem auto;
					background-position: right 13.3rem top 50%;
					padding-right: 15rem;

					&.open{
						background-image: url(../images/menu-arrow-open.svg);

						> a{
							color: $blue-light;
							text-decoration: underline!important;
						}
					}
				}

				a{

					&:after{
						display: none;
					}
				}

				&:hover,
				&:focus-visible{
					
					> a{
						color: $blue-light;

						&:before{
							opacity: 1;
							visibility: visible;
						}
					}
				}

				&.simple-sub{

					&:hover,
					&:focus-visible{
						
						> .sub-menu.sub-menu.sub-menu{
							pointer-events: auto;
						}	
					}

					> .sub-menu.sub-menu.sub-menu{
						width: calc(100vw - 38.1rem);
						max-width: calc(100vw - 38.1rem);
						height: calc(100dvh - 10rem);
						left: 100%;
						padding: 7.2rem 4.3rem 2rem 9.3rem;
						background-color: $blue-lighter;
						opacity: 1;
						visibility: visible;
                        max-height: 100%;
                        overflow: auto;

						@media (max-width: $md){
							width: 100%;
							max-width: 100%;
							top: 100%;
							left: 0;
							height: calc(100dvh - 35rem - 14.2rem);
							padding: 4.1rem 0rem 2rem 3.1rem;
							overflow: auto;
						}

						@media (min-width: $md + 1){
							// column-count: 2;
                            display: grid;
                            grid-template-columns: 1fr 1fr;
                            align-items: start;
                            overflow: auto;
						}

						> li{

							@media (max-width: $md){
								background-image: none;
								padding-right: 0;
							}

							> a{
								@include font-size(18);
								font-weight: 500;
								color: $blue-dark;
								padding: .5rem 0;

								@media (max-width: $md){
									@include font-size(20);
									font-weight: 600;

								}

								@media (min-width: $md + 1){
									
									&:hover,
									&:focus-visible{
										color: $red;
									}
								}
								
							}
						}
					}
				}

				&.has-button{

					@media (max-width: $md){
						padding-right: 0;
						background-image: none;
					}

					> a{
						display: block;
						padding: 5.6rem 4.6rem 1rem 0;

						@media (max-width: $md){
							padding: .6rem 4.6rem 1rem 0;

							.btn{
								background-position: top 50% right 2.3rem;
								background-image: url(../images/link.svg);
								background-repeat: no-repeat;
								background-size: 3.2928rem auto;
								padding: 1rem 7rem 1rem 2.7rem;
								font-size: 1.8rem!important;
							}
						}

						&:before{
							display: none;
						}

						p{
							color: $white;
							margin-bottom: 3.5rem;
						}

						&:hover,
						&:focus-visible{
							
							.btn{
								background-color: darken($blue-light, 4.5%);
								border-color: darken($blue-light, 4.5%);
								color: $white!important;
							}
						}
					}


					@media (min-width: $md + 1){

						&:hover,
						&:focus-visible{
							
							> .sub-menu.sub-menu.sub-menu{
								pointer-events: auto;
							}	
						}
					}

					@media (max-width: $md){
						
						&.open{
							
							> .sub-menu.sub-menu.sub-menu{
								pointer-events: auto;
							}	
						}
					}
					

					> .sub-menu.sub-menu.sub-menu{
						opacity: 1;
						visibility: visible;
					}
				}

				> a{
					@include font-size(25);
					@include line-height(25,30);
					font-weight: 500;
					color: $white;
					width: 100%;
					padding: 1rem 8.5rem 1rem 0;
					justify-content: flex-start;
					position: relative;

					@media (max-width: $md){
						@include font-size(20);
						@include line-height(20,24);
						padding: .85rem 0;
						text-underline-offset: .22em;
					}

					&:before{
						content: '';
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						right: 4.9rem;
						background-image: url(../images/slide-next.svg);
						background-repeat: no-repeat;
						background-size: 100% auto;
						aspect-ratio: 33.3/12.9;
						width: 3.33rem;
						left: auto;
						opacity: 0;
						visibility: hidden;

						@media (max-width: $md){
							display: none;
						}
					}
				}

				> .sub-menu.sub-menu.sub-menu{
					width: calc(100vw - 38.1rem);
					max-width: calc(100vw - 38.1rem);
					height: calc(100dvh - 10rem);
					left: 100%;
					padding: 5.8rem 4.3rem 2rem 9.3rem;
					background-color: $blue-lighter;
                    max-height: 100%;
                    overflow: auto;

					@media (max-width: $md){
						top: 35rem;
						left: 0;
						width: 100%;
						max-width: 100%;
						height: calc(100dvh - 35rem - 14.2rem);
						padding: 4.1rem 0rem 2rem 3.1rem;
						overflow: auto;
					}

					&:has(img){
						padding-top: 6.5rem;

						@media (max-width: $md){
							padding-top: 4.1rem;
						}

						> li{
							width: 48.8%;
							position: relative;
							
							@media (min-width: $md + 1){
								position: static;
							}

							@media (max-width: $md){
								width: 100%;
								padding-right: 0!important;
								background-image: none!important;
							}

							@media (min-width: $md + 1){
								
								&:hover,
								&:focus-visible{
									
									> a{
										color: $blue-light;
									}

									> .sub-menu.sub-menu.sub-menu{
										opacity: 1;
										visibility: visible;
										pointer-events: auto;
									}
								}
							}

							> a{
								padding: 1.02rem 3.5rem 1.02rem 0;
								color: $blue-dark;

								@media (max-width: $md){
									padding: .5rem 0;
								}
							}

							> .sub-menu.sub-menu.sub-menu{
								position: absolute;
								top: 1rem;
								left: 100%;
								width: 88.5%;
								column-count: unset;
								opacity: 0;
								visibility: hidden;

								@media (min-width: $md + 1){
									width: calc(100% - 4.3rem - 9.3rem - 48.8%);
									left: auto;
									right: 11.4rem;
									top: 7.6rem;
									height: calc(100dvh - 19.9rem);
								}

								@media (max-width: $md){
									display: none;
								}

								li{
									display: none;

									&:first-of-type{
										display: block;
										width: 100%;

										a{
											display: flex;
											flex-wrap: wrap;
											width: 100%;
											padding: 0 0 3.6rem;
											position: relative;

											&:before{
												content: '';
												position: absolute;
												bottom: 0;
												left: 0;
												background-image: url(../images/slide-next.svg);
												background-repeat: no-repeat;
												background-size: 100% auto;
												aspect-ratio: 33.3/12.9;
												width: 3.33rem;
											}

											img{
												width: 100%;
												aspect-ratio: 380/222;
												object-fit: cover;
												object-position: 50% 50%;
												margin-bottom: 3rem;
												
											}

											h4{
												width: 100%;
												color: $blue-light;
												font-weight: 600;
												margin-bottom: .6em;
											}

											p{
												margin-bottom: 0;
												width: 100%;
											}
										}
									}
								}
							}
						}
					}

					&:has(.posts){
						padding-top: 6.5rem;

						@media (max-width: $md){
							padding-top: 4.1rem;
						}

						> li{
							width: 38.8%;
							position: relative;

							@media (min-width: $md + 1){
								position: static;
							}

							@media (max-width: $md){
								width: 100%;
							}

							@media (min-width: $md + 1){

								&:hover,
								&:focus-visible{
									
									> a{
										color: $blue-light;
									}

									> .sub-menu.sub-menu.sub-menu{
										opacity: 1;
										visibility: visible;
										pointer-events: auto;
									}
								}
							}
							

							> a{
								padding: 1.02rem 3.5rem 1.02rem 0;
								color: $blue-dark;

								@media (max-width: $md){
									padding: .5rem 0;
								}
							}

							> .sub-menu.sub-menu.sub-menu{
								position: absolute;
								top: 1rem;
								left: 100%;
								width: 122%;
								max-width: 122%;
								column-count: unset;
								opacity: 0;
								visibility: hidden;

								@media (min-width: $md + 1){
									width: calc(100% - 4.3rem - 9.3rem - 38.8%);
									left: auto;
									right: 11.4rem;
									top: 7.8rem;
									height: calc(100dvh - 19.9rem);
								}

								@media (max-width: $md){
									opacity: 1;
									visibility: visible;
									position: static;
									width: calc(100% + 12rem);
									max-width: calc(100% + 12rem);
									min-width: calc(100% + 12rem);

									p{
										@include font-size(14);
									}

								}

								li{

									> a{
										display: none;
									}

									h6{
										color: $blue-light;
										margin-top: 6.2rem;
										margin-bottom: 2.6rem;

										@media (max-width: $md){
											margin-top: 4.2rem;
											@include font-size(18);
											color: $blue-dark;
										}
									}

									.mn-item{
										width: 100%;
										margin-bottom: 3.1rem;

										.inner{
											display: flex;
											flex-wrap: wrap;
											align-items: flex-start;
											position: relative;

											a{
												position: absolute;
												top: 0;
												left: 0;
												width: 100%;
												height: 100%;
												z-index: 1;

												&:hover,
												&:focus-visible{
													
													~ .img-side{

														.img-con{
															transform: scale(1.1);
														}
													}

													~ .text{

														&:before{
															margin-left: 1rem;
														}
													}
												}
											}

											.img-side{
												width: 32.8%;
												width: 13.8rem;
												margin-right: 1.7rem;
												overflow: hidden;

												@media (max-width: $md){
													width: 11rem;
													margin-right: 1.6rem;
												}

												.img-con{
													aspect-ratio: 1/1;
													object-fit: cover;
													object-position: 50% 50%;
													transition: $time;
													transform: scale(1);
												}
											}
										}
										
										.text{
											position: relative;
											align-self: stretch;
											width: calc(100% - 32.8% - 1.7rem);
											width: calc(100% - 13.8rem - 1.7rem);

											@media (max-width: $md){
												width: calc(100% - 11rem - 1.6rem);
											}

											&:before{
												content: '';
												position: absolute;
												bottom: 0;
												left: 0;
												background-image: url(../images/slide-next.svg);
												background-repeat: no-repeat;
												background-size: 100% auto;
												aspect-ratio: 33.3/12.9;
												width: 3.33rem;
												transition: $time;
											}

											h4{
												@include font-size(18);
												font-weight: 400;

												@media (max-width: $md){
													@include font-size(14);
													@include line-height(14,20);
												}
											}

										}


									}
								}
							}
						}
					}

					> li{
						flex-wrap: wrap;

						@media (max-width: $md){
							
							&.menu-item-has-children{
								background-position: right 12.8rem top 1.9rem;
								background-image: url(../images/menu-down.svg);
								background-repeat: no-repeat;
								background-size: 1.2905rem auto;
								padding-right: 15rem;

								&.open{
									background-image: url(../images/menu-up.svg);

									> .sub-menu.sub-menu.sub-menu{
										max-height: 9990rem;
										margin-top: .7rem;
									}
								}

								> a{
									
								}
							}
						}

						> a{
							@include font-size(25);
							@include line-height(25,30);
							font-weight: 600;
							color: $blue-light;
							padding: 1.8rem 3.5rem 1.8rem 0;
							width: 100%;
							justify-content: flex-start;

							@media (max-width: $md){
								@include font-size(20);
								@include line-height(20,30);
								color: $blue-dark;
								padding: .55rem 0;
							}
							
						}

						> .sub-menu.sub-menu.sub-menu{
							position: static;
							opacity: 1;
							visibility: visible;
							background-color: transparent;
							width: 100%;
							max-width: 100%;
							margin-bottom: 2.3rem;

							@media (max-width: $md){
								margin-bottom: 0;
								max-height: 0;
								overflow: hidden;
								margin-top: 0rem;
								width: calc(100% + 2.4rem);
								max-width: calc(100% + 2.4rem);
								min-width: calc(100% + 2.4rem);

								> li{
									background-image: none;
									padding-right: 0;
								}
							}

							@media (min-width: $md + 1){
								// column-count: 3;
                                display: grid;
                                grid-template-columns: 1fr 1fr 1fr;
                                align-items: start;
                                overflow: auto;
							}

							> li{

								> a{
									padding: .5rem 0;

									@media (max-width: $md){
										padding: 0 0;
										@include font-size(16);
										@include line-height(16,30);
									}
								}
							}
						}
					}
				}
			}
		}
	}
	
	li{
		@include font-size(0);
		line-height: 1;
		position: static;
		display: flex;
		text-align: center;
		
		@media (min-width: $md + 1){

			+ li{
				margin-left: 3rem;
			}

			&:hover,
			&:focus-visible{
				
				> a{
					color: $red;

					// &:after{
					// 	opacity: 1;
					// 	visibility: visible;
					// }
				}
			}

			&.mob-back{
				display: none;
			}
		}

		@media (max-width: $md){
			margin: 0 0;
			justify-content: left;
			text-align: left;
			margin-bottom: 1rem;
			width: 100%;
			padding-right: 6rem;
			background-image: url(../images/menu-arrow.svg);
			background-repeat: no-repeat;
			background-size: .6983rem auto;
			background-position: right .3rem top 55%;
	
			&:last-of-type{
				margin-bottom: 0;
			}
			
			&.open{

				> .sub-menu.sub-menu{
					opacity: 1;
					visibility: visible;
					pointer-events: auto;
					max-height: 9999px;
					z-index: 7;
				}
			}
		}

		&.no-link{

			> a{
				pointer-events: none;
			}
		}

		&.menu-item-has-children{
			flex-wrap: wrap;

			&.open{

				&:after{
					transform: rotateX(180deg);
				}
			}
			
			.sub-menu{
				position: absolute;
				top: 100%;
				left: -1rem;
				width: 25.155rem;
				max-width: 25.155rem;
				pointer-events: none;
				opacity: 0;
				visibility: hidden;
				background-color: $green;

				// @media (max-width: $md){
				// 	//position: static;
				// 	transform: none;
				// 	max-height: 0;
				// 	overflow: hidden;
				// 	width: 100%;
				// 	max-width: 100%;
				// 	background-color: transparent;
				// 	border-radius: 0;
				// }

				li{
					width: 100%;
					text-align: left;
					margin: 0;

					&.menu-item-has-children{

						.sub-menu{
							left: 100%;
							top: 0;
							width: 28.3rem;
							max-width: 28.3rem;
						}
					}

					@media (max-width: $md){
						width: 100%;
						text-align: left;
					}
				}
			}

			@media (min-width: $md + 1){
				
				&:hover,
				&:focus-visible{
					
					> .sub-menu{
						pointer-events: auto;
						opacity: 1;
						visibility: visible;
						transition: $time;
					}
				}
			}
		}

		[href="#"]{

			@media (max-width: $md){
				pointer-events: none;
			}
		}

		> a{
			@include font-size(18);
			@include line-height(18,21);
			width: auto;
			color: $colour;
			text-decoration: none!important;
			font-weight: 500;
			position: relative;
			font-family: $alt-font;
			padding: 3.95rem 0;
			z-index: 2;
			display: flex;
			justify-content: center;
			align-items: center;

			&:after{
				content: '';
				position: absolute;
				bottom: 3.35rem;
				left: 0;
				height: .2rem;
				width: 100%;
				background-color: $red;
				opacity: 0;
				visibility: hidden;
			}

			@media (min-width: $md + 1){
				
				&:hover,
				&:focus-visible{
					color: $red;

					// &:after{
					// 	opacity: 1;
					// 	visibility: visible;
					// }
				}
			}

			@media (max-width: $md){
				padding: .25rem 0;
				@include font-size(25);
				@include line-height(25,30);
				justify-content: start;
				position: relative;

				&:hover,
				&:focus-visible{
					color: $red;
				}
			}
		}
	}
}

.small-menu{
	display: flex;
	align-items: center;

	@media (max-width: $md){
		display: none;
	}

	li{

		@media (max-width: $md){
			
			.careers-header &{
				margin: 0 0;
				justify-content: left;
				text-align: left;
				margin-bottom: 1.6rem;
				width: 100%;
				padding-right: 6rem;
				background-image: url(../images/menu-arrow.svg);
				background-repeat: no-repeat;
				background-size: .6983rem auto;
				background-position: right .3rem top 55%;

				&:last-of-type{
					margin-bottom: 4.4rem;
				}
			}
		}

		@media (min-width: $md + 1){
			position: relative;

			&:hover,
			&:focus-visible{
				
				> a{
					color: $red;

					// &:after{
					// 	opacity: 1;
					// 	visibility: visible;
					// }
				}
			}

			+ li{
				margin-left: 2.4rem;

				.careers-header &{
					margin-left: 3.1rem;
				}
				
				&::before{
					content: '';
					position: absolute;
					top: 50%;
					left: -1.2rem;
					transform: translate(-50%, -50%);
					width: 1px;
					height: 1.45rem;
					background-color: #D3D3D3;
					display: block;
				}

				.careers-header &{
					
					&:before{
						display: none;
					}
				}
			}
		}
	}

	a{
		@include font-size(16);
		@include line-height(16,19);
		text-decoration: none;
		padding: 4.05rem 0;
		z-index: 2;
		display: flex;
		justify-content: center;
		align-items: center;

		@media (min-width: $md + 1){
			
			&:after{
				content: '';
				position: absolute;
				bottom: 3.35rem;
				left: 0;
				height: .2rem;
				width: 100%;
				background-color: $red;
				opacity: 0;
				visibility: hidden;
			}
		}

		.careers-header &{
			color: $white;
			@include font-size(18);
			@include line-height(18,21);
			font-weight: 500;

			@media (max-width: $md){
				color: $blue-dark;
				@include font-size(25);
				padding: 0!important;
			}
		}

		@media (min-width: $md + 1){
			
			&:hover,
			&:focus-visible{
				color: $red;

				// &:after{
				// 	opacity: 1;
				// 	visibility: visible;
				// }
			}
		}
	}
}

@media (min-width: $md + 1){

	.main-menu > li.menu-item-has-children{

		&:hover,
		&:focus-visible{
			
			> .sub-menu.sub-menu.sub-menu.sub-menu.sub-menu{
				pointer-events: none;
				opacity: 0;
				visibility: hidden;
			}
		}
	}
	
	.main-menu > li.menu-item-has-children.open{
		
		> .sub-menu.sub-menu.sub-menu.sub-menu.sub-menu.sub-menu.sub-menu{
			pointer-events: auto;
			opacity: 1;
			visibility: visible;
			transition: $time;
		}
	}
}

.menu-titles-hidden{
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 0;
	overflow: hidden;
}

@media (min-width: $md + 1){

	// body.page-id-440{

	// 	#menu-item-1490{

	// 		> a{

	// 			&:after{
	// 				opacity: 1;
	// 				visibility: visible;
	// 			}
	// 		}
	// 	}
	// }


	body.post-type-archive-location{

		#menu-item-1490{

			> a{

				&:after{
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
	
	body.post-type-archive-brokers{

		#menu-item-1491{

			> a{

				&:after{
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
}