html{
	@if $scale {
		font-size: resize(10px);
	} @else {
		font-size: 10px;
	}
	font-family: $main-font;
	font-weight: 400;
	margin-top: 0 !important;
	background-color: $main-bg-colour;
	overflow-x: clip;

	@media (max-width: $md){
		font-size: 10px;
		font-size: 2.66666666667vw;
		//font-size: 14.6667px;
	}

	@media (max-width: 550px){
		font-size: 2.66666666667vw;
	}

	@media (min-width: 1400px + 1){
		font-size: 10px;
	}


	// @media (max-width: $md){
	// 	overflow-x: hidden;

	// 	&.menu-open{
	// 		overflow: hidden;
	// 	}
	// }

	@media (max-width: 374px){
		font-size: 2.67379679144vw;
	}

	// &#BTT{
	// 	margin-top: 0 !important;
	// }

	&:-webkit-scrollbar{
		display: none;
	}

	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */

	&:before,
	&:after,
	*{

		&:-webkit-scrollbar {
			display: none;
		}

		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	}

	*{

		&:-webkit-scrollbar{
			display: none;
		}
	
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	
		&:before,
		&:after,
		*{
	
			&:-webkit-scrollbar {
				display: none;
			}
	
			-ms-overflow-style: none;  /* IE and Edge */
			scrollbar-width: none;  /* Firefox */
		}
	}
}

body{
	color: $colour;
	@include font-size(16);
	@include line-height(16,19);
	font-weight: 400;
	position: relative;
	overflow: clip;
	padding-top: 10rem;

	@media (max-width: $md){
		padding-top: 6.825rem;
	}

	&:-webkit-scrollbar{
		display: none;
	}

	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */

	&:before,
	&:after,
	*{

		&:-webkit-scrollbar {
			display: none;
		}

		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	}

	*{

		&:-webkit-scrollbar{
			display: none;
		}
	
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	
		&:before,
		&:after,
		*{
	
			&:-webkit-scrollbar {
				display: none;
			}
	
			-ms-overflow-style: none;  /* IE and Edge */
			scrollbar-width: none;  /* Firefox */
		}
	}
}

// #wpadminbar{
// 	display: none;
// }

h1,
h2,
h3,
h4,
h5,
h6,
p,
address,
blockquote,
pre,
table,
li{
	color: $colour;
	margin-top: 0;
	font-weight: 400;
	margin-bottom: .61em;
	@include font-size(16);
	@include line-height(16,19);

	a{
		font-size: inherit;
		text-decoration: underline;
		font-weight: inherit;
		color: inherit;

		&:hover,
		&:focus-visible{
			text-decoration: none;
		}
	}

	strong{
		font-weight: 700;
		color: inherit;
	}

	em{
		font-style: italic;
	}
}

body sub, 
body sup{
	font-size: 59%;
	margin-left: 0em;
}

body sup{
	top: -.55em;
}

body sub{
	bottom: .3em;
}

small{
	font-size: 75%;
}

h1,
h2,
h3,
h4,
h5,
h6,
blockquote{
	font-family: $main-font;
	margin-bottom: .5em;
	font-weight: 600;
	color: $colour;
}

h1{
	@include font-size(70);
	@include line-height(70,80);
	margin-bottom: .54em;

	@media (max-width: $md){
		@include font-size(35);
		@include line-height(35,40);
		margin-bottom: .71em;
	}
}

h2{
	@include font-size(40);
	@include line-height(40,45);
	margin-bottom: 1.07em;

	@media (max-width: $md){
		@include font-size(25);
		@include line-height(25,30);
		margin-bottom: 1em;
	}
}

h3{
	@include font-size(30);
	@include line-height(30,35);

	@media (max-width: $md){
		@include font-size(25);
		@include line-height(25,35);
	}

	a{
		color: $blue-light;
		text-decoration: none;

		&:hover,
		&:focus-visible{
			color: $blue-light;
			text-decoration: underline;
		}
	}
}

h4{
	@include font-size(25);
	@include line-height(25,30);
	font-weight: 500;

	@media (max-width: $md){
		@include font-size(22);
		@include line-height(22,30);
	}
}

h5{
	@include font-size(20);
	@include line-height(20,24);
	margin-bottom: .34em;

	@media (max-width: $md){
		@include font-size(14);
		@include line-height(14,16);
	}
}

h6{
	@include font-size(18);
	@include line-height(18,21);

	@media (max-width: $md){
		@include font-size(16);
		@include line-height(16,19);
	}
}

p{
	@include font-size(16);
	@include line-height(16,19);
	margin-bottom: 1.2em;

	strong{
		font-weight: 500;
	}

	&.large{
		@include font-size(20);
		@include line-height(20,30);

		@media (max-width: $md){
			@include font-size(16);
			@include line-height(16,19);
		}
	}

	&.medium{
		@include font-size(18);
		@include line-height(18,26);

		@media (max-width: $md){
			@include font-size(16);
			@include line-height(16,19);
		}
		
		strong{
			font-weight: 500;
		}
	}
}

button{
	box-shadow: none;
	background-color: transparent;
	border: none;
	padding: 0;
}

button,
a{
	font-size: inherit;
	color: $colour;
	font-weight: 400;
	transition: $time;
	cursor: pointer;
	text-decoration: underline;
	text-underline-position: under;
	text-underline-offset: -.08em;

	&:before,
	&:after,
	*:before,
	*:after,
	*{
		transition: $time;
	}

	&:hover,
	&:focus-visible{
		color: $colour;
	}
}

button{
	box-shadow: none;
	background-color: transparent;
	border: none;
	padding: 0;
}

sup{
	top: -1.61em;
	font-size: 30%;
	font-weight: inherit;
	margin-left: .2em;
}

mark{
	background-color: $blue;
	color: $white;
}

strong{
	font-weight: 600;
}

blockquote{
	@include font-size(40);
	@include line-height(40,45);
	margin: 1.87em 0 1.06em;
	text-transform: none;
	padding: 0;
	font-style: normal;
	color: $red;
	position: relative;
	z-index: 1;
	font-family: $main-font;
	font-weight: 600;
	padding-left: .45em;

	@media (max-width: $md){
		@include font-size(30);
		@include line-height(30,35);
		margin: 1.8em 0 1.06em;
		padding-left: 0;

		br{
			display: none;
		}
	}

	&:has(~ .by){
		margin: 0 0 .2em;

		@media (max-width: $md){
			margin: 0 0 1.5em;
		}
	}

	&:before{
		content: '“';
		position: absolute;
		top: 0;
		left: 0;

		@media (max-width: $md){
			position: static;
		}
	}

	&:after{
		content: '”';
	}

	~ .by{
		@include font-size(20);
		@include line-height(20,30);
		text-align: center;
		font-weight: 400;
		display: flex;
		align-items: center;
	}

	p{
		font-size: inherit!important;
		line-height: inherit!important;
		font-family: inherit!important;
		font-weight: inherit!important;
		margin-bottom: 0!important;
		letter-spacing: inherit!important;
		display: inline;
		color: inherit!important;
	}
}

hr{
	border: 0;
	width: 100%;
	height: .1rem;
	min-height: 1px;
	background-color: currentColor;
	margin: 1.7rem 0 2.7rem;

	@media (max-width: $lg){
		margin: .8rem 0 .8rem;
	}

	&:last-child{
		margin-bottom: 0;
	}
}

br{

	// @media (max-width: $md){
	// 	display: none;
	// }
}

address{
	font-style: normal;
	@include font-size(16);
	@include line-height(16,19.2);
	color: $colour;
	margin-bottom: 1.3em;
}

ol,
ul,
dl{
	margin: 0;
	padding-left: 0;
	list-style-position: inside;
	font-size: 0;
	margin-bottom: 1.77778em;

	li,
	dt,
	dd{
		@include font-size(16);
		@include line-height(16,19);
		margin-bottom: 0;

		a{
			font-size: inherit;
		}
	}

	dd{
		margin-bottom: 20px;
	}
}

.acf_content ol,
.acf_content ul,
.acf_content dl,
main ol,
main ul,
main dl{
	@include font-size(16);
	@include line-height(16,19);
	margin-bottom: 1.77778em;
}

ul{
	list-style-position: inside;
	padding-left: 0;

	&.no-bullet{
		list-style: none;

		li{
			padding-left: 0;

			&:before{
				display: none;
			}
		}
	}

	li{
		padding-left: 3.3em;
		margin-bottom: 0em;
		list-style: none;
		position: relative;

		@media (max-width: $md){
			padding-left: 1.8em;
		}

		&.large{
			@include font-size(20);
			@include line-height(20,30);
		}
	
		&.medium{
			@include font-size(18);
			@include line-height(18,26);

			@media (max-width: $md){
				@include font-size(16);
				@include line-height(16,19);
			}
			
			strong{
				font-weight: 500;
			}
		}

		&:before{
			content: '';
			position: absolute;
			left: .15em;
			top: .5em;
			width: .19em;
			height: .19em;
			border-radius: 4rem;
			background-color: currentColor;
		}

		ul{
			margin-top: 1em;
			margin-bottom: 1em;

			li{

				&:before{
					border-radius: 0;
				}
			}
		}
	}
}

ol:not(.pagination){
	list-style-position: outside;
	padding-left: 0;
	counter-reset: section;

	li{
		list-style: none;
		position: relative;
		padding-left: 3.3em;
		margin-bottom: 0;

		@media (max-width: $md){
			padding-left: 1.8em;
		}

		&:nth-of-type(1n + 10){

			&:before{
				content: counter(section)". ";
			}
		}

		&:before{
			counter-increment: section;
			content: counter(section)".";
			min-width: 1em;
			display: inline-block;
			position: absolute;
			top: 0em;
			left: 0;
			font-size: inherit;
			line-height: inherit;
			color: inherit;
			font-weight: inherit;
		}

		ol{
			margin-top: 1em;
			margin-bottom: 1em;

			li{
				padding-left: 2.3em;

				&:before{
					content: counter(section , lower-alpha)". ";
					left: .1em;
				}
			}
		}
	}
}

.wpml-ls ul,
ul.simple-menu,
ul.wc-item-meta,
ul.order_details,
ul.filter-items,
ul.small-footer-menu,
ul.woocommerce-PaymentMethods,
ul.woocommerce-shipping-methods,
ul.woocommerce-order-overview,
ul.wc_payment_methods,
ul.select2-results__options,
.woocommerce-MyAccount-navigation ul,
ul.woocommerce-error,
ul.tabs,
ul.small-menu,
ul.main-menu,
ul.footer-menu,
ul.sub-menu,
ul.g_fields,
ul.social-menu{
	list-style: none;
	padding-left: 0;
	padding-top: 0;

	> li{
		padding-left: 0;
		margin-bottom: 0;

		&:before,
		&:after{
			display: none;
		}
	}
}

html body table:not(.ui-datepicker-calendar){
	margin-bottom: 1.77778em;
	border: none;
	width: 100%!important;
	background-color: transparent;
	text-align: left;
	table-layout: fixed;
	@include font-size(16);
	@include line-height(16,19);
	
	*{
		vertical-align: top;
	}

	&:not(:has(thead)) tbody tr:first-of-type,
	thead{

		td,
		th{
			padding: 1.1rem 1.5rem;
			border-top: .1rem solid $colour;
			border-bottom: .1rem solid $colour;
			border-left: .1rem solid $colour;
			font-family: $alt-font;
			color: $blue;
			text-transform: uppercase;
			font-weight: 400;

			&:last-of-type{
				border-right: .1rem solid $colour;
			}
		}
	}

	tbody{

		td{
			padding: 1.55rem 1.5rem;
			border-bottom: .1rem solid $colour;
			border-left: .1rem solid $colour;

			&:last-of-type{
				border-right: .1rem solid $colour;
			}
		}
	}
}

.wp-caption{

	.wp-caption-text{
		@include font-size(14);
		@include line-height(14,16);
		font-weight: 400;
		color: $colour;
		
		&:last-of-type:last-of-type{
			margin-bottom: 0;
			padding-top: 1.1rem;
		}
	}
}

.p-img.p-img{

	&:has(+ *){

		svg,
		p.p-img a,
		.wp-caption,
		video,
		img{
			margin-top: 5.5rem;
			margin-bottom: 5.1rem;

			@media (max-width: $md){
				margin-top: -.9rem;
				margin-bottom: 2.9rem;
			}
		}
	}
}

svg,
p.p-img a,
.wp-caption,
video,
img{
	max-width: 100%;
	width: auto;
	height: auto;
	display: block;

	&.alignnone{
		margin: 5.7em 0 5.7em;

		@media (max-width: $md){
			margin: 5.3em 0 5.3em;
		}
	}

	&.alignright{
		float:right;
		margin: 5.7em 0 5.7em 5.7em;

		@media (max-width: $md){
			margin: 5.3em 0 5.3em;
		}
	}

	&.alignleft{
		float:left;
		margin: 5.7em 5.7em 5.7em 0;

		@media (max-width: $md){
			margin: 5.3em 5.3em 5.3em 0;
		}
	}

	&.aligncenter{
		clear: both;
		display: block;
		margin: 5.7em auto 5.7em;

		@media (max-width: $md){
			margin: 5.3em auto 5.3em;
		}
	}
}

a img.alignright {
	float:right;
	margin: 1.4em 0 1.4em 1.4em;
}

a img.alignleft {
	float:left;
	margin: 1.4em 1.4em 1.4em 0;
}

a img.aligncenter {
	clear: both;
	display: block;
	margin: 1.4em auto 1.4em;
}

::selection {
	background: darken($colour, 5%);
	color: $white;
}

::-moz-selection {
	background: darken($colour, 5%);
	color: $white;
}

.fieldset{
	margin-bottom: 0;

	.field{
		position: relative;
		padding-bottom: 1px;
	}

	div.mage-error[generated]{
		position: absolute;
		bottom: 5px;
	}
}

.wpcf7-not-valid.wpcf7-not-valid.wpcf7-not-valid:focus-visible,
input:focus-visible,
textarea:focus-visible,
select:focus-visible,
input[name][name][name]:focus-visible,
textarea[name][name][name]:focus-visible,
select[name][name][name]:focus-visible{
	border-color: $blue!important;
}

.wpcf7-not-valid.wpcf7-not-valid.wpcf7-not-valid:focus-visible,
input.mage-error:focus-visible,
select.mage-error:focus-visible,
textarea.mage-error:focus-visible,
.wpcf7-not-valid.wpcf7-not-valid.wpcf7-not-valid,
li.gfield.error input,
.gfield_error input,
.gfield_error select,
.gfield_error .dk-select,
.gfield_error textarea,
input.mage-error,
select.mage-error,
textarea.mage-error{
	border-color: $red!important;
}

div.mage-error[generated] {
	color: $red;
	position: absolute;
	margin-top: 4px;
	line-height: 1.1em;
	padding-top: 2px;
	margin-left: 0px;
	text-align: left;
}

pre{
	font-size: resize(10px);
	@include font-size(10);
}

.main-wrapper{
	padding-top: 1px;
	margin-top: -1px;
	padding-bottom: 0;
	width: 100%;
	width: 100dvw;

	section{
		overflow: hidden;
		overflow: clip;
	}
}

.inner{
	position: relative;
}

img.full-bg,
.full-bg{
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
}

img.full-bg{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: 50% 50%;
}