.pagination.pagination.pagination{
	margin-bottom: 0;
	vertical-align: middle;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	position: relative;
	margin-top: 5.4rem;

	@media (max-width: $md){
		margin-top: 3.2rem;
	}

	.pagination-mid{
		display: flex;
		justify-content: center;
		align-items: center;

		div{
			text-align: center;
			width: auto;
			@include font-size(18);
			@include line-height(18,23);
			font-weight: 600;
			position: relative;
			z-index: 1;
			color: $blue;
			display: inline-block;

			&.active{
				pointer-events: none;

				a{
					border-bottom: .2rem solid $blue-light;
					font-weight: 600;
				}
			}

			a{
				@include font-size(18);
				@include line-height(18,23);
				display: inline-block;
				text-decoration: none;
				padding: .1rem .4rem;
				margin: 0 1.1rem;
				border-bottom: .2rem solid transparent;

				&:hover,
				&:focus-visible{
					border-bottom: .2rem solid $blue-light;
				}
			}

			&.ellipse{
				color: $blue-dark;
				margin: 0 1.5rem;
			}
		}
	}

	div{

		&.next,
		&.prev{

			span{
				opacity: .4;
			}
			
			a{
			
				text-decoration: none;
				margin: 0;

				svg{

					*{
						fill: $blue-light;
					}
				}

				&:hover,
				&:focus-visible{
					color: $blue;

					svg{

						*{
							fill: $blue;
						}
					}
				}
			}
		}

		&.next{
			margin-left: 2.5rem;
		}

		&.prev{
			margin-right: 2.5rem;
		}
	}
}

.facetwp-pager{
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	.facetwp-page:not(.next):not(.prev){
		@include font-size(18);
		@include line-height(18,23);
		display: inline-block;
		text-decoration: none;
		padding: .1rem .4rem;
		margin: 0 1.1rem;
		border-bottom: .2rem solid transparent;

		&:hover,
		&:focus-visible{
			border-bottom: .2rem solid $blue-light;
		}

		&.active{
			pointer-events: none;
			border-bottom: .2rem solid $blue-light;
			font-weight: 600;
		}
	}

	.facetwp-page.next,
	.facetwp-page.prev{
		aspect-ratio: 33.3/12.9;
		width: 3.33rem;
		background-size: 100% auto;
		background-repeat: no-repeat;
		@include font-size(0);
	}

	.facetwp-page.next{
		margin-left: 2.5rem;
		background-image: url(../images/slide-next.svg);
	}

	.facetwp-page.prev{
		margin-right: 2.5rem;
		background-image: url(../images/slide-prev.svg);
	}
}
