.form {
  background-color: #f2faff;
  padding-top: 10rem;
  padding-bottom: 10rem;
  @media (max-width: $md){
    padding-top: 3rem;
    padding-bottom: 5rem;
  }
  * {
    margin-top: 0;
    font-family: "Gilroy";
  }
  [type="button"]{
    text-decoration: none;
  }
  &-container {
    max-width: 127rem;
    padding-left: 3.1rem;
    padding-right: 3.1rem;
    margin: 0 auto;
  }
  .circle-progress-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.5rem;
    width: 3.5rem;
    position: relative;
    margin-bottom: 4.5rem;
    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  .form .gf_page_steps .gf_step .gf_step_number {
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem;
    line-height: 1;
    border: 1px solid #2b99d9;
    color: #2b99d9;
    background-color: #f2faff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
  }

  .gform_wrapper.gravity-theme .gf_step_completed .gf_step_number::after {
    align-items: center;
    color: #fff;
    content: '';
    background-image: url(../images/tick.svg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: .9719rem auto;
    display: flex;
    font-family: gform-icons-theme;
    font-size: 2.4rem;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 5;
  }

  .gform_wrapper.gravity-theme .gf_step_completed .gf_step_number {
    position: relative;
  }

  .gform_wrapper.gravity-theme .gf_step_completed .gf_step_number::before {
    background: #607382;
    border: .2rem solid #607382;
    border-radius: 2rem;
    content: "";
    display: table-cell;
    height: 4rem;
    left: 0;
    position: absolute;
    top: 0;
    width: 4rem;
  }

  .form .gf_page_steps .gf_step.gf_step_completed .gf_step_number:before {
    width: 3rem;
    height: 3rem;
    background-color: #2b99d9;
    border-color: #2b99d9;
  }

  .gform_wrapper.gravity-theme .field_sublabel_below .ginput_complex {
    align-items: flex-start;
  }

  .gform_wrapper.gravity-theme .ginput_complex {
    display: flex;
    flex-flow: row wrap;
  }

  .gform_wrapper.gravity-theme .ginput_complex .ginput_container_date {
    flex-flow: row wrap;
    max-width: 30%;
    width: auto;
    min-width: 8.4rem;
  }

  .gform_wrapper.gravity-theme .ginput_container_date+.ginput_container_date {
    margin-left: 2%;
  }

  .gform_wrapper.gravity-theme .ginput_container_date {
    align-content: flex-start;
    align-items: center;
    display: flex;
  }

  .gform-field-label{
    color: $blue-dark;
  }

  select{
    height: auto;
  }

  .circle-progress {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    svg {
      path {
        &:first-of-type {
          stroke: #b6ddf4 !important;
        }
        &:last-of-type {
          stroke: #2b99d9 !important;
        }
      }
    }
  }

  .progressbar-text {
    white-space: nowrap;
    font-size: 1.6rem !important;
    left: 9rem !important;
  }

  .circle-progress-text {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 1.5em;
  }

  &-title {
    margin-bottom: 3.2rem;
    @media (max-width: $md){
      margin-bottom: 2rem;
    }
    * {
      margin-bottom: 2rem;
      color: #002a57;
      @media (max-width: $md){
        margin-bottom: 1.6rem;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    h6 {
      font-size: 2rem;
      line-height: 3rem;
      font-weight: 500;
      @media (max-width: $md){
        font-size: 1.8rem;
        line-height: 2.2rem;
      }
    }
    h1 {
      font-size: 7rem;
      line-height: 8rem;
      font-weight: 600;
      @media (max-width: $md){
        font-size: 4rem;
        line-height: 4.5rem;
      }
    }
    p {
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: #002a57;
    }
  }
  .gform_confirmation_wrapper {
    position: relative;
    &:after {
      content: "";
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 99;
      background-color: #f2faff;
      backdrop-filter: blur(.5rem);
      opacity: 0.8;
    }
    .gform_confirmation_message {
      position: fixed;
      top: 13rem;
      left: 50%;
      transform: translateX(-50%);
      padding: 5rem;
      background-color: #fff;
      border-left: 4.5rem solid #2b99d9;
      z-index: 999;
      color: $blue-dark;
      @media (max-width: $md){
        top: 12rem;
        padding: 6.5rem 4rem;
        border-left: 0;
        width: 70vw;
      }
    }
    .form_popup_close {
      position: absolute;
      top: 2.5rem;
      right: 2.5rem;
      width: 2.2rem;
      height: 2.2rem;
      background-image: url(../images/popup-close-icon.svg);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
    h2 {
      font-size: 5rem;
      line-height: 6rem;
      margin-bottom: 5rem;
      @media (max-width: $md){
        font-size: 3rem;
        line-height: 3.5rem;
        margin-bottom: 3rem;
      }
    }
    p {
      font-size: 2rem;
      line-height: 3rem;
      color: #002a57;
      @media (max-width: $md){
        font-size: 1.6rem;
        line-height: 1.9rem;
      }
    }
  }
  .gform_validation_errors {
    padding: 1.5rem 7rem !important;
    background-image: url(../images/field-error-icon.svg) !important;
    background-size: 2.2rem !important;
    background-position: center left 2rem !important;
    background-repeat: no-repeat !important;
    margin-bottom: 5rem !important;
    border: none !important;
    border-radius: 0 !important;
    background-color: #fde8eb !important;
    color: #d11342 !important;
    max-width: 50% !important;
    box-shadow: none !important;
    @media (max-width: $md){
      max-width: 100% !important;
    }
    .gform-icon {
      display: none !important;
    }
    .gform_submission_error {
      font-size: 1.6rem !important;
      line-height: 1.9rem !important;
      color: #d11342 !important;
      margin: 0 !important;
      display: block;
    }
  }
  .gf_page_steps {
    margin-bottom: 10rem !important;
    border-bottom: none !important;
    max-width: 86.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    @media (max-width: $md){
      margin-bottom: 4.5rem !important;
      flex-direction: column;
      align-items: flex-start;
      display: none;
    }
    &:after {
      content: "";
      position: absolute;
      top: 1.5rem;
      right: 5rem;
      left: 1.5rem;
      height: .1rem;
      background-color: #2b99d9;
      opacity: 0.3;
      z-index: 1;
      @media (max-width: $md){
        display: none;
      }
    }
    .gf_step {
      text-align: center;
      margin: 0 !important;
      position: relative;
      z-index: 2;
      @media (max-width: $md){
        text-align: left;
        margin-bottom: 2rem !important;
      }
      &.gf_step_active {
        .gf_step_number {
          background-color: #2b99d9;
          color: #fff;
          border-color: #2b99d9;
        }
      }
      &.gf_step_completed {
        .gf_step_number {
          &:before {
            width: 3rem;
            height: 3rem;
            background-color: #2b99d9;
            border-color: #2b99d9;
          }
          &:after {
            padding-top: .2rem;
          }
        }
      }
      .gf_step_number {
        width: 3rem;
        height: 3rem;
        font-size: 1.5rem;
        line-height: 1;
        border: .1rem solid #2b99d9;
        color: #2b99d9;
        background-color: #f2faff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
        border-radius: 3rem;
      }
      .gf_step_label {
        padding-left: 0;
        display: block;
        font-size: 1.4rem;
        line-height: 1.7rem;
        font-weight: 400;
      }
    }
  }
  [data-conditional-logic="hidden"] {
    display: block !important;
    height: 0 !important;
    overflow: hidden;
    padding: 0 !important;
    visibility: hidden;
    margin: 0 !important;
    position: absolute!important;
    * {
      display: none !important;
    }
  }
  .half-width {
    @media screen and (min-width: 768px) {
      max-width: 45%;
    }
  }
  #gform_10 {
    .gform_body {
      .gform_page {
        &#gform_page_10_1 {
          .gform_page_footer {
            justify-content: flex-end;
          }
          .gform_page_fields {
            .gform_fields {
              .gfield {
                max-width: 61rem;
                @media (max-width: $md){
                  max-width: 100%;
                }
                &_html {
                  margin-bottom: 5rem;
                  @media (max-width: $md){
                    margin-bottom: 4rem;
                  }
                }
              }
            }
          }
        }

        .gfield--width-half{
          width: calc(50% - 6.2rem);

          @media (max-width: $md){
            width: 100%;
          }
        }

        .gfield--width-full{
          width: 100%;
          max-width: 100%;
        }

        .gfield--width-third{
          width: calc(33.3% - 6.2rem);

          @media (max-width: $md){
            width: 100%;
          }
        }

        .gfield--width-quarter{
          width: calc(25% - 9.3rem);
  
          @media (max-width: $md){
            width: 100%;
          }
        }

        .gform_page_fields {
          .gform_fields {
            grid-row-gap: 0;
            @media screen and (min-width: 135rem) {
              grid-column-gap: 12rem;
            }
            @media (max-width: $md){
              grid-column-gap: 0;
            }
            .spacer {
              @media (max-width: $md){
                display: none !important;
              }
            }
            .gfield {
              margin-bottom: 4rem;
              @media (max-width: $md){
                grid-column: 1 / -1;
              }
              &_validation_message {
                padding-top: 1.3rem !important;
                padding: 1.2992rem 2.4rem;
                border: none;
                background-color: #fde8eb;
                color: #d11342;
                margin-block-start: .8rem;
              }
              &_required {
                display: none;
              }
              &_error {
                > .ginput_container {
                  background-image: url(../images/field-error-icon.svg) !important;
                  background-size: 2.2rem !important;
                  background-position: center right 0 !important;
                  background-repeat: no-repeat !important;
                }
                label {
                  color: #002a57 !important;
                }
                input,
                select {
                  border-color: #d11342 !important;
                }
              }
              &_html {
                * {
                  margin-top: 0;
                  margin-bottom: 4rem;
                  @media (max-width: $md){
                    margin-bottom: 3rem;
                  }
                  &:last-child {
                    margin-bottom: 0;
                  }
                }
                br {
                  @media (max-width: $md){
                    display: none;
                  }
                }
                h2 {
                  font-size: 3.5rem;
                  line-height: 4.5rem;
                  color: #002a57;
                  font-weight: 600;
                  @media (max-width: $md){
                    font-size: 2.5rem;
                    line-height: 3rem;
                  }
                }
                p {
                  font-size: 2rem;
                  line-height: 2.4rem;
                  color: #002a57;
                  @media (max-width: $md){
                    font-size: 1.6rem;
                    line-height: 1.9rem;
                  }
                }
              }
              &--type-date {
                select {
                  padding: 0 1rem 1rem 0;
                  border: none;
                  border-bottom: 1px solid rgba($color: #002a57, $alpha: 0.4);
                  color: #2b99d9;
                  font-size: 1.6rem;
                  line-height: 1.9rem;
                  appearance: none;
                  background-image: url(../images/select-arrow.svg);
                  background-size: 1.2rem;
                  background-position: top .6rem right 1rem;
                  background-repeat: no-repeat;
                  background-color: transparent;
                  @media (max-width: $md){
                    font-size: 1.8rem;
                    line-height: 2.2rem;
                  }
                }
              }
              &#field_10_48 {
                .gchoice {
                  margin-right: 0;
                  margin-bottom: 2rem;
                  max-width: 33.33%;
                  flex: 0 0 33.33%;
                  width: 100%;
                  @media (max-width: $md){
                    max-width: 100%;
                    flex: 0 0 100%;
                    &:last-of-type {
                      margin-bottom: 0;
                    }
                  }
                }
              }
              &#field_10_63 {

                @media (min-width: $md + 1){
                  .gfield_checkbox{
                    width: calc(100% + 2rem);
                    max-width: calc(100% + 2rem);
                  }
                }
                .gchoice {
                  margin-right: 0;
                  margin-bottom: 2rem;
                  max-width: 16.66%;
                  flex: 0 0 16.66%;
                  width: 100%;
                  @media (min-width: $md + 1){
                    flex: 0 0 15.66%;
                    max-width: 15.66%;
                    margin-right: 1%;
                  }
                  @media (max-width: $md){
                    max-width: 100%;
                    flex: 0 0 100%;
                    &:last-of-type {
                      margin-bottom: 0;
                    }
                  }
                }
              }
              &#field_10_75 {
                .gchoice {
                  margin-right: 0;
                  margin-bottom: 2rem;
                  max-width: 50%;
                  flex: 0 0 50%;
                  width: 100%;
                  @media (max-width: $md){
                    max-width: 100%;
                    flex: 0 0 100%;
                    &:last-of-type {
                      margin-bottom: 0;
                    }
                  }
                }
              }
              &#field_10_84 {
                .gchoice {
                  margin-right: 0;
                  margin-bottom: 2rem;
                  max-width: 25%;
                  flex: 0 0 25%;
                  width: 100%;
                  @media (max-width: $md){
                    max-width: 100%;
                    flex: 0 0 100%;
                    &:last-of-type {
                      margin-bottom: 0;
                    }
                  }
                }
              }
              &#field_10_38 {
                .gfield_label {
                  font-size: 1.6rem;
                }
              }
              &#field_10_39 {
                .gfield_label {
                  font-size: 1.6rem;
                }
              }
              .gfield_label {
                font-size: 2rem;
                line-height: 2.4rem;
                color: #002a57;
                font-weight: 500;
                margin-bottom: 2.5rem;
                @media (max-width: $md){
                  font-size: 1.8rem;
                  line-height: 2.2rem;
                }
              }
              .gfield_description {
                padding-top: 2rem;
              }
              .gfield_checkbox {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                @media (max-width: $md){
                  flex-direction: column;
                  align-items: flex-start;
                }
                .gchoice {
                  margin-right: 5rem;
                  @media (max-width: $md){
                    margin-right: 0;
                    margin-bottom: 2rem;
                  }
                  &:last-of-type {
                    margin-right: 0;
                    //margin-bottom: 0;
                  }
                }
              }
              .gfield_radio {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                @media (max-width: $md){
                  flex-direction: column;
                  align-items: flex-start;
                }
                .gchoice {
                  margin-right: 5rem;
                  @media (max-width: $md){
                    margin-right: 0;
                    margin-bottom: 2rem;
                  }
                  &:last-of-type {
                    margin-right: 0;
                    //margin-bottom: 0;
                  }
                }
              }
              &.gfield--type-consent {
                margin-left: 0;
                .ginput_container {
                  //padding-left: 4rem;
                  position: relative;
                }
              }
              /* Custom styles for Gravity Forms checkboxes and radio buttons */
              &.gfield--type-consent input[type="checkbox"] {
                width: 2rem;
                height: 2rem;
                border: 1px solid #2b99d9;
                border-radius: 0;
                appearance: none; /* Remove default styling */
                -webkit-appearance: none;
                -moz-appearance: none;
                outline: none;
                cursor: pointer;
                position: relative;
                margin-left: 0;
                position: absolute;
                left: 0;
              }

              &.gfield--type-consent input[type="checkbox"]:checked {
                background-color: #2b99d9;
                border: 1px solid #2b99d9;
              }

              &.gfield--type-consent input[type="checkbox"]:checked::before {
                content: "";
                display: block;
                width: 2rem; /* Adjust the size as needed */
                height: 2rem; /* Adjust the size as needed */
                border-radius: 0;
                background-color: #2b99d9;
                background-image: url(../images/check-white.svg);
                background-size: .8rem;
                background-position: center;
                background-repeat: no-repeat;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }

              /* Adjusting the label positioning for better alignment */
              &.gfield--type-consent label {
                padding-left: 0; /* Adjust the padding as needed */
                line-height: 2.5rem; /* Aligns label vertically with the input */
                font-size: 2rem;
                line-height: 2.4rem;
                font-weight: 600;
                color: #002a57;
                @media (max-width: $md){
                  font-size: 1.6rem;
                  line-height: 2.6rem;
                }
              }
              .ginput_container {
                .gfield_select {
                  padding: 0 1rem 1rem 0;
                  border: none;
                  border-bottom: 1px solid rgba($color: #002a57, $alpha: 0.4);
                  color: #002a57;
                  font-size: 1.6rem;
                  line-height: 1.9rem;
                  appearance: none;
                  background-image: url(../images/select-arrow.svg);
                  background-size: 1.2rem;
                  background-position: center right 1rem;
                  background-repeat: no-repeat;
                  background-color: transparent;
                  @media (max-width: $md){
                    font-size: 1.8rem;
                    line-height: 2.2rem;
                  }
                }
                /* Custom styles for Gravity Forms checkboxes and radio buttons */
                .gfield_checkbox input[type="checkbox"],
                .gfield_radio input[type="radio"] {
                  width: 2.5rem;
                  height: 2.5rem;
                  border: 1px solid #2b99d9;
                  border-radius: 50%; /* Makes the input round */
                  appearance: none; /* Remove default styling */
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  outline: none;
                  cursor: pointer;
                  position: relative;
                  margin-left: 0;
                }

                .gfield_checkbox input[type="checkbox"]:checked,
                .gfield_radio input[type="radio"]:checked {
                  background-color: #2b99d9;
                  border: 1px solid #2b99d9;
                }

                .gfield_checkbox input[type="checkbox"]:checked::before,
                .gfield_radio input[type="radio"]:checked::before {
                  content: "";
                  display: block;
                  width: 2.5rem; /* Adjust the size as needed */
                  height: 2.5rem; /* Adjust the size as needed */
                  border-radius: 50%;
                  background-color: #2b99d9;
                  background-image: url(../images/check-white.svg);
                  background-size: .8rem;
                  background-position: center;
                  background-repeat: no-repeat;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }

                /* Adjusting the label positioning for better alignment */
                .gfield_checkbox label,
                .gfield_radio label {
                  padding-left: 1rem; /* Adjust the padding as needed */
                  //line-height: 25px; /* Aligns label vertically with the input */
                }

                input[type="text"],
                input[type="email"],
                input[type="number"] {
                  padding: 0 1rem 1rem 0;
                  border: none;
                  border-bottom: 1px solid rgba($color: #002a57, $alpha: 0.4);
                  color: #2b99d9;
                  font-size: 1.6rem;
                  line-height: 1.9rem;
                  appearance: none;
                  background-color: transparent;
                  &::placeholder {
                    color: #2b99d9;
                    font-size: 1.6rem;
                    line-height: 1.9rem;
                  }
                }
              }
            }
            .gsection {
              border: none;
              padding: 0;
              height: 5rem;
              @media (max-width: $md){
                display: none;
              }
              &_title {
                display: none;
              }
            }
          }
        }
        .gform_page_footer {
          padding: 0 !important;
          margin: 9rem 0 0 !important;
          display: flex;
          align-items: center;
          justify-content: space-between;
          @media (max-width: $md){
            margin: 5rem 0 0 !important;
            flex-direction: column;
          }
          .gform_ajax_spinner {
            display: none !important;
          }
          .gform_previous_button {
            font-size: 1.8rem;
            line-height: 2.2rem;
            background-color: transparent;
            border: 1px solid #002a57;
            color: #002a57;
            padding: 1.2rem 2.8rem 1.2rem 7.3rem;
            background-image: url(../images/button-arrow-prev.svg);
            background-size: 3.2rem 1.2rem;
            background-position: center left 2.7rem;
            background-repeat: no-repeat;
            margin-bottom: 0;
            cursor: pointer;
            font-weight: 500;
            transition: all 0.2s ease-in-out;
            &:hover {
              background-color: #002a57;
              border-color: #002a57;
              color: #fff;
              background-image: url(../images/button-arrow-prev-white.svg);
              transition: all 0.2s ease-in-out;
            }
            @media (max-width: $md){
              font-size: 1.6rem;
              line-height: 1.9rem;
              background-size: 2.8rem 1.1rem;
              background-position: center left 2.2rem;
              margin-top: 1.5rem;
              width: 100%;
              margin-left: 0;
              order: 2;
              padding: 1.2rem 2.2rem;
            }
            @media screen and (max-width: 35rem) {
              background-size: 2rem .8rem;
            }
          }

          @media (min-width: $md + 1){
            
            .gform_previous_button + .gform_next_button[data-conditional-logic="hidden"]{
              right: 0;
            }
          }

          .gform_next_button {
            font-size: 1.8rem;
            line-height: 2.2rem;
            background-color: #2b99d9;
            border: 1px solid #2b99d9;
            color: #fff;
            padding: 1.2rem 7.8rem 1.2rem 2.8rem;
            background-image: url(../images/button-arrow-next.svg);
            background-size: 3.2rem 1.2rem;
            background-position: center right 2.8rem;
            background-repeat: no-repeat;
            margin-bottom: 0;
            cursor: pointer;
            font-weight: 500;
            transition: all 0.2s ease-in-out;
            &:hover {
              background-color: #002a57;
              border-color: #002a57;
              color: #fff;
              background-position: center right 2.3rem;
              transition: all 0.2s ease-in-out;
            }
            
            &[data-conditional-logic="hidden"] {
              height: auto !important;
              padding: 1.2rem 7.8rem 1.2rem 2.8rem !important;
              visibility: visible !important;
              opacity: 1;
              background-color: #c3d5e0;
              border-color: #c3d5e0;
              @media (max-width: $md){
                padding: 1.2rem 2.8rem !important;
              }
            }
            @media (max-width: $md){
              font-size: 1.6rem;
              line-height: 1.9rem;
              background-size: 2.8rem 1.1rem;
              background-position: center right 2.2rem;
              width: 100%;
              margin-left: 0;
              order: 1;
              padding: 1.2rem 2.2rem;
              text-align: left;
            }
            @media screen and (max-width: 35rem) {
              background-size: 2rem .8rem;
            }
          }
          .gform_button {
            font-size: 1.8rem;
            line-height: 2.2rem;
            background-color: #2b99d9;
            border: 1px solid #2b99d9;
            color: #fff;
            padding: 1.2rem 7.8rem 1.2rem 2.8rem;
            background-image: url(../images/button-arrow-next.svg);
            background-size: 3.2rem 1.2rem;
            background-position: center right 2.8rem;
            background-repeat: no-repeat;
            margin-bottom: 0;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            &:hover {
              background-color: #002a57;
              border-color: #002a57;
              color: #fff;
              background-position: center right 2.3rem;
              transition: all 0.2s ease-in-out;
            }
            @media (max-width: $md){
              font-size: 1.6rem;
              line-height: 1.9rem;
              background-size: 2.8rem 1.1rem;
              width: 100%;
              margin-left: 0;
              order: 1;
              padding: 1.2rem 2.8rem;
            }
            @media screen and (max-width: 35rem) {
              background-size: 2rem .8rem;
            }
          }
        }
      }
    }
  }
  #gform_11 {
    .gform_body {

      .gfield--width-half{
        width: calc(50% - 6.2rem);

        @media (max-width: $md){
          width: 100%;
        }
      }

      .gfield--width-full{
        width: 100%;
        max-width: 100%;
      }

      .gfield--width-third{
        width: calc(33.3% - 6.2rem);

        @media (max-width: $md){
          width: 100%;
        }
      }

      .gfield--width-quarter{
        width: calc(25% - 9.3rem);

        @media (max-width: $md){
          width: 100%;
        }
      }

      .gform_fields {
        grid-row-gap: 0;
        grid-column-gap: 3rem;
        @media (max-width: $md){
          grid-column-gap: 0;
        }
        .spacer {
          @media (max-width: $md){
            display: none !important;
          }
        }
        .gfield {
          margin-bottom: 4rem;
          @media (max-width: $md){
            grid-column: 1 / -1;
          }
          &_validation_message {
            padding-top: 1.3rem;
            border: none;
          }
          &_required {
            display: none;
          }
          &_error {
            > .ginput_container {
              background-image: url(../images/field-error-icon.svg) !important;
              background-size: 2.2rem !important;
              background-position: center right 0 !important;
              background-repeat: no-repeat !important;
            }
            label {
              color: #002a57 !important;
            }
            input,
            select {
              border-color: #d11342 !important;
            }
          }
          &_html {
            * {
              margin-top: 0;
              margin-bottom: 4rem;
              @media (max-width: $md){
                margin-bottom: 3rem;
              }
              &:last-child {
                margin-bottom: 0;
              }
            }
            h2 {
              font-size: 3.5rem;
              line-height: 4.5rem;
              color: #002a57;
              font-weight: 600;
              @media (max-width: $md){
                font-size: 2.5rem;
                line-height: 3rem;
              }
            }
            p {
              font-size: 2rem;
              line-height: 2.4rem;
              color: #002a57;
              @media (max-width: $md){
                font-size: 1.8rem;
                line-height: 2.2rem;
              }
            }
          }
          &--type-date {
            select {
              padding: 0 1rem 1rem 0;
              border: none;
              border-bottom: 1px solid rgba($color: #002a57, $alpha: 0.4);
              color: #2b99d9;
              font-size: 1.6rem;
              line-height: 1.9rem;
              appearance: none;
              background-image: url(../images/select-arrow.svg);
              background-size: 1.2rem;
              background-position: top .6rem right 1rem;
              background-repeat: no-repeat;
              background-color: transparent;
              @media (max-width: $md){
                font-size: 1.8rem;
                line-height: 2.2rem;
              }
            }
          }
          .gfield_label {
            font-size: 2rem;
            line-height: 2.4rem;
            color: #002a57;
            font-weight: 500;
            margin-bottom: 2.5rem;
            @media (max-width: $md){
              font-size: 1.8rem;
              line-height: 2.2rem;
            }
          }
          .gfield_description {
            padding-top: 2rem;
          }
          .gfield_checkbox {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            @media (max-width: $md){
              flex-direction: column;
              align-items: flex-start;
            }
            .gchoice {
              margin-right: 5rem;
              @media (max-width: $md){
                margin-right: 0;
                margin-bottom: 2rem;
              }
              &:last-of-type {
                margin-right: 0;
                //margin-bottom: 0;
              }
            }
          }
          .gfield_radio {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            @media (max-width: $md){
              flex-direction: column;
              align-items: flex-start;
            }
            .gchoice {
              margin-right: 5rem;
              @media (max-width: $md){
                margin-right: 0;
                margin-bottom: 2rem;
              }
              &:last-of-type {
                margin-right: 0;
                //margin-bottom: 0;
              }
            }
          }
          &.gfield--type-consent {
            margin-left: 0;
            .ginput_container {
              //padding-left: 4rem;
              position: relative;
            }
          }
          /* Custom styles for Gravity Forms checkboxes and radio buttons */
          &.gfield--type-consent input[type="checkbox"] {
            width: 2rem;
            height: 2rem;
            border: 1px solid #2b99d9;
            border-radius: 0;
            appearance: none; /* Remove default styling */
            -webkit-appearance: none;
            -moz-appearance: none;
            outline: none;
            cursor: pointer;
            position: relative;
            margin-left: 0;
            position: absolute;
            left: 0;
          }

          &.gfield--type-consent input[type="checkbox"]:checked {
            background-color: #2b99d9;
            border: 1px solid #2b99d9;
          }

          &.gfield--type-consent input[type="checkbox"]:checked::before {
            content: "";
            display: block;
            width: 2rem; /* Adjust the size as needed */
            height: 2rem; /* Adjust the size as needed */
            border-radius: 0;
            background-color: #2b99d9;
            background-image: url(../images/check-white.svg);
            background-size: .8rem;
            background-position: center;
            background-repeat: no-repeat;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          /* Adjusting the label positioning for better alignment */
          &.gfield--type-consent label {
            padding-left: 0; /* Adjust the padding as needed */
            line-height: 2.5rem; /* Aligns label vertically with the input */
            font-size: 2rem;
            line-height: 2.4rem;
            font-weight: 600;
            color: #002a57;
            @media (max-width: $md){
              font-size: 1.6rem;
              line-height: 2.6rem;
            }
          }
          .ginput_container {
            .gfield_select {
              padding: 0 1rem 1rem 0;
              border: none;
              border-bottom: 1px solid rgba($color: #002a57, $alpha: 0.4);
              color: #002a57;
              font-size: 1.6rem;
              line-height: 1.9rem;
              appearance: none;
              background-image: url(../images/select-arrow.svg);
              background-size: 1.2rem;
              background-position: center right 1rem;
              background-repeat: no-repeat;
              background-color: transparent;
              @media (max-width: $md){
                font-size: 1.8rem;
                line-height: 2.2rem;
              }
            }
            /* Custom styles for Gravity Forms checkboxes and radio buttons */
            .gfield_checkbox input[type="checkbox"],
            .gfield_radio input[type="radio"] {
              width: 2.5rem;
              height: 2.5rem;
              border: 1px solid #2b99d9;
              border-radius: 50%; /* Makes the input round */
              appearance: none; /* Remove default styling */
              -webkit-appearance: none;
              -moz-appearance: none;
              outline: none;
              cursor: pointer;
              position: relative;
            }

            .gfield_checkbox input[type="checkbox"]:checked,
            .gfield_radio input[type="radio"]:checked {
              background-color: #2b99d9;
              border: 1px solid #2b99d9;
            }

            .gfield_checkbox input[type="checkbox"]:checked::before,
            .gfield_radio input[type="radio"]:checked::before {
              content: "";
              display: block;
              width: 2.5rem; /* Adjust the size as needed */
              height: 2.5rem; /* Adjust the size as needed */
              border-radius: 50%;
              background-color: #2b99d9;
              background-image: url(../images/check-white.svg);
              background-size: .8rem;
              background-position: center;
              background-repeat: no-repeat;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }

            /* Adjusting the label positioning for better alignment */
            .gfield_checkbox label,
            .gfield_radio label {
              padding-left: 1.5rem; /* Adjust the padding as needed */
              //line-height: 25px; /* Aligns label vertically with the input */
            }

            input[type="text"],
            input[type="email"],
            input[type="number"],
            textarea {
              padding: 0 1rem 1rem 0;
              border: none;
              border-bottom: 1px solid rgba($color: #002a57, $alpha: 0.4);
              color: #2b99d9;
              font-size: 1.6rem;
              line-height: 1.9rem;
              appearance: none;
              background-color: transparent;
              &::placeholder {
                color: #2b99d9;
                font-size: 1.6rem;
                line-height: 1.9rem;
              }
            }

            textarea {
              height: 17.5rem;
              border-top: 1px solid #002a57;
              padding-top: 2rem;
              @media (max-width: $md){
                border-top: none;
                padding-top: 0;
                height: 7.5rem;
              }
            }
          }
          &.form-acknowledgement {
            p {
              margin-bottom: 2.5rem;
              font-weight: 500;
              &.small-text {
                font-size: 1.6rem;
                line-height: 2.5rem;
                font-weight: 400;
              }
              a {
                color: #2b99d9;
                text-decoration: underline;
                &:hover {
                  text-decoration: none;
                }
              }
            }
          }
        }
        .gsection {
          border: none;
          padding: 0;
          height: 5rem;
          @media (max-width: $md){
            display: none;
          }
          &_title {
            display: none;
          }
        }
      }
    }
    .gform_footer {
      padding: 0 !important;
      margin: 9rem 0 0 !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: $md){
        margin: 5rem 0 0 !important;
        flex-direction: column;
      }
      .gform_ajax_spinner {
        display: none !important;
      }
      .gform_previous_button {
        font-size: 1.8rem;
        line-height: 2.2rem;
        background-color: transparent;
        border: 1px solid #002a57;
        color: #002a57;
        padding: 1.2rem 2.8rem 1.2rem 7.3rem;
        background-image: url(../images/button-arrow-prev.svg);
        background-size: 3.2rem 1.2rem;
        background-position: center left 27px;
        background-repeat: no-repeat;
        margin-bottom: 0;
        cursor: pointer;
        font-weight: 500;
        transition: all 0.2s ease-in-out;
        &:hover {
          background-color: #002a57;
          border-color: #002a57;
          color: #fff;
          background-image: url(../images/button-arrow-prev-white.svg);
          transition: all 0.2s ease-in-out;
        }
        @media (max-width: $md){
          font-size: 1.6rem;
          line-height: 1.9rem;
          background-size: 2.8rem 1.1rem;
          background-position: center left 2.2rem;
          margin-top: 1.5rem;
          width: 100%;
          margin-left: 0;
          order: 2;
          padding: 1.2rem 2.2rem;
        }
        @media screen and (max-width: 35rem) {
          background-size: 2rem .8rem;
        }
      }
      .gform_next_button {
        font-size: 1.8rem;
        line-height: 2.2rem;
        background-color: #2b99d9;
        border: 1px solid #2b99d9;
        color: #fff;
        padding: 1.2rem 7.8rem 1.2rem 2.8rem;
        background-image: url(../images/button-arrow-next.svg);
        background-size: 3.2rem 1.2rem;
        background-position: center right 2.8rem;
        background-repeat: no-repeat;
        margin-bottom: 0;
        cursor: pointer;
        font-weight: 500;
        transition: all 0.2s ease-in-out;
        &:hover {
          background-color: #002a57;
          border-color: #002a57;
          color: #fff;
          background-position: center right 2.3rem;
          transition: all 0.2s ease-in-out;
        }
        &[data-conditional-logic="hidden"] {
          height: auto !important;
          padding: 1.2rem 7.8rem 1.2rem 2.8rem !important;
          visibility: visible !important;
          opacity: 1;
          background-color: #c3d5e0;
          border-color: #c3d5e0;
          @media (max-width: $md){
            padding: 1.2rem 2.8rem !important;
          }
        }
        @media (max-width: $md){
          font-size: 1.6rem;
          line-height: 1.9rem;
          background-size: 2.8rem 1.1rem;
          background-position: center right 2.2rem;
          width: 100%;
          margin-left: 0;
          order: 1;
          padding: 1.2rem 2.2rem;
          text-align: left;
        }
        @media screen and (max-width: 35rem) {
          background-size: 2rem .8rem;
        }
      }
      .gform_button {
        font-size: 1.8rem;
        line-height: 2.2rem;
        background-color: #2b99d9;
        border: 1px solid #2b99d9;
        color: #fff;
        padding: 1.2rem 7.8rem 1.2rem 2.8rem;
        background-image: url(../images/button-arrow-next.svg);
        background-size: 3.2rem 1.2rem;
        background-position: center right 2.8rem;
        background-repeat: no-repeat;
        margin-bottom: 0;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        &:hover {
          background-color: #002a57;
          border-color: #002a57;
          color: #fff;
          background-position: center right 2.3rem;
          transition: all 0.2s ease-in-out;
        }
        @media (max-width: $md){
          font-size: 1.6rem;
          line-height: 1.9rem;
          background-size: 2.8rem 1.1rem;
          width: 100%;
          margin-left: 0;
          order: 1;
          padding: 1.2rem 2.8rem;
        }
        @media screen and (max-width: 35rem) {
          background-size: 2rem .8rem;
        }
      }
    }
  }
  #gform_12 {
    .gform_body {
      .gform_page {
        &#gform_page_12_1 {
          .gform_page_footer {
            justify-content: flex-end;
          }
          .gform_page_fields {
            .gform_fields {
              .gfield {
                //max-width: 60rem;
                @media (max-width: $md){
                  max-width: 100%;
                }
                &_html {
                  margin-bottom: 2rem;
                }
              }
            }
          }
        }

        .gfield--width-half{
          width: calc(50% - 6.2rem);

          @media (max-width: $md){
            width: 100%;
          }
        }

        .gfield--width-full{
          width: 100%;
          max-width: 100%;
        }

        .gfield--width-third{
          width: calc(33.3% - 6.2rem);

          @media (max-width: $md){
            width: 100%;
          }
        }

        .gfield--width-quarter{
          width: calc(25% - 9.3rem);
  
          @media (max-width: $md){
            width: 100%;
          }
        }

        .gform_page_fields {
          .gform_fields {
            // grid-row-gap: 0;
            // grid-column-gap: 12rem;
            // @media (max-width: $md){
            //   grid-column-gap: 0;
            // }
            .spacer {
              @media (max-width: $md){
                display: none !important;
              }
            }
            .gfield {
              margin-bottom: 3.1rem;
              @media (max-width: $md){
                grid-column: 1 / -1;
              }
              &_validation_message {
                padding-top: 1.3rem !important;
                padding: 1.2992rem 2.4rem;
                border: none;
                background-color: #fde8eb;
                color: #d11342;
                margin-block-start: .8rem;
                width: 100%;
              }
              &_required {
                display: none;
              }
              &_error {
                > .ginput_container {
                  background-image: url(../images/field-error-icon.svg) !important;
                  background-size: 2.2rem !important;
                  background-position: center right 0 !important;
                  background-repeat: no-repeat !important;
                }
                label {
                  color: #002a57 !important;
                }
                input,
                select {
                  border-color: #d11342 !important;
                }
              }
              &#field_12_46,
              &#field_12_53 {
                p {
                  font-weight: 500;
                }
              }
              &#field_12_44,
              &#field_12_54,
              &#field_12_64,
              &#field_12_67 {
                .gfield_checkbox,
                .gfield_radio {
                  justify-content: space-between;
                }
                .gchoice {
                  margin-right: 0;
                  margin-bottom: 2rem;
                  @media (max-width: $md){
                    &:last-of-type {
                      margin-bottom: 0;
                    }
                  }
                }
              }
              &_html {
                * {
                  margin-top: 0;
                  margin-bottom: 3.1rem;
                  @media (max-width: $md){
                    margin-bottom: 3rem;
                  }
                  &:last-child {
                    margin-bottom: 0;
                  }
                }
                h2 {
                  font-size: 3.5rem;
                  line-height: 4.5rem;
                  color: #002a57;
                  font-weight: 600;
                  @media (max-width: $md){
                    font-size: 2.5rem;
                    line-height: 3rem;
                  }
                }
                p {
                  font-size: 2rem;
                  line-height: 2.4rem;
                  color: #002a57;
                  @media (max-width: $md){
                    font-size: 1.6rem;
                    line-height: 1.9rem;
                  }
                }
              }
              &--type-date {
                select {
                  padding: 0 1rem 1rem 0;
                  border: none;
                  border-bottom: 1px solid rgba($color: #002a57, $alpha: 0.4);
                  color: #2b99d9;
                  font-size: 1.6rem;
                  line-height: 1.9rem;
                  appearance: none;
                  background-image: url(../images/select-arrow.svg);
                  background-size: 1.2rem;
                  background-position: top .6rem right 1rem;
                  background-repeat: no-repeat;
                  background-color: transparent;
                  @media (max-width: $md){
                    font-size: 1.8rem;
                    line-height: 2.2rem;
                  }
                }
              }
              .gfield_label {
                font-size: 2rem;
                line-height: 2.4rem;
                color: #002a57;
                font-weight: 500;
                margin-bottom: 2.5rem;
                @media (max-width: $md){
                  font-size: 1.8rem;
                  line-height: 2.2rem;
                }
              }
              .gfield_description {
                padding-top: 2rem;
              }
              .gfield_checkbox {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                @media (max-width: $md){
                  flex-direction: column;
                  align-items: flex-start;
                }
                .gchoice {
                  margin-right: 5rem;
                  @media (max-width: $md){
                    margin-right: 0;
                    margin-bottom: 2rem;
                  }
                  &:last-of-type {
                    margin-right: 0;
                    //margin-bottom: 0;
                  }
                }
              }
              .gfield_radio {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                @media (max-width: $md){
                  flex-direction: column;
                  align-items: flex-start;
                }
                .gchoice {
                  margin-right: 5rem;
                  @media (max-width: $md){
                    margin-right: 0;
                    margin-bottom: 2rem;
                  }
                  &:last-of-type {
                    margin-right: 0;
                    //margin-bottom: 0;
                  }
                }
              }
              &.gfield--type-consent {
                margin-left: 0;
                .ginput_container {
                  //padding-left: 4rem;
                  position: relative;
                }
              }
              /* Custom styles for Gravity Forms checkboxes and radio buttons */
              &.gfield--type-consent input[type="checkbox"] {
                width: 2rem;
                height: 2rem;
                border: 1px solid #2b99d9;
                border-radius: 0;
                appearance: none; /* Remove default styling */
                -webkit-appearance: none;
                -moz-appearance: none;
                outline: none;
                cursor: pointer;
                position: relative;
                margin-left: 0;
                position: absolute;
                left: 0;
              }

              &.gfield--type-consent input[type="checkbox"]:checked {
                background-color: #2b99d9;
                border: 1px solid #2b99d9;
              }

              &.gfield--type-consent input[type="checkbox"]:checked::before {
                content: "";
                display: block;
                width: 2rem; /* Adjust the size as needed */
                height: 2rem; /* Adjust the size as needed */
                border-radius: 0;
                background-color: #2b99d9;
                background-image: url(../images/check-white.svg);
                background-size: .8rem;
                background-position: center;
                background-repeat: no-repeat;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }

              /* Adjusting the label positioning for better alignment */
              &.gfield--type-consent label {
                padding-left: 0; /* Adjust the padding as needed */
                line-height: 2.5rem; /* Aligns label vertically with the input */
                font-size: 2rem;
                line-height: 2.4rem;
                font-weight: 600;
                color: #002a57;
                @media (max-width: $md){
                  font-size: 1.6rem;
                  line-height: 2.6rem;
                }
              }
              .ginput_container {
                .gfield_select {
                  padding: 0 1rem 1rem 0;
                  border: none;
                  border-bottom: 1px solid rgba($color: #002a57, $alpha: 0.4);
                  color: #002a57;
                  font-size: 1.6rem;
                  line-height: 1.9rem;
                  appearance: none;
                  background-image: url(../images/select-arrow.svg);
                  background-size: 1.2rem;
                  background-position: center right 1rem;
                  background-repeat: no-repeat;
                  background-color: transparent;
                  @media (max-width: $md){
                    font-size: 1.8rem;
                    line-height: 2.2rem;
                  }
                }
                /* Custom styles for Gravity Forms checkboxes and radio buttons */
                .gfield_checkbox input[type="checkbox"],
                .gfield_radio input[type="radio"] {
                  width: 2.5rem;
                  height: 2.5rem;
                  border: 1px solid #2b99d9;
                  border-radius: 50%; /* Makes the input round */
                  appearance: none; /* Remove default styling */
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  outline: none;
                  cursor: pointer;
                  position: relative;
                  margin-left: 0;
                }

                .gfield_checkbox input[type="checkbox"]:checked,
                .gfield_radio input[type="radio"]:checked {
                  background-color: #2b99d9;
                  border: 1px solid #2b99d9;
                }

                .gfield_checkbox input[type="checkbox"]:checked::before,
                .gfield_radio input[type="radio"]:checked::before {
                  content: "";
                  display: block;
                  width: 2.5rem; /* Adjust the size as needed */
                  height: 2.5rem; /* Adjust the size as needed */
                  border-radius: 50%;
                  background-color: #2b99d9;
                  background-image: url(../images/check-white.svg);
                  background-size: .8rem;
                  background-position: center;
                  background-repeat: no-repeat;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }

                /* Adjusting the label positioning for better alignment */
                .gfield_checkbox label,
                .gfield_radio label {
                  padding-left: 1rem; /* Adjust the padding as needed */
                  //line-height: 25px; /* Aligns label vertically with the input */
                }

                input[type="text"],
                input[type="email"],
                input[type="number"] {
                  padding: 0 1rem 1rem 0;
                  border: none;
                  border-bottom: 1px solid rgba($color: #002a57, $alpha: 0.4);
                  color: #2b99d9;
                  font-size: 1.6rem;
                  line-height: 1.9rem;
                  appearance: none;
                  background-color: transparent;
                  &::placeholder {
                    color: #2b99d9;
                    font-size: 1.6rem;
                    line-height: 1.9rem;
                  }
                }
              }
            }
            .gsection {
              border: none;
              padding: 0;
              height: 5rem;
              @media (max-width: $md){
                display: none;
              }
              &_title {
                display: none;
              }
            }
          }
        }
        .gform_page_footer {
          padding: 0 !important;
          margin: 9rem 0 0 !important;
          display: flex;
          align-items: center;
          justify-content: space-between;
          @media (max-width: $md){
            margin: 5rem 0 0 !important;
            flex-direction: column;
          }
          .gform_ajax_spinner {
            display: none !important;
          }
          .gform_previous_button {
            font-size: 1.8rem;
            line-height: 2.2rem;
            background-color: transparent;
            border: 1px solid #002a57;
            color: #002a57;
            padding: 1.2rem 2.8rem 1.2rem 7.3rem;
            background-image: url(../images/button-arrow-prev.svg);
            background-size: 3.2rem 1.2rem;
            background-position: center left 2.7rem;
            background-repeat: no-repeat;
            margin-bottom: 0;
            cursor: pointer;
            font-weight: 500;
            transition: all 0.2s ease-in-out;
            &:hover {
              background-color: #002a57;
              border-color: #002a57;
              color: #fff;
              background-image: url(../images/button-arrow-prev-white.svg);
              transition: all 0.2s ease-in-out;
            }
            @media (max-width: $md){
              font-size: 1.6rem;
              line-height: 1.9rem;
              background-size: 2.8rem 1.1rem;
              background-position: center left 2.2rem;
              margin-top: 1.5rem;
              width: 100%;
              margin-left: 0;
              order: 2;
              padding: 1.2rem 2.2rem;
            }
            @media screen and (max-width: 35rem) {
              background-size: 2rem .8rem;
            }
          }
          .gform_next_button {
            font-size: 1.8rem;
            line-height: 2.2rem;
            background-color: #2b99d9;
            border: 1px solid #2b99d9;
            color: #fff;
            padding: 1.2rem 7.8rem 1.2rem 2.8rem;
            background-image: url(../images/button-arrow-next.svg);
            background-size: 3.2rem 1.2rem;
            background-position: center right 2.8rem;
            background-repeat: no-repeat;
            margin-bottom: 0;
            cursor: pointer;
            font-weight: 500;
            transition: all 0.2s ease-in-out;
            &:hover {
              background-color: #002a57;
              border-color: #002a57;
              color: #fff;
              background-position: center right 2.3rem;
              transition: all 0.2s ease-in-out;
            }
            &[data-conditional-logic="hidden"] {
              height: auto !important;
              padding: 1.2rem 7.8rem 1.2rem 2.8rem !important;
              visibility: visible !important;
              opacity: 1;
              background-color: #c3d5e0;
              border-color: #c3d5e0;
              @media (max-width: $md){
                padding: 1.2rem 2.8rem !important;
              }
            }
            @media (max-width: $md){
              font-size: 1.6rem;
              line-height: 1.9rem;
              background-size: 2.8rem 1.1rem;
              background-position: center right 22px;
              width: 100%;
              margin-left: 0;
              order: 1;
              padding: 1.2rem 2.2rem;
              text-align: left;
            }
            @media screen and (max-width: 35rem) {
              background-size: 2rem .8rem;
            }
          }
          .gform_button {
            font-size: 1.8rem;
            line-height: 2.2rem;
            background-color: #2b99d9;
            border: 1px solid #2b99d9;
            color: #fff;
            padding: 1.2rem 7.8rem 1.2rem 2.8rem;
            background-image: url(../images/button-arrow-next.svg);
            background-size: 3.2rem 1.2rem;
            background-position: center right 2.8rem;
            background-repeat: no-repeat;
            margin-bottom: 0;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            &:hover {
              background-color: #002a57;
              border-color: #002a57;
              color: #fff;
              background-position: center right 23px;
              transition: all 0.2s ease-in-out;
            }
            @media (max-width: $md){
              font-size: 1.6rem;
              line-height: 1.9rem;
              background-size: 2.8rem 1.1rem;
              width: 100%;
              margin-left: 0;
              order: 1;
              padding: 1.2rem 2.8rem;
            }
            @media screen and (max-width: 35rem) {
              background-size: 2rem .8rem;
            }
          }
        }
      }
    }
  }
}

.page-id-9 {
  margin: 0;
}

.pagename-business-insurance {
  .circle-progress-wrapper {
    display: none !important;
  }
}