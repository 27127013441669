.breadcrumb{
	color: $colour;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	@include font-size(16);
	@include line-height(16,19);

	span,
	a{
		@include font-size(16);
		@include line-height(16,19);
		color: $white;

		@media (max-width: $md){
			@include font-size(13);
			@include line-height(13,15);
		}
	}

	span{
		display: inline;
		display: flex;
		//flex-wrap: wrap;
		align-items: center;
	}

	a{
		text-decoration: none;
		@include inline;
		vertical-align: middle;
		position: relative;
		margin-right: 2.6rem;

		@media (max-width: $md){
			margin-right: 1rem;
		}

		&:after{
			content: '';
			position: absolute;
			aspect-ratio: 6.0942/10.7739;
			object-fit: cover;
			object-position: 50% 50%;
			background-image: url(../images/breadcrumb-arrow.svg);
			background-repeat: no-repeat;
			width: .60942rem;
			right: -1.6rem;
			top: 50%;
			transform: translateY(-50%);
			pointer-events: none;
			background-size: 100% auto;

			@media (max-width: $md){
				width: .45942rem;
				right: -.8rem;
			}
		}

		&:hover,
		&:focus-visible{
			color: rgba($white, .6);
		}
	}

	> a{
		margin-left: 0;
	}

	.breadcrumb_last{
		text-decoration: none;
		@include inline;
		vertical-align: middle;
		position: relative;
	}
}

.breadcrumb-section{
	background-color: $blue-dark;
	
	@media (min-width: $md + 1){
		position: fixed;
		width: 100%;
		z-index: 5;

		+ section.breadcrumb-section + section,
		+ section{
			margin-top: 3.9rem;
		}
	}

	+ .breadcrumb-section{
		display: none;
	}

	.container{
		max-width: 131.2rem;
	}

	.row{
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
}