//---------------------------------------------------------//
@import 'normalize'; /* site wide variables (colours, font sizes) */
@import 'variables'; /* site wide variables (colours, fonts) */
@import 'toolkit'; /* mixin (@include font-size(??); , @include svg_img("../images/??");) and common classes (.clearfix, .img-replacment) */
@import 'general-tags-and-classes';
@import 'grid'; /* adapted boostap 4 grid*/
@import 'header';
@import 'footer';
@import 'pagination';
@import 'breadcrumb';
@import 'social';
@import 'buttons';
@import 'forms';
@import 'swiper';
@import 'fancybox';
@import 'tabs';
@import 'form';

.waypoint{
	transition: .2s;
	opacity: 0;
	visibility: hidden;
	transform: translateY(5.0rem);
	will-change: transform;

	&.animate{
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
		transition: .5s .1s;
	}
}

//
//!!BLOCKS START
//



body section{
	background-color: $white;

	&.parallax-window,
	&:has(.parallax-window){
		background-color: transparent;
	}

	.container{
		position: relative;
		z-index: 3;
	}

	*:last-child{
		margin-bottom: 0;
	}

	.btn-con{
		margin-top: 6.2rem;

		@media (max-width: $md){
			margin-top: 5.1rem;
		}
	}
}

.hero-with-filter-section{
	position: relative;
	overflow-x: clip!important;
	overflow-y: visible!important;
	z-index: 4;

	&::before{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 60%;
		height: 100%;
		z-index: 2;
		background: linear-gradient(to right, rgba(36, 58, 88, 0) 0%, rgba(14, 43, 82, 0.35) 25.62%, rgba(22, 37, 59, 0.59) 78.82%, rgba(30, 38, 49, 0) 100%);
		mix-blend-mode: multiply;
	}

	&.scrolled{

		.connect-hero.connect-hero.connect-hero.connect-hero{
			position: fixed;
			top: auto;
			bottom: 5.6rem;
			right: 8.7rem;
			width: 33.8rem;

			@media (max-width: $md){
				bottom: auto;
				top: 6.8rem;
				left: 0;
				width: 100%;
			}

			.connect{
				font-size: 1.8rem !important;
				padding: 1.434rem 1.85rem;

				@media (min-width: $md + 1){
					width: 100%;
				}

				i{
					width: 1.428rem;
					margin-left: 2.2rem !important;
					transform: translateY(0rem) rotateX(180deg);

					@media (max-width: $md){
						transform: translateY(0rem) rotateX(0deg);
					}
				}
			}

			.filter-bottom{
				top: auto;
				bottom: 6.9rem;

				@media (max-width: $md){
					bottom: auto;
					top: 0;
				}

				.top{
					padding: 3.68rem 2.71rem 2.02rem 1.71rem;

					h5{
						@include font-size(14);
						@include line-height(14,16);
						margin-bottom: 0.28em;
					}

					h2{
						@include font-size(25);
						@include line-height(25,30);
						font-weight: 500;
					}

					i.arrow{
						top: 3.5rem;
						right: 1.5rem;
						width: 1.6789rem;
					}
				}

				.bottom{
					position: relative;
					top: 0;
					padding: 0 1.61rem 2.62rem;
				}

				.filter-item{
					width: 100%;
					margin-right: 0;
					flex: 1 1 100%;
				}

				.facetwp-facet{
					margin-bottom: 1.4rem;
				}

				label{
					@include font-size(14);
					margin-bottom: 1rem;
				}

				.btn{
					width: 100%;
					margin-left: 0;
				}
			}
		}
	}

	video{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		object-fit: cover;
		object-position: 50% 50%;
	}

	.row{
		min-height: 40rem;
		padding-top: 5.7rem;
		padding-bottom: 4rem;
		align-items: center;

		@media (max-width: $md){
			min-height: 33.35rem;
			align-items: flex-end;
			padding-bottom: 3.3rem;
		}
	}

	.text{
		width: 67rem;

		*{
			color: $white;
		}
	}

	.connect-hero{
		position: absolute;
		top: calc(100% - 3.9rem);
		padding: 0 1.6rem 0 0;
		width: 100%;
		display: flex;
		justify-content: flex-end;
		
		@media (max-width: $md){
			top: calc(100% - 2.9rem);
			left: 3.1rem;
			width: calc(100% - 3.1rem - 3.1rem);
			padding: 0;
		}
	}

	.btn.connect{

		@media (max-width: $md){
			font-size: 1.8rem !important;
			padding: 1.7rem 1.2rem;
			width: 100%;

			i{
				width: 1.5789rem;
				margin-left: 1.5rem !important;
			}
		}
	
		&.open{

			i{
				transform: translateY(0rem) rotateX(180deg);
			}
		}

		i{
			transform: rotateX(0deg);
		}

		&:hover,
		&:focus-visible{

			i{
				transform: translateY(.2rem) rotateX(0deg);
			}
		}
	}

	.filter-bottom{
		background-color: $blue-dark;
		margin-bottom: -6.9rem;
		opacity: 0;
		visibility: hidden;
		position: absolute;
		top: 0rem;
		right: 1.6rem;
		width: calc(100% - 1.6rem);
		z-index: 2;
		transition: $time;

		@media (max-width: $md){
			width: 100%;
			right: 0;
		}

		&:has(.facetwp-facet-broker_insurance_type [value="personal-insurance"]:checked){
			
			.filter-item.select.specialty{
				display: none;
			}

			.filter-item.select.coverage{
				display: block;
			}

			&:has(.coverage [value="vehicle-insurance"]:checked){

				button.btn{
					display: none;
				}

				a.btn.vehicle-insurance{
					display: inline-flex;
				}
			}

			&:has(.coverage [value="individual-insurance"]:checked){

				button.btn{
					display: none;
				}

				a.btn.individual-insurance{
					display: inline-flex;
				}
            }

			&:has(.coverage [value="personal-life"]:checked){

				button.btn{
					display: none;
				}

				a.btn.personal-life{
					display: inline-flex;
				}
            }

			&:has(.coverage [value="high-net-worth"]:checked){

				button.btn{
					display: none;
				}

				a.btn.high-net-worth{
					display: inline-flex;
				}
            }

			&:has(.coverage [value="property-insurance"]:checked){

				button.btn{
					display: none;
				}

				a.btn.property-insurance{
					display: inline-flex;
				}
            }

			&:has(.coverage [value="medicare-insurance"]:checked){

				button.btn{
					display: none;
				}

				a.btn.medicare-insurance{
					display: inline-flex;
				}
			}
		}

        &:has(.facetwp-facet-broker_insurance_type [value="employee-benefits"]:checked){
			.filter-item.select.specialty{
				display: none;
			}
        }

        &:has(.facetwp-facet-broker_insurance_type [value="business-insurance"]:checked){
			
			.filter-item.select.specialty{
				display: none;
			}

			.filter-item.select.industry,
			.filter-item.select.employees {
				display: block;
			}

            &:has(.employees [value="employees-small"]:checked) {
                &:has(.industry [value="environmental-waste-management"]:checked),
                &:has(.industry [value="staffing-temporary-help-firms"]:checked),
                &:has(.industry [value="entertainment-production"]:checked),
                &:has(.industry [value="thoroughbred-horse-racing"]:checked),
                &:has(.industry [value="educational-institutions"]:checked),
                &:has(.industry [value="higher-education"]:checked),
                &:has(.industry [value="government-defense"]:checked),
                &:has(.industry [value="public-entities"]:checked),
                &:has(.industry [value="healthcare"]:checked),
                &:has(.industry [value="financial-institutions"]:checked),
                &:has(.industry [value="architects-engineers"]:checked),
                &:has(.industry [value="law-firms"]:checked),
                &:has(.industry [value="technology"]:checked) {

                    button.btn{
                        display: none;
                    }

                    a.btn.small-business{
                        display: inline-flex;
                    }

                }
			}

            &:has(.employees [value="employees-med"]:checked) {
                &:has(.industry [value="entertainment-production"]:checked),
                &:has(.industry [value="thoroughbred-horse-racing"]:checked),
                &:has(.industry [value="educational-institutions"]:checked),
                &:has(.industry [value="higher-education"]:checked),
                &:has(.industry [value="government-defense"]:checked),
                &:has(.industry [value="public-entities"]:checked),
                &:has(.industry [value="healthcare"]:checked),
                &:has(.industry [value="financial-institutions"]:checked),
                &:has(.industry [value="architects-engineers"]:checked),
                &:has(.industry [value="law-firms"]:checked),
                &:has(.industry [value="technology"]:checked) {

                    button.btn{
                        display: none;
                    }

                    a.btn.small-business{
                        display: inline-flex;
                    }

                }
			}

            &:has(.employees [value="employees-large"]:checked){
                &:has(.industry [value="financial-institutions"]:checked),
                &:has(.industry [value="architects-engineers"]:checked),
                &:has(.industry [value="law-firms"]:checked),
                &:has(.industry [value="technology"]:checked) {

                    button.btn{
                        display: none;
                    }

                    a.btn.small-business{
                        display: inline-flex;
                    }

                }
			}
        }

		&.open{
			opacity: 1;
			visibility: visible;
		}


		a.btn{
			display: none;
		}

		.coverage,
        .industry,
        .employees {
			display: none;
			
			select{
				margin-bottom: 1rem;
			}
		}
		
		.top{
			padding: 5.18rem 5.31rem 3.52rem;
			position: relative;

			@media (max-width: $md){
				padding: 3.78rem 2.11rem 3.02rem;

				h2{
					padding-right: 1rem;
				}
			}
	
			i.arrow{
				position: absolute;
				top: 6.3rem;
				transform: rotateX(0deg) translateY(-50%);
				transform-origin: 50% 0%;
				right: 5.4rem;
				aspect-ratio: 20.789/11.455;
				width: 2.0789rem;
				backface-visibility: visible!important;
				transition: $time;
				cursor: pointer;

				@media (max-width: $md){
					top: 3.6rem;
					right: 2rem;
					width: 1.5789rem;
				}

				&:hover{
					
					svg{

						*{
							fill: $blue-light;
						}
					}
				}
	
				svg{
					width: 100%;

					*{
						fill: $white;
						transition: $time;
					}

					&:nth-of-type(2){
						aspect-ratio: 22.857/23.635;
						width: 2.2857rem;
						display: none;
					}
				}
			}

			h5{
				color: $blue-light;
				font-weight: 400;
				margin-bottom: .48em;

				@media (max-width: $md){
					margin-bottom: .18em;
				}
			}

			h2{
				color: $white;
				margin-bottom: 0;
			}
		}

		.bottom{
			display: flex;
			flex-wrap: wrap;
			align-items: flex-end;
			padding: 0 6.11rem 3.92rem;
			transition: $time;
			position: absolute;
			top: calc(100% - 1rem);
			left: 0;
			width: 100%;
			background-color: $blue-dark;

			@media (max-width: $md){
				padding: 0 2.61rem 2.92rem;
			}

			.btn{
				margin-bottom: 1rem;
				border-radius: 0;
				padding: .834rem 2.45rem;
				margin-left: 1rem;

				@media (max-width: $md){
					margin-left: 0;
					width: 100%;
				}
			}

			[data-type="search"]{

				.facetwp-icon{
					aspect-ratio: 15.743/14.443;
					width: 1.5743rem;
					height: auto;
					top: 50%;
					transform: translateY(-50%);
					right: 1.6rem;
					opacity: 1;
					transition: $time;
					@include font-size(0);
					
					&:hover,
					&:focus-visible{
						opacity: .4;
					}
		
					&:before{
						background-image: url(../images/location-search.svg);
						background-repeat: no-repeat;
						aspect-ratio: 15.743/14.443;
						background-size: 100% auto;
						width: 1.5743rem;
						height: auto;
						background-position: 50% 50%;
					}
				}

				input{
					padding-right: 4rem;
				}
			}

			.filter-item{
				flex: 1 1;

				@media (max-width: $md){
					flex: 1 1 100%;
					margin-bottom: .8rem;

					label{
						@include font-size(14);
					}
				}

				&:has(+ .filter-item){
					margin-right: 1rem;

					@media (max-width: $md){
						margin-right: 0;
					}
				}

				&.search{
					min-width: 65.803rem;
				}
			}

			.facetwp-icon.locate-me{
				aspect-ratio: 15.743/14.443;
				width: 1.5743rem;
				height: auto;
				top: 50%;
				transform: translateY(-50%);
				right: 1.6rem;
				opacity: 1;
				transition: $time;
				@include font-size(0);

				+ input{
					padding-right: 4rem;
				}
				
				&:hover,
				&:focus-visible{
					opacity: .4;
				}
	
				&:before{
					background-image: url(../images/location-search.svg);
					background-repeat: no-repeat;
					aspect-ratio: 15.743/14.443;
					background-size: 100% auto;
					width: 1.5743rem;
					height: auto;
					background-position: 50% 50%;
				}
			}

			label{
				color: $blue-light;
				width: auto;
				font-weight: 400;
				@include font-size(16);
				margin-bottom: .9rem;
			}

			.facetwp-facet{
				flex: 1 1;

				&:has(+ .facetwp-facet){
					margin-right: 3.8rem;
				}
			}

			::-webkit-input-placeholder {
				@include font-size(18);
				color: $white;
			}
			 
			:-moz-placeholder { /* Firefox 18- */
				@include font-size(18);
				color: $white;
			}
			
			::-moz-placeholder {  /* Firefox 19+ */
				@include font-size(18);
				color: $white;
			}
			
			:-ms-input-placeholder {
				@include font-size(18);
				color: $white;
			}

			input{
				background-color: #094172;
				border: .1rem solid #094172;
				color: white;
				padding: .617rem 1.2rem;
				@include font-size(18);
				border-radius: .3rem;
			}

			.facetwp-input-wrap{
				width: 100%;
			}

			select{
				background-color: #094172;
				border: .1rem solid #094172;
				@include font-size(18);
				color: white;
				border-radius: .3rem;
				line-height: 3.9rem - .1rem - .1rem;
				height: 3.9rem;
				padding-left: 1rem;
				padding-right: 3.8rem;
				background-position: right 1.75rem top 50%;

				option{
					color: $colour;
					background-color: $white;
				}
			}

			.facetwp-facet{
				margin-bottom: 1rem;
			}
		}
	}
}

.hidden-brokers{
	display: none;
}

.find-hero-with-filter-section{
	position: relative;
	overflow-x: clip!important;
	overflow-y: visible!important;
	background-color: $blue-light!important;
	z-index: 5;

	.row{
		min-height: 40rem;
		padding-top: 13.8rem;
		padding-bottom: 4rem;

		@media (max-width: $md){
			padding-top: 5.8rem;
		}
	}

	.title-side{
		padding-top: .4rem;

		@media (max-width: $md){
			
			h1{
				@include font-size(40);
				@include line-height(40,45);
				margin-bottom: 2.2rem;
			}
		}
	}

	.text{

		*{
			color: $white;
		}
	}

	.filter-bottom{
		background-color: $blue-dark;
		margin-bottom: -6.9rem;
		margin-top: 7.9rem;

		@media (max-width: $md){
			margin-top: 5.3rem;
			margin-bottom: 0;
		}

		&:has(.top.open){
			//margin-bottom: -17.2rem;
		}

		.top{
			padding: 4.78rem 7.91rem 3.92rem;
			position: relative;

			@media (max-width: $md){
				padding: 1.28rem 2.51rem 0.02rem;

				&.open{
					padding: 3.68rem 2.51rem 0.52rem;
				}

				&:not(.open){

					h5{
						@include font-size(16);
						@include line-height(16,35);
						font-weight: 600;
						color: $white;
					}
				}

				h2{
					display: none;
				}
			}

			&.open{

				+ .bottom{
					//margin-top: -1rem;
					padding-bottom: 3.92rem;
					max-height: 15rem;

					@media (max-width: $md){
						padding-top: 2.8rem;
						max-height: 35rem;
						padding-bottom: 1.42rem;
					}
				}

				@media (max-width: $md){
					
					h2{
						display: block;
					}
				}
	
				i.arrow{
					
					svg{

						&:nth-of-type(1){
							display: none;
						}

						&:nth-of-type(2){
							display: block;
						}
					}
				}
			}
	
			i.arrow{
				position: absolute;
				top: 50%;
				transform: rotateX(0deg) translateY(-50%);
				transform-origin: 50% 0%;
				right: 5.4rem;
				aspect-ratio: 20.789/11.455;
				width: 2.0789rem;
				backface-visibility: visible!important;
				transition: $time;
				cursor: pointer;

				@media (max-width: $md){
					top: 3rem;
					right: 2rem;
					width: 1.5789rem;
				}

				&:hover{
					
					svg{

						*{
							fill: $blue-light;
						}
					}
				}
	
				svg{
					width: 100%;

					*{
						fill: $white;
						transition: $time;
					}

					&:nth-of-type(2){
						aspect-ratio: 22.857/23.635;
						width: 2.2857rem;
						display: none;
					}
				}
			}

			h5{
				color: $blue-light;
				font-weight: 400;
				margin-bottom: .58em;
			}

			h2{
				color: $white;
				margin-bottom: 0;
			}
		}

		.bottom{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			padding: 0 8.71rem 0;
			max-height: 0;
			overflow: hidden;
			transition: $time;
			position: absolute;
			top: calc(100% - 1rem);
			left: 0;
			width: 100%;
			background-color: $blue-dark;

			@media (max-width: $md){
				padding: 0 3.01rem 0;
			}

			[data-type="search"]{

				.facetwp-icon{
					aspect-ratio: 15.743/14.443;
					width: 1.5743rem;
					height: auto;
					top: 50%;
					transform: translateY(-50%);
					right: 1.6rem;
					opacity: 1;
					transition: $time;
					@include font-size(0);
					
					&:hover,
					&:focus-visible{
						opacity: .4;
					}
		
					&:before{
						background-image: url(../images/location-search.svg);
						background-repeat: no-repeat;
						aspect-ratio: 15.743/14.443;
						background-size: 100% auto;
						width: 1.5743rem;
						height: auto;
						background-position: 50% 50%;
					}
				}

				input{
					padding-right: 4rem;
				}
			}

			.filter-item{
				flex: 1 1;

				@media (max-width: $md){
					flex: 1 1 100%;
					margin-bottom: 1.7rem;
				}

				&:has(+ .filter-item){
					margin-right: 1.55rem;

					@media (max-width: $md){
						margin-right: 0;
					}
				}

				&.search{
					min-width: 65.803rem;

					@media (max-width: $md){
						min-width: 0;
						width: 100%;
					}
				}
			}
			
			label{
				color: $blue-light;
				width: auto;
				font-weight: 400;
				@include font-size(16);
				margin-bottom: .9rem;

				@media (max-width: $md){
					@include font-size(14);
				}
			}

			.facetwp-facet{
				flex: 1 1;

				&:has(+ .facetwp-facet){
					margin-right: 3.8rem;
				}
			}

			::-webkit-input-placeholder {
				@include font-size(18);
				color: $white;
			}
			 
			:-moz-placeholder { /* Firefox 18- */
				@include font-size(18);
				color: $white;
			}
			
			::-moz-placeholder {  /* Firefox 19+ */
				@include font-size(18);
				color: $white;
			}
			
			:-ms-input-placeholder {
				@include font-size(18);
				color: $white;
			}

			input{
				background-color: #094172;
				border: .1rem solid #094172;
				color: white;
				padding: .617rem 1.2rem;
				@include font-size(18);
				border-radius: .3rem;
			}

			.facetwp-input-wrap{
				width: 100%;
			}

			select{
				background-color: #094172;
				border: .1rem solid #094172;
				@include font-size(18);
				color: white;
				border-radius: .3rem;
				line-height: 3.9rem - .1rem - .1rem;
				height: 3.9rem;
				padding-left: 1rem;
				padding-right: 3.8rem;
				background-position: right 1.75rem top 50%;

				option{
					color: $colour;
					background-color: $white;
				}
			}

			.facetwp-facet{
				margin-bottom: 1rem;
			}
		}
	}

	p{

		&.large{
			@include line-height(20,24);
		}
	}
}

.hero-section{
	position: relative;

	&::before{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 64%;
		height: 100%;
		z-index: 2;
		background: linear-gradient(to right, rgba(36, 58, 88, 0.63) 0%, rgba(14, 43, 82, 0.62) 13.79%, rgba(22, 37, 59, 0.54) 53.2%, rgba(30, 38, 49, 0) 100%);
		mix-blend-mode: multiply;
	}

	video{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		object-fit: cover;
		object-position: 50% 50%;
	}

	.container{
		max-width: 123rem;
	}

	.row{
		min-height: 45.8rem;
		padding-top: 3.9rem;
		padding-bottom: 4rem;
		align-items: center;
		
		@media (min-width: $md + 1){
			min-height: 76.918rem;
			transition: $time*3 $time*1;

			.text{
				transform: translateX(-200%);
				transition: $time*4 $time*2;
			}

			.loaded &{
				min-height: 45.8rem;

				.text{
					transform: translateX(0%);
				}
			}
		}

		@media (max-width: $md){
			min-height: 20rem;
			align-items: flex-start;
			padding-top: 5rem;
			padding-bottom: 3rem;
		}
	}

	h1{
		@include font-size(100);
		@include line-height(100,110);

		@media (max-width: $md){
			@include font-size(40);
			@include line-height(40,43);
			padding-right: 2rem;
		}
	}

	.text{
		width: 76rem;

		*{
			color: $white;
		}
	}

	.btn-con{
		margin-top: -3.5rem;

		@media (max-width: $md){
			margin-top: -.5rem;
		}
	}
}

.hero-with-breadcrumb-section{

	&.blue{
		background-color: $blue-light!important;
	}

	&.red{
		background-color: $red!important;
	}

	.container{
		max-width: 122.8rem;
	}

	.row{
		min-height: 37.6rem;

		@media (max-width: $md){
			min-height: 0;
		}
	}

	*{
		color: $white;
	}

	.text-side{
		padding-top: 10.2rem;
		padding-bottom: 13.62rem;

		@media (max-width: $md){
			padding-top: 4.2rem;
			padding-bottom: 4.8rem;

			.btn-con{
				margin-top: 2.9rem;
			}
		}

		.single-business-insurance &{
			padding-top: 10.2rem;
			padding-bottom: 8.72rem;

			@media (max-width: $md){
				padding-top: 4.2rem;
				padding-bottom: 3.82rem;
			}

			.btn-con{
				margin-top: 4.6rem;

				@media (max-width: $md){
					margin-top: 4rem;
				}
			}
		}

		@media (min-width: $md + 1){
			flex: 0 0 66.3%;
			max-width: 66.3%;
			padding-right: 11rem;
		}

		@media (max-width: $md){
			order: 2;
		}

		*:last-child{
			margin-bottom: 0;
		}
	}

	h1{
		margin-bottom: .35em;

		@media (max-width: $md){
			@include font-size(40);
			@include line-height(40,45);
			margin-bottom: .55em;
		}
	}

	p{

		&.large{
			@include line-height(20,24);
		}
	}

	.image-side{
		padding: 0;

		@media (min-width: $md + 1){
			flex: 0 0 33.7%;
			max-width: 33.7%;
		}

		@media (max-width: $md){
			order: 1;
			margin-left: -2.7rem;
			flex: 0 0 100vw;
			max-width: 100vw;
		}

		.img-con{
			height: 100%;

			@media (min-width: $md + 1){
				width: calc(100% + 8.6rem);
				max-width: calc(100% + 8.6rem);
			}

			@media (min-width: 1400px + 1){
				width: calc(100% + ((100vw - 122.8rem)/2));
				max-width: calc(100% + ((100vw - 122.8rem)/2));
			}

			@media (max-width: $md){
				aspect-ratio: 375/200;
				object-fit: cover;
				object-position: 50% 50%;
			}
		}
	}
}

.hero-with-breadcrumb-2-section{

	&.blue{
		background-color: $blue-light!important;
	}

	&.red{
		background-color: $red!important;
	}

	.container{
		max-width: 122.8rem;
	}

	.row{
		min-height: 41.4rem;

		@media (max-width: $md){
			min-height: 0;
		}
	}

	*{
		color: $white;
	}

	.text-side{
		padding-top: 10.2rem;
		padding-bottom: 13.62rem;

		@media (min-width: $md + 1){
			flex: 0 0 66.3%;
			max-width: 66.3%;
			padding-right: 11rem;
		}

		@media (max-width: $md){
			padding-top: 4.2rem;
			padding-bottom: 4.8rem;
		}

		*:last-child{
			margin-bottom: 0;
		}
	}

	h1{
		margin-bottom: .35em;
	}

	p{

		&.large{
			@include line-height(20,24);
		}
	}

	.image-side{
		padding: 0;
		position: relative;

		@media (min-width: $md + 1){
			flex: 0 0 33.7%;
			max-width: 33.7%;
		}

		&:after{
			content: '';
			position: absolute;
			top: 13.3rem;
			left: 10.3%;
			width: 81.221%;
			background-image: url(../images/dots2.svg);
			background-repeat: no-repeat;
			aspect-ratio: 336/287;
			object-fit: cover;
			object-position: 50% 50%;
			background-size: 100% auto;
		}
	}
}

.hero-with-breadcrumb-3-section{
	background-color: $blue-light!important;
	position: relative;

	&:after{
		content: '';
		position: absolute;
		bottom: 0.5rem;
		left: -6.4%;	
		width: 49.953%;
		width: 69.9333rem;
		background-image: url(../images/dots3.svg);
		background-repeat: no-repeat;
		aspect-ratio: 700/182;
		background-size: 100% auto;
	}

	.container{
		max-width: 122.8rem;
	}

	.row{
		min-height: 37.6rem;
		padding-top: 10.2rem;
		padding-bottom: 11.42rem;

		@media (max-width: $md){
			min-height: 0rem;
			padding-top: 4.1rem;
			padding-bottom: 5.72rem;
		}
	}

	*{
		color: $white;
	}

	.title-side{

		@media (min-width: $md + 1){
			flex: 0 0 50%;
			max-width: 50%;
		}

		@media (max-width: $md){
			margin-bottom: 2.1rem;
		}
	}

	.text-side{
		padding-top: .2rem;
	
		@media (min-width: $md + 1){
			flex: 0 0 50%;
			max-width: 50%;
		}
	}

	h1{
		margin-bottom: .35em;

		@media (max-width: $md){
			@include font-size(40);
			@include line-height(40,45);
		}
	}

	p{

		&.large{
			@include line-height(20,24);
		}
	}
}

.hero-with-intro-section{
	background-color: $blue-light;
	position: relative;

	&:after{
		content: '';
		position: absolute;
		top: 4.7rem;
		left: 43.2%;
		width: 37.8rem;
		background-image: url(../images/dots5.svg);
		background-repeat: no-repeat;
		aspect-ratio: 378/277;
		object-fit: cover;
		object-position: 50% 50%;
		background-size: 100% auto;

		@media (max-width: $md){
			display: none;
		}
	}

	.container{
		max-width: 122.8rem;
	}

	.row{
		min-height: 44.8rem;
		padding-top: 12.6rem;
		padding-bottom: 7.42rem;

		@media (max-width: $md){
			min-height: 0;
			padding-top: 6.2rem;
			padding-bottom: 3.72rem;
		}
	}

	*{
		color: $white;
	}

	h1{
		margin-bottom: .48em;

		@media (max-width: $md){
			@include font-size(40);
			@include line-height(40,45);
			margin-bottom: .55em;
		}
	}

	.btn-con{
		margin-top: -.7rem;

		@media (max-width: $md){
			margin-top: 0;

			.btn{
				width: 100%;
			}
		}
	}
}

.intro-under-section{
	overflow-x: clip!important;
	overflow-y: visible!important;

	.container{
		max-width: 122.8rem;
	}

	.row{
		padding-top: 7.7rem;
		padding-bottom: 6.9rem;
		align-items: flex-start;

		@media (max-width: $md){
			padding-top: 4.5rem;
			padding-bottom: 3.8rem;
		}
	}

	.text-side{

		@media (min-width: $md + 1){
			padding-right: 7rem;
		}

		@media (max-width: $md){
			margin-bottom: 5rem;
		}
	}

	.image-side{

		@media (min-width: $md + 1){
			padding-left: 11.7rem;
			margin-top: -38.7rem;
		}

		img{
			width: 100%;
			aspect-ratio: 489/556;
			object-fit: cover;
			object-position: 50% 50%;
		}
	}
}

.text-blocks-and-side-links-section{

	.row{
		padding-top: 10.1rem;
		padding-bottom: 8.4rem;

		@media (max-width: $md){
			padding-top: 7.1rem;
			padding-bottom: 5.4rem;
		}
	}

	.col-md-4{

		@media (min-width: $md + 1){
			padding-top: .2rem;
			flex: 0 0 38.1%;
			max-width: 38.1%;

			p{
				width: 32.5rem;
			}
		}

		@media (max-width: $md){
			margin-bottom: 4.2rem;
		}
	}

	.col-md-5{

		@media (min-width: $md + 1){
			flex: 0 0 43.9%;
			max-width: 43.9%;
		}

		@media (max-width: $md){
			margin-bottom: 2.5rem;
		}
	}

	.container-fluid{

		.row{
			padding-top: 0;
			padding-bottom: 0;
		}
	}

	.col-6{
		margin-bottom: 1.8rem;

		@media (max-width: $md){
			margin-bottom: .4rem;
		}

		.inner{
			width: 100%;
		}

		a{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;

			&:hover,
			&:focus-visible{

				~ .img-con{
					border-radius: .3rem .3rem 0 0;

					img{
						transform: scale(1);
					}
				}
				
				~ .bottom{
					background-color: $red;

					h6{
						color: $white;
						left: 50%;
						width: 100%;
					}
				}
			}
		}

		.img-con{
			width: 100%;
			aspect-ratio: 250/150;
			overflow: hidden;
			border-radius: .3rem;

			@media (max-width: $md){
				aspect-ratio: 152/150;
			}
		}

		img{
			aspect-ratio: 250/150;
			object-fit: cover;
			object-position: 50% 50%;
			width: 100%;
			transform: scale(1.05);
			transition: $time;

			@media (max-width: $md){
				aspect-ratio: 152/150;
			}
		}

		.bottom{
			position: relative;
			padding: .6rem 0;
			background-color: transparent;
			transition: $time;
			border-radius: 0 0 .3rem .3rem;
		}

		h6{
			transition: $time;
			width: 0%;
			text-align: center;
			white-space: nowrap;
		}
	}

	.col-md-3{

		@media (min-width: $md + 1){
			align-self: flex-end;
			flex: 0 0 18%;
			max-width: 18%;
			padding-left: 4rem;
			padding-right: 1.4rem;
			margin-bottom: 2.65rem;
		}

		@media (max-width: $md){
			justify-content: space-between;
			display: flex;
			flex-wrap: wrap;
		}

		h4{
			color: $red;
			margin-bottom: 2.8rem;

			@media (max-width: $md){
				margin-bottom: 1.3rem;
				width: 100%;
			}
		}

		.link{
			display: flex;
			align-items: center;
			text-decoration: none;
			@include font-size(18);
			padding: 1.14rem 0;
			border-bottom: 1px solid $blue-dark;

			@media (max-width: $md){
				width: calc(50% - 1.5rem);
				justify-content: space-between;
			}

			span{
				width: calc(100% - 4.687rem);
			}

			&:hover,
			&:focus-visible{
				//font-weight: 600;
				
				i{
					max-width: 2.687rem;
				}
			}

			i{
				max-width: 0;
				overflow: hidden;
				display: flex;
				justify-content: flex-end;
				margin-left: 1.4rem;

				@media (max-width: $md){
					margin-left: .5rem;
					margin-right: 0rem;
					max-width: 2.687rem;
				}
	
				svg{
					aspect-ratio: 26.87/10.55;
					width: 2.687rem;
					max-width: 2.687rem;
					min-width: 2.687rem;
					height: 1.055rem;
				}
			}
		}
	}
}

.stats-section{
	background-color: $blue-light!important;

	*{
		color: $white;
	}

	.row{
		padding-top: 10.1rem;
		padding-bottom: 10.3rem;

		@media (max-width: $md){
			padding-top: 5.1rem;
			padding-bottom: 8.9rem;
		}
	}

	.text-con{
		text-align: center;
		margin-bottom: 7.7rem;

		@media (max-width: $md){
			margin-bottom: 2rem;
		}
	}

	.stats-slide{
		width: calc(100% + .1rem + .1rem);
		max-width: calc(100% + .1rem + .1rem);
		margin-left: -.1rem;

		.swiper-slide{
			padding: 0 .1rem;
		}

		.swiper-button-next,
		.swiper-button-prev{

			@media (max-width: $md){
				bottom: -4.3rem;
			}

			svg{

				*{
					fill: $white;
				}
			}
		}

		.swiper-button-next{

			@media (max-width: $md){
				left: auto;
				right: 0;
			}
		}

		.swiper-scrollbar{
			background-color: rgba($white,.4);

			@media (max-width: $md){
				bottom: -3.8rem;
				width: 50%;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				width: calc(100% - 21.3rem);
			}

			.swiper-scrollbar-drag{
				background-color: $white;
			}
		}
	}

	.stats-slide-con.animate{

		@media (min-width: $md + 1){
			
		}

		.swiper-slide{

			.number{
	
				.number-inner{
					transform: translateY(0%);
				}
			}
			
	

			@media (min-width: $md + 1){
				
				&.swiper-slide-active + .swiper-slide,
				&.swiper-slide-active + .swiper-slide + .swiper-slide,
				&.swiper-slide-active + .swiper-slide + .swiper-slide + .swiper-slide,
				&.swiper-slide-active{
		
					.number{
		
						.number-inner{
							transform: translateY(calc(-100% + 1.0625em));
							transition: $time*5;
						}
					}
				}
			}

			@media (max-width: $md){
				
				&.swiper-slide-active{
		
					.number{
		
						.number-inner{
							transform: translateY(calc(-100% + 1.0625em));
							transition: $time*5;
						}
					}
				}
			}
			
		}
	}

	.stat{
		@include font-size(80);
		@include line-height(80,85);
		font-weight: 700;
		font-family: $alt-font;
		display: flex;
		align-items: flex-end;
		width: 100%;
		margin-bottom: .1rem;

		@media (max-width: $md){
			@include font-size(60);
			@include line-height(60,60);
			justify-content: center;
		}

		.number{
			height: 1.0625em;
			overflow: hidden;
			position: relative;

			@media (max-width: $md){
				height: 1em;
				line-height: 1.1;
			}

			.number-inner{
				position: absolute;
				top: 0;
				left: 0;
				transform: translateY(0%);
			}

			.filler{
				opacity: 0;
				visibility: hidden;
			}
		}
	}

	.text{
		margin-bottom: .1rem;

		@media (max-width: $md){
			text-align: center;
		}
	}
}

.banner-section{
	position: relative;
	overflow-x: clip!important;
	overflow-y: visible!important;

	&.parallax-window{
		min-height: 57rem;
		background: transparent;

		@media (max-width: $md){
			min-height: 63.7rem;
		}
	}

	&::before{
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 60%;
		height: 100%;
		z-index: 2;
		background: linear-gradient(to left, #002a57 0%, #002a57 70.44%, rgba(255, 255, 255, 0) 100%);
		opacity: .45;
		mix-blend-mode: multiply;
	}

	.container{
		max-width: 109.2rem;
	}

	.row{
		min-height: 57rem;
		padding-top: 1.3rem;
		padding-bottom: 1rem;
		align-items: center;

		@media (max-width: $md){
			min-height: 63.7rem;
			padding-top: 1.3rem;
			padding-bottom: 4.9rem;
			align-items: flex-end;
		}
	}

	.col-12{
		display: flex;
		justify-content: flex-end;
	}

	.text{
		width: 42.1rem;

		*{
			color: $white;
		}
	}
}

.banner-2-section{

	.container{
		max-width: 100%;

		@media (max-width: $md){
			padding: 0 .4rem;
		}
	}

	.image-side{
		padding: 0;

		@media (min-width: $md + 1){
			flex: 0 0 33.85%;
			max-width: 33.85%;
		}

		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: 50% 50%;

			@media (max-width: $md){
				aspect-ratio: 375/263;
				object-fit: cover;
				object-position: 50% 50%;
			}
		}

		.img-con{
			width: 100%;

			&.parallax-window{
				min-height: 58rem;
				background: transparent;

				@media (max-width: $md){
					min-height: 26.3rem;
				}
			}
		}
	}

	.text-side{
		background-color: $blue-light;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;

		@media (max-width: $md){
			padding: 5.3rem 3.1rem 13rem;

			br{
				display: none;
			}
		}

		&:after{
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 100%;
			width: 100vw;
			max-width: 100vw;
			background-color: $red;

			@media (max-width: $md){
				left: 0;
				height: 9.1rem;
				top: auto;
			}
		}

		@media (min-width: $md + 1){
			flex: 0 0 50.1%;
			max-width: 50.1%;
			padding: 2rem 15.8rem 4.1rem 12.1rem;
		}

		*{
			color: $white;
		}
	}
}

.banner-3-section{

	@media (max-width: $md){
		position: relative;
		border-top: 3.9rem solid $blue-dark;

		&:before{
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			right: calc(100% - 3.1rem);
			width: 3.1rem;
			background-color: $blue-dark;
		}

		&:after{
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: calc(100% - 3.1rem);
			width: 3.1rem;
			background-color: $blue-dark;
		}
	}

	.container{
		max-width: 100%;
	}

	*{
		color: $white;
	}

	.image-side{
		padding: 0;
		position: relative;

		&:after{
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			right: 100%;
			width: 100vw;
			max-width: 100vw;
			background-color: $blue-light;

			@media (max-width: $md){
				display: none;
			}
		}

		@media (min-width: $md + 1){
			flex: 0 0 38.9%;
			max-width: 38.9%;
			margin-left: 2.8%;
		}

		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: 50% 50%;
		}

		.img-con{
			width: 100%;

			&.parallax-window{
				min-height: 45.9rem;
				background: transparent;

				@media (max-width: $md){
					min-height: 20rem;
					aspect-ratio: 312/195;
					object-fit: cover;
					object-position: 50% 50%;
				}
			}
		}
	}

	.text-side{
		background-color: $blue-dark;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;

		@media (min-width: $md + 1){
			flex: 0 0 58.3%;
			max-width: 58.3%;
			padding: 4.7rem 19rem 4.1rem 21.7rem;
		}

		@media (max-width: $md){
			padding-top: 4.1rem;
			padding-bottom: 3.9rem;
		}

		*{
			color: $white;
		}
	}

	.btn-con{
		margin-top: 5.2rem;

		@media (max-width: $md){
			margin-top: 3.5rem;
		}
	}
}

.banner-4-section{
	position: relative;
	overflow-x: clip!important;
	overflow-y: visible!important;

	&.parallax-window{
		min-height: 50.5rem;
		background: transparent;

		@media (max-width: $md){
			min-height: 52rem;
		}
	}

	&::before{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
		background: linear-gradient(to right, rgba(0, 0, 0, 0.76) 0%, rgba(48, 48, 48, 0.81) 30.05%, rgba(100, 100, 100, 0.61) 62.07%, rgba(255, 255, 255, 0) 100%);
		opacity: .35;
		mix-blend-mode: multiply;
	}

	.container{
		max-width: 122.7rem;
	}

	.row{
		min-height: 50.5rem;
		padding-top: 1rem;
		padding-bottom: 5.2rem;
		align-items: center;

		@media (max-width: $md){
			min-height: 52rem;
			align-items: flex-end;
		}
	}

	.btn-con{
		margin-top: 5.3rem;

		@media (max-width: $md){
			margin-top: 3.8rem;
		}

		.post-type-archive-industries &{

			.btn{
				background-color: $blue-light;
				border-color: $blue-light;
				color: $white!important;

				&:hover,
				&:focus-visible{
					background-color: darken($blue-light, 4.5%);
					border-color: darken($blue-light, 4.5%);
					color: $white!important;
				}
			}
		}
	}

	.col-12{
		display: flex;
	}

	.text{
		width: 42.1rem;

		@media (max-width: $md){
			padding-right: 1rem;
		}

		*{
			color: $white;
		}
	}
}

.banner-5-section{
	position: relative;
	overflow-x: clip!important;
	overflow-y: visible!important;

	&.parallax-window{
		min-height: 20rem;
		background: transparent;

		@media (max-width: $md){
			min-height: 65.7rem;

			.page-id-620 &{
				min-height: 55.6rem;
			}

			.page-id-542 &{
				min-height: 51.1rem;
			}
		}
	}

	&.blue{

		.text{
			background-color: $blue-light;
		}

		.btn{
			background-color: $blue-dark;
			border-color: $blue-dark;

			&:hover,
			&:focus-visible{
				background-color: darken($blue-dark, 4.5%);
				border-color: darken($blue-dark, 4.5%);
			}
		}
	}

	&.red{

		.text{
			background-color: $red;
		}

		.btn{
			background-color: $white;
			border-color: $white;
			color: $red!important;

			svg{

				*{
					fill: $red!important;
				}
			}

			&:hover,
			&:focus-visible{
				background-color: darken($white, 4.5%);
				border-color: darken($white, 4.5%);
				color: $red!important;
			}
		}
	}

	.container{
		max-width: 122.8rem;
	}

	.row{
		min-height: 20rem;
		padding-top: 9.5rem;
		padding-bottom: 9.5rem;
		align-items: center;

		@media (max-width: $md){
			min-height: 65.7rem;
			padding-bottom: 4.5rem;
			align-items: flex-end;

			.page-id-620 &{
				min-height: 55.6rem;
			}

			.page-id-542 &{
				min-height: 51.1rem;
			}
		}
	}

	.col-12{
		display: flex;
		justify-content: flex-start;
	}

	.text{
		width: 59.8rem;
		padding: 7.4rem 5.9rem 7.2rem;

		@media (max-width: $md){
			padding: 3.6rem 2.6rem 4rem;

			h2{
				margin-bottom: 1.2em;
			}
		}

		*{
			color: $white;
		}
	}

	.btn-con{
		margin-top: 5.6rem;

		@media (max-width: $md){
			margin-top: 4.6rem;

			.btn{
				width: 100%;
			}
		}
	}
}

.latest-newsroom-section.latest-newsroom-section,
.riskscore-form-section + .latest-insights-section,
.employee-benefits-section + .latest-insights-section,
.our-personal-insurance-products-section + .latest-insights-section,
.connect-with-section + .latest-insights-section,
.personal-insurance-section + .latest-insights-section,
.business-insurance-section + .latest-insights-section,
.banner-3-section + .latest-insights-section,
.ioa-gives-section + .latest-insights-section,
.banner-4-section + .latest-insights-section{
	background-color: $blue-lighter!important;

	.latest-slide-con:before{
		background-color: $blue-lighter;
	}
}

.latest-newsroom-section,
.latest-insights-section{

	.row{
		padding-top: 10.2rem;
		padding-bottom: 16.4rem;

		@media (max-width: $md){
			padding-top: 4.5rem;
			padding-bottom: 9.1rem;
		}
	}

	.text-side{
		position: relative;
		z-index: 3;

		@media (min-width: $md + 1){
			flex: 0 0 39.7%;
			max-width: 39.7%;
		}

		@media (max-width: $md){
			margin-bottom: 4rem;
		}

		p{
			width: 38rem;
		}
	}

	.latest-slide-con{

		@media (min-width: $md + 1){
			flex: 0 0 60%;
			max-width: 60%;

			&:before{
				content: '';
				position: absolute;
				top: 0;
				right: 100%;
				height: 100%;
				background-color: $white;
				width: 100vw;
				max-width: 100vw;
				z-index: 2;
			}
		}

		.swiper-wrapper-con{
			overflow: visible!important;
		}

		.latest-slide{
			width: calc(100% + 1.25rem + 1.25rem);
			max-width: calc(100% + 1.25rem + 1.25rem);
			margin-left: -1.25rem;

			@media (max-width: $md){
				width: calc(100% + .5rem + .5rem);
				max-width: calc(100% + .5rem + .5rem);
				margin-left: -.5rem;
			}
		}

		.swiper-slide{
			padding: 0 1.25rem;

			@media (max-width: $md){
				padding: 0 .5rem;
				margin-bottom: 0;
			}

			h4{
				@include font-size(25);
				@include line-height(25,30);

				@media (max-width: $md){
					@include font-size(20);
					@include line-height(20,25);
				}
			}

			.text{

				.date{
	
					span{
	
						&:has(+ span){
	
							&:after{
								content: '|';
								margin: 0 .3em;
							}
						}
					}
				}
			}
		}
	}

	.btn-con{

		@media (max-width: $md){
			margin-top: 4.4rem;
		}
	}
}

.get-in-touch-section{

	.post-type-archive-location &,
	.single-brokers &,
	.single-location &{
		display: none;
	}

	&.blue-light{
		background-color: $blue-light!important;

		.text-side{

			*{
				color: $white;
			}

			h3{

				a{
					color: $blue-dark;
				}
			}
		}

		.gform_confirmation_message.gform_confirmation_message.gform_confirmation_message.gform_confirmation_message.gform_confirmation_message{
			color: $white;
		}

		.form-side{

			.gfield{

				::-webkit-input-placeholder {
					color: $white;
				}
				 
				:-moz-placeholder { /* Firefox 18- */
					color: $white;
				}
				
				::-moz-placeholder {  /* Firefox 19+ */
					color: $white;
				}
				
				:-ms-input-placeholder {
					color: $white;
				}
	
				.gfield_label{
					border-bottom-color: rgba($white,.4);
				}

				input, 
				input.input-text, 
				textarea, 
				.fake-select, 
				select{
					border-bottom-color: rgba($white,.4);
					color: $white;
				}

				select{
					color: $blue-dark;
					background-image: url(../images/select-icon-white.svg);

					.gf_placeholder{
						color: $blue-dark;
					}
				}
			}

			.gform_footer{
			
				[type="submit"]{
					border-color: $blue-dark;
					background-color: $blue-dark;
	
					&:hover,
					&:focus-visible{
						border-color: $blue;
						background-color: $blue;
					}
				}
			}
		}
	}

	.container{
		max-width: 125.6rem;
	}

	.row{
		padding-top: 10.1rem;
		padding-bottom: 10.1rem;

		@media (max-width: $md){
			padding-top: 5.2rem;
			padding-bottom: 4.6rem;
		}
	}

	.text-side{

		@media (min-width: $md + 1){
			padding-left: 2.2rem;
			padding-right: 11rem;
		}

		@media (max-width: $md){
			margin-bottom: 3.5rem;
			
			p{
				padding-right: 4rem;
			}
		}
	}

	h1{
		font-weight: 500;
	}

	* + h3{
		margin-top: 1.58em;

		@media (max-width: $md){
			margin-top: 1.38em;
		}
	}

	.form-side{

		.gfield{

			.gfield_label{
				width: auto;
				padding: .8rem 0rem;
				@include font-size(16);
				line-height: 1.417;
				border: .1rem solid rgba($blue,.4);
				border-top: none;
				border-left: none;
				border-right: none;
				margin-bottom: 0;
				padding-right: .5rem;
				font-weight: 400;

				.gfield_required{
					display: none;
				}
			}

			.gfield_validation_message{
				width: 100%;
			}
	
			.ginput_container{
				width: auto;
				flex-grow: 1;
				
				@media (max-width: $md){
					flex: 1;

					&.ginput_container_textarea{
						flex: auto;
					}
				}
			}

			select{
				color: $blue-dark;

				.gf_placeholder{
					color: $blue-dark;
				}
			}
		}

		.gform_footer{
			margin-top: .5rem;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row-reverse;

			@media (max-width: $md){
				margin-top: 1.3rem;
			}

			[type="submit"]{
				border-color: $blue-light;
				background-color: $blue-light;
				padding-left: 2.4rem;
				padding-right: 2.4rem;

				@media (max-width: $md){
					padding-left: 1.8rem;
					padding-right: 1.8rem;
				}

				&:hover,
				&:focus-visible{
					border-color: $blue;
					background-color: $blue;
				}
			}
		}
	}
}

.text-with-images-section{

	&:has(+ .tabs-section.blue-light){

		.row{
			padding-bottom: 6.2rem;

			@media (max-width: $md){
				padding-bottom: 4rem;
			}
		}
	}

	.container{
		max-width: 122.8rem;
	}

	.row{
		padding-top: 10.1rem;
		padding-bottom: 10.1rem;

		@media (max-width: $md){
			padding-top: 4.1rem;
			padding-bottom: 10.1rem;
		}
	}

	.text-side{

		@media (min-width: $md + 1){
			flex: 0 0 48.7%;
			max-width: 48.7%;
			padding-top: .3rem;
			padding-right: 10.3rem;
		}

		@media (max-width: $md){
			margin-bottom: 2.7rem;
			
			.btn-con{
				display: none;
			}
		}
	}

	.btn-under{

		@media (min-width: $md + 1){
			display: none;
		}

		.btn-con{
			margin-top: 2.8rem;
		}
	}

	.image-side{
		display: flex;
		justify-content: space-between;
		align-self: flex-start;

		&:after{
			content: '';
			position: absolute;
			top: 7rem;
			left: 23.5%;
			width: 63.021%;
			background-image: url(../images/dots.svg);
			background-repeat: no-repeat;
			aspect-ratio: 397/219;
			object-fit: cover;
			object-position: 50% 50%;
			background-size: 100% auto;

			@media (max-width: $md){
				display: none;
			}
		}

		@media (min-width: $md + 1){
			flex: 0 0 51.3%;
			max-width: 51.3%;
		}

		img{
			object-fit: cover;
			object-position: 50% 50%;
			position: relative;
			z-index: 2;

			&:nth-of-type(1){
				aspect-ratio: 218/292;
				width: 35.5%;
				align-self: flex-start;

				@media (max-width: $md){
					aspect-ratio: 310/210;
					object-fit: cover;
					object-position: 50% 50%;
					width: 100%;	
				}
			}

			&:nth-of-type(2){
				aspect-ratio: 380/247;
				width: 61.9%;
				align-self: flex-start;
				margin-top: 14.2rem;

				@media (max-width: $md){
					display: none;
				}
			}
		}
	}

	.btn-con{
		margin-top: 5.9rem;
	}
}

.text-with-images-2-section{
	background-color: $blue-light!important;
	position: relative;

	&:before{
		content: '';
		position: absolute;
		bottom: 14.7rem;
		left: -.6%;
		width: 26.069%;
		background-image: url(../images/dots4.svg);
		background-repeat: no-repeat;
		aspect-ratio: 364.989/251.558;
		background-size: 100% auto;

		@media (max-width: $md){
			display: none;
		}
	}

	&:after{
		content: '';
		position: absolute;
		bottom: 23.7rem;
		right: -.5%;
		width: 26.069%;
		background-image: url(../images/dots4.svg);
		background-repeat: no-repeat;
		aspect-ratio: 364.989/251.558;
		background-size: 100% auto;

		@media (max-width: $md){
			display: none;
		}
	}

	.container{
		max-width: 122.6rem;
	}

	.row{
		padding-top: 10.1rem;
		padding-bottom: 10.8rem;

		@media (max-width: $md){
			padding-top: 4.3rem;
			padding-bottom: 4.6rem;
		}
	}

	*{
		color: $white;
	}

	.title-side{

		@media (min-width: $md + 1){
			margin-top: -1.7rem;
			flex: 0 0 58.9%;
			max-width: 58.9%;
		}

		@media (max-width: $md){
			margin-bottom: 2.3rem;
		}
	}

	.text-side{

		@media (min-width: $md + 1){
			flex: 0 0 41.1%;
			max-width: 41.1%;
		}
	}

	h1{
		@include font-size(100);

		@media (max-width: $md){
			@include font-size(35);
			@include line-height(35,43);
		}
	}

	.image-con{
		margin-top: 8.2rem;
		display: flex;
		justify-content: center;
		padding: 0 2.1rem;

		@media (max-width: $md){
			margin-top: 11.2rem;
		}

		.img-con{
			margin: 0 -1.7rem;
			position: relative;

			&:nth-of-type(1){
				z-index: 4;
			}

			&:nth-of-type(2){
				z-index: 3;
				margin-top: 8.8rem;

				@media (max-width: $md){
					margin-top: -5.2rem;
				}
			}

			&:nth-of-type(3){
				z-index: 2;

				@media (max-width: $md){
					display: none;
				}
			}

			&:nth-of-type(4){
				z-index: 1;
				margin-top: 8.8rem;

				@media (max-width: $md){
					display: none;
				}
			}

			img{
				aspect-ratio: 1/1;
				object-fit: cover;
				object-position: 50% 50%;
			}
		}
	}
}

.text-with-image-3-section + .text-with-images-3-section{

	.text-side{

		@media (min-width: $md + 1){
			padding-right: 7.7rem;
		}

		h1{
			@include font-size(100);

			@media (max-width: $md){
				@include font-size(35);
				@include line-height(35,43);
				margin-bottom: .62em;
			}

			@media (min-width: $md + 1){
				width: calc(100% + 9.9rem + 34.4rem);
				max-width: calc(100% + 9.9rem + 34.4rem);
				float: right;
				z-index: 2;
				position: relative;
				margin-right: -3.7rem;

				&:first-of-type{
					margin-top: 5.9rem;
					margin-bottom: .54em;
				}
			}
		}
	}
}

.text-with-images-3-section{
	background-color: $red!important;

	@media (max-width: $md){
		
		&:has(.mob-title-side){

			.row{
				padding-bottom: 0;
			}
		}
	}

	.mob-title-side{
		order: 1;
		margin-bottom: 2.2rem;

		@media (min-width: $md + 1){
			display: none;
		}

		@media (max-width: $md){
			
			~ .image-side{
				order: 2;
				margin-bottom: 3rem;
			}
	
			~ .text-side.text-side.text-side.text-side{
				order: 3;
				margin-bottom: 0;

				> .text > h1{
					display: none;
				}
			}
		}
	}

	.container{
		max-width: 122.8rem;
	}

	.row{
		padding-top: 10.1rem;
		padding-bottom: 12.5rem;

		@media (max-width: $md){
			padding-top: 4.1rem;
			padding-bottom: 4.5rem;
		}
	}

	*{
		color: $white;
	}

	.text-side{

		@media (min-width: $md + 1){
			flex: 0 0 48.2%;
			max-width: 48.2%;
			padding-top: .3rem;
			padding-left: 9.9rem;
			padding-right: 11.3rem;
		}

		@media (max-width: $md){
			order: 1;
			margin-bottom: 2.9rem;
		}

		h1{
			@include font-size(100);

			@media (max-width: $md){
				@include font-size(35);
				@include line-height(35,43);
				margin-bottom: .62em;
			}

			@media (min-width: $md + 1){
				width: calc(100% + 9.9rem + 34.3rem);
				max-width: calc(100% + 9.9rem + 34.3rem);
				float: right;
				z-index: 2;
				position: relative;

				&:first-of-type{
					margin-top: 8.6rem;
					margin-bottom: .74em;
				}

				+ *{
					clear: both;	
				}
			}
		}

		p{

			@media (max-width: $md){
				margin-bottom: 3.4em;
			}
		}
	}

	.image-side{
		display: flex;
		justify-content: space-between;
		align-self: flex-start;

		@media (max-width: $md){
			order: 2;
		}

		@media (min-width: $md + 1){
			flex: 0 0 51.8%;
			max-width: 51.8%;
		}

		img{
			object-fit: cover;
			object-position: 50% 50%;
			position: relative;
			z-index: 2;

			&:nth-of-type(1){
				aspect-ratio: 375/562;
				width: 60.5%;
				align-self: flex-start;
			}

			&:nth-of-type(2){
				aspect-ratio: 394/263;
				width: 63.6%;
				margin-left: -24%;
				align-self: flex-start;
				margin-top: 34.2rem;

				@media (max-width: $md){
					margin-top: 16.9rem;
				}
			}
		}
	}

	.btn-con{
		margin-top: 7.6rem;

		@media (max-width: $md){
			margin-top: -2.2rem;

			.btn{
				width: 100%;
			}
		}
	}
}

.text-with-images-4-section{
	background-color: $blue-dark!important;
	overflow-x: clip!important;
	overflow-y: visible!important;

	.container{
		max-width: 122.8rem;
	}

	.row{
		padding-top: 8.9rem;
		padding-bottom: 8.9rem;

		@media (max-width: $md){
			padding-top: 4.7rem;
			padding-bottom: 2.9rem;
		}
	}

	*{
		color: $white;
	}

	.text-side{

		@media (min-width: $md + 1){
			flex: 0 0 45.6%;
			max-width: 45.6%;
			padding-right: 12rem;
		}

		@media (max-width: $md){
			margin-bottom: 6.6rem;
		}
	}

	.image-side{
		display: flex;
		justify-content: space-between;
		align-self: flex-start;

		@media (min-width: $md + 1){
			flex: 0 0 54.4%;
			max-width: 54.4%;
		}

		img{
			object-fit: cover;
			object-position: 50% 50%;
			position: relative;
			aspect-ratio: 1;

			&:nth-of-type(1){
				width: 47%;
				align-self: flex-start;
				margin-top: 6.3rem;
				z-index: 2;

				@media (max-width: $md){
					width: 69%;
					margin-top: .3rem;
				}
			}

			&:nth-of-type(2){
				width: 60.2%;
				margin-left: -24%;
				align-self: flex-start;
				margin-top: -14.9rem;

				@media (max-width: $md){
					width: 49.2%;
					margin-top: 13.5rem;
				}
			}
		}
	}

	.btn-con{
		margin-top: 7.6rem;
	}
}

.text-with-images-5-section{
	background-color: $red!important;
	overflow-x: clip!important;
	overflow-y: visible!important;

	.container{
		max-width: 122.8rem;
	}

	.row{
		padding-top: 7.9rem;
		padding-bottom: 11.5rem;

		@media (max-width: $md){
			padding-top: 4.7rem;
			padding-bottom: 5rem;
		}
	}

	*{
		color: $white;
	}

	.title-side{

		@media (min-width: $md + 1){
			flex: 0 0 39.8%;
			max-width: 39.8%;
		}

		@media (max-width: $md){
			margin-bottom: 2.3rem;
			order: 1;
		}
	}

	.top-side{
		margin-bottom: 18.5rem;

		@media (max-width: $md){
			margin-bottom: 9.5rem;
			order: 2;
		}

		@media (min-width: $md + 1){
			flex: 0 0 52%;
			max-width: 52%;
			padding-top: 1rem;
		}

		p.large{
			@include line-height(20,24);
		}
	}

	.text-side{

		@media (min-width: $md + 1){
			flex: 0 0 51.4%;
			max-width: 51.4%;
			padding-left: 12.6rem;

			&:nth-of-type(5){
				flex: 0 0 54.2%;
				max-width: 54.2%;
				padding-left: .8rem;
				padding-right: 18rem;
				margin-top: 10.4rem;
			}
		}

		@media (max-width: $md){

			&:nth-of-type(4){
				order: 4;
			}
			
			&:nth-of-type(5){
				margin-bottom: 3rem;
				order: 5;
			}
		}

		h2{
			margin-bottom: .73em;

			@media (max-width: $md){
				margin-bottom: 1em;
			}
		}
	}

	.image-side{
		display: flex;
		justify-content: space-between;
		align-self: flex-start;
		position: relative;

		@media (min-width: $md + 1){
			flex: 0 0 48.6%;
			max-width: 48.6%;

			&:nth-of-type(6){
				flex: 0 0 45.8%;
				max-width: 45.8%;
			}
			
		}

		.back{
			@include font-size(150);
			color: $white;
			opacity: .1;
			top: 50%;
			left: 50%;
			max-width: none;
			transform: translate(-50%, -50%);
			position: absolute;
			z-index: -1;
			font-weight: 600;
		}

		@media (max-width: $md){
			margin-bottom: 3.2rem;

			&:nth-of-type(3){
				order: 3;
			}

			&:nth-of-type(6){
				order: 4;
				margin-top: 8.8rem;
			}

			.back{
				display: none;
			}
		}

		&:nth-of-type(6){

			img{

				&:nth-of-type(1){
					width: 64%;
					align-self: flex-start;
					z-index: 2;
					margin-top: -2rem;

					@media (max-width: $md){
						width: 74%;
					}
				}
	
				&:nth-of-type(2){
					width: 47.3%;
					align-self: flex-start;
					margin-top: 18.1rem;
					z-index: 2;
					left: -5.4%;

					@media (max-width: $md){
						width: 49.3%;
						margin-top: 10.1rem;
						left: -1.1%;
					}
				}
			}
		}

		img{
			object-fit: cover;
			object-position: 50% 50%;
			position: relative;
			aspect-ratio: 1;

			&:nth-of-type(1){
				width: 50.5%;
				align-self: flex-start;
				z-index: 2;
				margin-top: 13.5rem;

				@media (max-width: $md){
					width: 46%;
					margin-top: 3.5rem;
				}
			}

			&:nth-of-type(2){
				width: 62.7%;
				margin-left: -24.1%;
				align-self: flex-start;
				margin-top: -5.7rem;
			}
		}
	}

	.btn-con{
		margin-top: 7.6rem;
	}
}

.text-with-images-6-section{
	background-color: $blue-light!important;
	overflow-x: clip!important;
	overflow-y: visible!important;

	.container{
		max-width: 122.8rem;
	}

	.row{
		padding-top: 8.9rem;
		padding-bottom: 13.3rem;

		@media (max-width: $md){
			padding-top: 4.5rem;
			padding-bottom: 4.3rem;
		}
	}

	*{
		color: $white;
	}

	.text-side{

		@media (min-width: $md + 1){
			flex: 0 0 49.4%;
			max-width: 49.4%;

			&:nth-of-type(1){
				padding-right: 8.2rem;

				p{
					padding-right: 3rem;
				}
			}

			&:nth-of-type(4){
				padding-left: 3.7rem;
				padding-right: 8rem;
				margin-top: 10.4rem;
			}
		}

		@media (max-width: $md){
			
			&:nth-of-type(1){
				order: 1;
			}

			&:nth-of-type(4){
				order: 3;
				margin-top: 3rem;
			}
		}


		h1{
			margin-bottom: .77em;

			@media (max-width: $md){
				margin-bottom: .67em;
			}
		}

		h2{
			margin-bottom: .73em;
		}

		h3{
			@include line-height(30,40);
			margin-bottom: 1.7em;

			@media (max-width: $md){
				@include font-size(20);
				@include line-height(20,25);
				margin-bottom: 1.2em;
			}
		}
	}

	.image-side{
		display: flex;
		justify-content: space-between;
		align-self: flex-start;

		@media (min-width: $md + 1){
			flex: 0 0 50.6%;
			max-width: 50.6%;
		}
		
		&:after{
			content: '';
			background-image: url(../images/dots9.svg);
			background-repeat: no-repeat;
			aspect-ratio: 258.46/375.003;
			width: 25.846rem;
			background-size: 100% auto;
			position: absolute;
			top: 43%;
			left: 39.5%;
			transform: translate(-50%, -50%) rotate(90deg);
			z-index: -1;

			@media (max-width: $md){
				display: none;
			}
		}

		&:nth-of-type(3){
			
			@media (min-width: $md + 1){
				margin-top: 10.7rem;
				padding-right: 9.5rem;
			}

			&:after{
				left: 61.5%;
			}

			img{

				&:nth-of-type(1){
					width: 60%;
					align-self: flex-start;
					z-index: 2;
					margin-top: 0rem;

					@media (max-width: $md){
						width: 74%;
					}
				}
	
				&:nth-of-type(2){
					width: 44.4%;
					align-self: flex-start;
					margin-top: 14rem;
					z-index: 2;
					left: -5.4%;

					@media (max-width: $md){
						width: 50.4%;
						margin-top: 12rem;
						left: -0.4%;
					}
				}
			}
		}

		@media (max-width: $md){

			&:nth-of-type(2){
				order: 2;
			}

			&:nth-of-type(3){
				order: 4;
				margin-top: 8rem;
			}
		}

		img{
			object-fit: cover;
			object-position: 50% 50%;
			position: relative;
			aspect-ratio: 1;

			&:nth-of-type(1){
				width: 48.5%;
				align-self: flex-start;
				z-index: 2;
				margin-top: 22.5rem;

				@media (max-width: $md){
					width: 46.5%;
					margin-top: 18.5rem;
				}
			}

			&:nth-of-type(2){
				width: 60.4%;
				margin-left: -24.1%;
				align-self: flex-start;
				margin-top: 4.2rem;

				@media (max-width: $md){
					width: 64.4%;
					margin-top: 9.2rem;
				}
			}
		}
	}

	.btn-con{
		margin-top: 7.6rem;
	}
}

.points-3-section + .tabs-section{

	.row{
		padding-top: 0;
		padding-bottom: 11.2rem;

		@media (max-width: $md){
			padding-bottom: 3.8rem;
		}
	}
}

.text-with-image-2-section + .tabs-section{
	
	.row{
		padding-top: 0rem;
		padding-bottom: 6.9rem;

		@media (max-width: $md){
			padding-bottom: 4.6rem;
		}
	}
}


.tabs-section{

	&.blue-dark{
		background-color: $blue-dark!important;

		*{
			color: $white;
		}

		.tab.tab{

			&.active{

				span{
					color: $blue-light;
				}
			}

			i{

				svg{

					*{
						fill: $blue-light;
					}
				}
			}
		}

		.tab-header{

			&:after{
				background-image: url(../images/select-icon-white.svg);
			}

			&.active{
				color: $blue-light;

				&:after{
					background-image: url(../images/select-icon.svg);
				}
			}
		}

		p{

			@media (max-width: $md){
				@include font-size(18);
				@include line-height(18,24);
			}
		}
	}

	&.white{

		@media (max-width: $md){
			
			.tab-content{

				.images + p{

					&.large{
						@include font-size(16);
						@include line-height(16,19);
					}
				}
			}
		}
	}

	&.blue{
		background-color: $blue-light!important;

		*{
			color: $white;
		}

		.tab.tab{
			border-bottom-color: rgba($white,.4);;

			&.active{

				span{
					color: $blue-dark;
				}
			}

			i{

				svg{

					*{
						fill: $blue-dark;
					}
				}
			}
		}

		.tab-header{

			&.active{
				color: $blue-dark;
			}
		}

		.tab-content.tab-content.tab-content{

			@media (max-width: $md){
				border-bottom-color: rgba($white,.4);
			}
		}
	}

	&.blue-light{
		background-color: $blue-lighter!important;

		@media (max-width: $md){
			
			.title-con{
				margin-bottom: 2.8rem;
			}

			.tab-header{
				@include font-size(20);
			}
		}
	}

	.container{
		max-width: 122.8rem;
	}

	.tab-content{

		@media (max-width: $md){
			border-bottom: 1px solid rgba($blue,.4);
		}
	}

	.title-con{
		margin-top: 1.9rem;
		margin-bottom: 5.4rem;

		@media (min-width: $md + 1){
			flex: 0 0 34%;
			max-width: 34%;
		}
	}

	.row{
		padding-top: 8.3rem;
		padding-bottom: 8.7rem;

		@media (max-width: $md){
			padding-top: 3.5rem;
			padding-bottom: 4.3rem;
		}
	}

	.images{
		width: calc(100% - .5rem - .5rem);
		max-width: calc(100% - .5rem - .5rem);
		margin: -.5rem;
		align-items: center;

		&.has-under{
			margin-bottom: 4.4rem;

			@media (max-width: $md){
				margin-bottom: 2rem!important;
			}
		}

		img{
			margin: 0 .5rem;
		}

		&.single{
			margin-top: 3.8rem;

			@media (max-width: $md){
				margin: 0 -.5rem;
				margin-top: -.5rem;
			}

			img{
				width: 100%;

				@media (max-width: $md){
					width: calc(100% + 1rem);
					max-width: calc(100% + 1rem);
					aspect-ratio: 312/220;
					object-fit: cover;
					object-position: 50% 50%;
				}
			}
		}

		&:not(.single){
			margin-top: 7.4rem;
			display: flex;
			justify-content: space-between;

			@media (max-width: $md){
				margin-top: 1.8rem;
				flex-wrap: wrap;
				justify-content: center;
				margin-bottom: 2.8rem;

				.img-con{
					width: 42%;
					margin: 0 4%;
				}
			}

			img{
				margin-bottom: 3.7rem;

				@media (max-width: $md){
					margin-bottom: 2.3rem;
				}
			}
		}
	}

	.btn-con{
		margin-top: 5rem;

		@media (max-width: $md){
			margin-top: 2rem;
			
			.btn{
				padding-left: 1.1rem;
				padding-right: 1.1rem;

				&.tel{
					padding-left: 1.1rem;
					padding-right: 1.1rem;
				}
			}
		}
	}

	p.large{
		@include line-height(20,24);

		@media (max-width: $md){
			@include font-size(18);
			@include line-height(18,24);
		}
	}

	.column{
		margin-top: 3.4rem;
		margin-bottom: 1rem;

		@media (min-width: $md + 1){
			column-count: 2;
			column-gap: 3.7rem;
		}

		p{
			break-inside: avoid;

			strong{
				color: $red;
				font-weight: 600;
			}
		}

		ul,
		ol{
			break-inside: avoid;
			break-before: avoid-column;
		}

		ul{

			li{
				padding-left: 1em;
			}
		}
	}

	.points{
		display: flex;
		flex-wrap: wrap;
		margin-top: 5.5rem;

		@media (max-width: $md){
			margin-top: 0;
		}

		.point{
			min-width: 10rem;

			@media (max-width: $md){
				width: 100%;
				margin-bottom: 2rem;
			}

			&:has(+ .point){
				margin-right: 11.5rem;

				@media (max-width: $md){
					margin-right: 0;
				}
			}

			.inner-inner{
				text-align: center;

				@media (max-width: $md){
					text-align: left;
					display: flex;
					align-items: center;
				}
				
				.icon-side{
					width: 100%;
					margin-bottom: 1.4rem;

					@media (max-width: $md){
						width: 5.5rem;
						margin-right: 1.7rem;
						margin-bottom: 0;
					}
				
					img{
						margin: 0 auto;

						@media (max-width: $md){
							width: 5.5rem;
						}
					}
				}
	
				.title-side{
					width: 100%;

					@media (max-width: $md){
						width: calc(100% - 5.5rem - 1.7rem);
					}
	
					h4{
						color: $blue-light;
						font-weight: 600;
					}
				}
			}
		}
	}
}

.our-team-section{
	background-color: $blue-lighter!important;

	.container{
		max-width: 122.8rem;
	}

	.row{
		padding-top: 10.1rem;
		padding-bottom: 18.9rem;

		@media (max-width: $md){
			padding-top: 5.1rem;
			padding-bottom: 3.6rem;
		}
	}

	.title-side{
		
		@media (min-width: $md + 1){
			flex: 0 0 31.35%;
			max-width: 31.35%;
		}

		@media (max-width: $md){
			text-align: center;
			margin-bottom: 2.5rem;
		}
	}

	.text-side{
		margin-bottom: 7rem;

		@media (min-width: $md + 1){
			flex: 0 0 68.65%;
			max-width: 68.65%;
			padding-left: 10.9rem;
			padding-right: 6rem;
		}

		@media (max-width: $md){
			text-align: center;
			margin-bottom: 7.3rem;
		}
	}

	dl.accordion{
		padding-top: .4rem;
	}

	.filter-top{
		display: flex;
		align-items: center;
		padding-right: 4rem;
		margin-bottom: 4.7rem;

		@media (min-width: $md + 1){
			display: none;
		}

		@media (max-width: $md){
			padding-right: 0;
			justify-content: space-between;
			width: 100%;
		}

		span{
			white-space: nowrap;
			margin-right: 1.7rem;

			@media (max-width: $md){
				@include font-size(18);	
			}
		}

		select{
			background-color: $white;
			border-radius: .3rem;
			border: none;
			height: 3.9rem;
			line-height: 3.9rem;
			@include font-size(18);
			color: $colour;
			padding-right: 5.1rem;
			padding-left: 2.1rem;
			background-position: right 1.5rem top 50%;

			@media (max-width: $md){
				width: auto;
			}
		}
	}

	.tab-container.tab-container{

		.tab-content + .tab-header{

			@media (max-width: $md){
				margin-top: 3rem;
			}
		}

		.tab-header{

			@media (max-width: $md){
				padding: .2rem 3rem .2rem 0;

				&.active{
					color: $red;
				}
				
				+ .tab-content{
					max-height: 9999px;
				}

				&:after{
					display: none;
				}
			}
		}
	}

	dl.accordion{

		dt{

			a{
				@media (max-width: $md){
					display: none;
				}


			}
		}
	}
	
}

dl.accordion{

	dt{
		border-top: 1px solid rgba($blue-light,.4);
		padding: 1.8rem 11rem 1.8rem 1rem;
		position: relative;
		transition: $time;
		cursor: pointer;

		@media (max-width: $md){
			padding: 1.8rem 2.7rem 1.8rem 0rem;
		}

		&:hover,
		&:focus-visible{
			
			h3{
				color: $blue-light;
			}
		}

		h3{
			margin-bottom: .15em;
			font-weight: 500;
			transition: $time;

			@media (max-width: $md){
				@include font-size(20);
			}
		}

		.role{

			@media (max-width: $md){
				@include font-size(14);
				@include line-height(14,18);
			}
		}

		&.open{
			padding: 2.1rem 11rem 2.1rem 1rem;

			@media (max-width: $md){
				padding: 1.8rem 2.7rem 1.8rem 0rem;
			}

			i.arrow{
				transform: rotateX(180deg) translateY(-50%);
			}
		}

		i.arrow{
			position: absolute;
			top: 50%;
			transform: rotateX(0deg) translateY(-50%);
			transform-origin: 50% 0%;
			right: 1.9rem;
			aspect-ratio: 20.789/11.455;
			width: 2.0789rem;
			backface-visibility: visible!important;
			transition: $time;

			@media (max-width: $md){
				width: 1.2905rem;
				right: .3rem;

				svg{

					*{
						fill: $blue-dark;
					}
				}
			}

			svg{
				width: 100%;
			}
		}

		a{
			position: absolute;
			top: 50%;
			right: 8.1rem;
			transform: translateY(-50%);
			@include font-size(22);
			color: $blue-light;

			&:hover,
			&:focus-visible{
				color: $blue-dark;
			}
		}
	}

	dd{
		margin: 0;
		max-height: 0;
		overflow: hidden;
		transition: $time;

		&:last-of-type{
			border-bottom: 1px solid rgba($blue-light,.4);
		}

		.inner.inner.inner{
			padding: 2.1rem 11rem 2.1rem 1rem;

			@media (max-width: $md){
				padding: .8rem 2.7rem .8rem 0rem;
			}

			.linkedin{
				@include font-size(20);
				color: $blue-light;
				display: inline-block;
				margin-top: 1.2rem;
				margin-bottom: .6rem;
				
				@media (min-width: $md + 1){
					display: none;
				}

				&:hover,
				&:focus-visible{
					color: $blue-dark;
				}
			}
		}
	}
}

.text-with-image-section{

	.container{
		max-width: 122.6rem;
	}

	.row{
		padding-top: 10.1rem;
		padding-bottom: 11.4rem;

		@media (max-width: $md){
			padding-top: 3.9rem;
			padding-bottom: 7.1rem;
		}
	}

	.text-side{
		padding-top: .2rem;

		@media (min-width: $md + 1){
			padding-right: 23rem;
			flex: 0 0 58.9%;
			max-width: 58.9%;
		}

		img{
			aspect-ratio: 310/210;
			object-fit: cover;
			object-position: 50% 50%;
		}

		.p-img{

			@media (min-width: $md + 1){
				display: none;	
			}
		}
	}

	h2{
		margin-bottom: 1.3em;

		@media (max-width: $md){
			margin-bottom: 1em;
		}
	}

	blockquote{

		@media (min-width: $md + 1){
			margin-left: 14.2rem;
			width: calc(100% + 21.5rem);
			max-width: calc(100% + 21.5rem);
		}
	}

	.image-side{

		@media (min-width: $md + 1){
			flex: 0 0 41.1%;
			max-width: 41.1%;
			padding-right: 6rem;
		}

		@media (max-width: $md){
			display: none;
		}
	}
}

.text-with-image-2-section{
	background-color: $blue-light!important;

	&:has( + .tabs-section.blue){

		.row{
			padding-bottom: 9.6rem;

			@media (max-width: $md){
				padding-bottom: 4.9rem;
			}
		}
	}

	.container{
		max-width: 122.6rem;
	}

	.row{
		padding-top: 8.4rem;
		padding-bottom: 11.4rem;

		@media (max-width: $md){
			padding-top: 4.5rem;
		}
	}

	.text-side{
		padding-top: .2rem;

		@media (min-width: $md + 1){
			flex: 0 0 65.3%;
			max-width: 65.3%;
			padding-left: 7.1rem;
			padding-right: 14rem;
		}

		@media (max-width: $md){
			
			h1.title{
				display: none;
			}
		}
	}

	p.large{
		@include line-height(20,24);
	}

	*{
		color: $white;
	}

	h1.title{
		@include font-size(100);
		@include line-height(100,110);
		margin-bottom: .64em;
		position: relative;
		z-index: 2;

		@media (max-width: $md){
			@include font-size(35);
			@include line-height(35,43);
		}

		@media (min-width: $md + 1){
			margin-left: -25.9rem;

			span{

				&:nth-of-type(2){
					padding-left: 1em;
				}
			}
		}
	}

	.image-side{

		@media (min-width: $md + 1){
			flex: 0 0 34.7%;
			max-width: 34.7%;
			margin-top: 12.1rem;

			h1.title{
				display: none;
			}
		}

		@media (max-width: $md){
			margin-bottom: 2.8rem;
			
			h1.title{
				width: calc(100% + .6rem);
				max-width: calc(100% + .6rem);
			}
		}
	}
}

.text-with-image-3-section{
	background-color: $blue-dark!important;

	.container{
		max-width: 122.6rem;
	}

	.row{
		padding-top: 9.7rem;
		padding-bottom: 9.5rem;

		@media (max-width: $md){
			padding-top: 4.3rem;
			padding-bottom: 4.3rem;
		}
	}

	.text-side{
		padding-top: .2rem;

		@media (min-width: $md + 1){
			flex: 0 0 62.7%;
			max-width: 62.7%;
			padding-right: 16.3rem;
		}

		@media (max-width: $md){
			margin-bottom: 3rem;
		}
	}

	p.large{
		@include line-height(20,24);

		@media (max-width: $md){
			@include font-size(18);
			@include line-height(18,24);
		}
	}

	*{
		color: $white;
	}

	.image-side{

		@media (min-width: $md + 1){
			flex: 0 0 37.3%;
			max-width: 37.3%;
			padding-right: 10.1rem;
		}

		img{
			width: 100%;

			@media (max-width: $md){
				aspect-ratio: 312/223;
				object-fit: cover;
				object-position: 50% 50%;
			}
		}
	}

	.btn-con{
		margin-top: 7.1rem;

		@media (max-width: $md){
			margin-top: 2.1rem;

			.btn{
				width: 100%;
			}
		}
	}
}

.text-with-image-4-section{

	.container{
		max-width: 122.6rem;
	}

	.row{
		padding-top: 10.1rem;
		padding-bottom: 17.5rem;
		align-items: center;

		@media (max-width: $md){
			padding-top: 3.8rem;
			padding-bottom: 3.9rem;
		}
	}

	.text-side{
		padding-top: .2rem;

		@media (min-width: $md + 1){
			padding-right: 22rem;
			flex: 0 0 57.6%;
			max-width: 57.6%;
		}
	}

	h2{
		margin-bottom: 1.05em;

		@media (min-width: $md + 1){
			padding-right: 11.7rem;
		}
	}

	.image-side{
		position: relative;

		@media (min-width: $md + 1){
			flex: 0 0 42.4%;
			max-width: 42.4%;
			padding-right: 0rem;
			padding-top: 10.8rem;
		}

		@media (max-width: $md){
			margin-top: 10rem;
		}

		img{
			width: 100%;
		}

		&:before{
			content: '';
			position: absolute;
			top: 1.7rem;
			left: -20.1%;
			width: 70.069%;
			background-image: url(../images/dots4.svg);
			background-repeat: no-repeat;
			aspect-ratio: 364.989/251.558;
			background-size: 100% auto;

			@media (max-width: $md){
				display: none;
			}
		}
	}
}

.text-with-scroll-image-section{
	background-color: $blue-lighter!important;

	&:has(.video-con-con){
		position: relative;

		.row{
			margin-bottom: 4.9rem;

			@media (max-width: $md){
				margin-bottom: 0;
			}
		}
		
		&:before{
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			height: 26rem;
			bottom: 0;
			background-color: $white;

			@media (max-width: $md){
				display: none;
			}
		}
	}

	.container{
		max-width: 122.8rem;
	}

	.row{
		padding-top: 10.2rem;
		padding-bottom: 10.2rem;

		@media (max-width: $md){
			padding-top: 5.1rem;
			padding-bottom: 0rem;
		}
	}

	.title-con{
		margin-bottom: 4.3rem;

		@media (max-width: $md){
			margin-bottom: 2.3rem;
		}
	}

	.image-side{

		@media (max-width: $md){
			margin-bottom: 5.8rem;
		}

		@media (min-width: $md + 1){
			flex: 0 0 44.4%;
			max-width: 44.4%;
		}

		img{
			width: 100%;

			&:has(+ div){
				margin-bottom: 2.5rem;

				@media (max-width: $md){
					margin-bottom: 1.2rem;
				}
			}

			@media (max-width: $md){
				aspect-ratio: 312/210;
				object-fit: cover;
				object-position: 50% 50%;
			}
		}

		.subject{
			color: $red;
			font-weight: 600;

			@media (max-width: $md){
				@include font-size(14);
				@include line-height(14,18);
			}
		}

		.role{
			@include font-size(14);
			@include line-height(14,21);
			margin-top: .1em;

			@media (max-width: $md){
				@include font-size(12);
				@include line-height(12,18);
				margin-top: -.1em;
			}
		}

		.img-con{
			position: sticky;
			top: 14.3rem;

		}
	}

	.text-side{

		@media (min-width: $md + 1){
			flex: 0 0 55.6%;
			max-width: 55.6%;
			padding-left: 7.7rem;
			padding-right: 2.6rem;
		}

		@media (max-width: $md){
			padding-bottom: 4.9rem;
			max-height: 47rem;
			overflow: hidden;

			&:after{
				content: 'Read more';
				padding: 16rem 0 4.5rem;
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba($blue-lighter,1) 80%);
				text-align: center;
				text-decoration: underline;
				color: $blue-dark;
				cursor: pointer;
			}

			&.open{
				max-height: 999999rem;

				&:after{
					display: none;
				}
			}
		}

		p{

			strong{
				font-weight: 600;
			}
		}
	}

	.video-con-con{
		margin-top: 23.6rem;

		@media (max-width: $md){
			margin-top: 0rem;
		}

		@media (min-width: $md + 1){
			margin-bottom: -10.2rem;
		}

		.video-con{
			margin: 0 auto;
			width: 96.8rem;
			border-radius: .5rem;
			overflow: clip;

			@media (max-width: $md){
				margin-left: -3.1rem;
				border-radius: 0;
				width: 100vw;
				max-width: 100vw;
			}
		}

		a{
			position: relative;
			display: block;

			&:hover,
			&:focus-visible{
				
				i{

					&:before{
						content: '';
						background-color: rgba($blue-light,1);
					}
				}
			}

			i{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 8.2rem;
				height: 8.2rem;
				border-radius: 8.2rem;
				overflow: clip;

				@media (max-width: $md){
					width: 3.1rem;
					height: 3.1rem;
				}

				&:before{
					content: '';
					position: absolute;
					width: 100%;
					height: 100%;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 100%;
					background-color: rgba($white,.33);
				}

				&:after{
					content: '';
					position: absolute;
					top: 50%;
					left: 55%;
					transform: translate(-50%, -50%);
					border-style: solid;
					border-top: 1.6rem solid transparent;
					border-bottom: 1.6rem solid transparent;
					border-left: 2.8rem solid #D3D3D3;
					border-right: 0;

					@media (max-width: $md){
						border-top: .55rem solid transparent;
						border-bottom: .55rem solid transparent;
						border-left: .9rem solid #D3D3D3;
					}
				}
			}
		}
	}
}

.milestones-section{

	.container{
		max-width: 130.8rem;
	}

	.row{
		padding-top: 10.1rem;
		padding-bottom: 15rem;

		@media (max-width: $md){
			padding-top: 4.7rem;
			padding-bottom: 9rem;
		}
	}

	.title-con{
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		margin-bottom: 4.2rem;

		@media (max-width: $md){
			flex-wrap: wrap;
			margin-bottom: 2.8rem;
		}

		h2{
			margin-bottom: 0;

			@media (max-width: $md){
				width: 100%;
				margin-bottom: 2.2rem;
			}
		}

		.year{
			display: flex;
			align-items: center;
			padding-right: 4rem;

			@media (max-width: $md){
				padding-right: 0;
				justify-content: space-between;
				width: 100%;
			}

			span{
				white-space: nowrap;
				margin-right: 1.7rem;

				@media (max-width: $md){
					@include font-size(18);	
				}
			}

			select{
				background-color: $blue-lighter;
				border-radius: .3rem;
				border: none;
				height: 3.9rem;
				line-height: 3.9rem;
				@include font-size(18);
				color: $colour;
				padding-right: 5.1rem;
				padding-left: 2.1rem;
				background-position: right 1.5rem top 50%;

				@media (max-width: $md){
					width: auto;
				}
			}
		}
	}

	.milestones-slide{
		width: calc(100% + 1.25rem + 1.25rem);
		margin-left: -1.25rem;

		@media (max-width: $md){
			width: calc(100% + .5rem + .5rem);
			margin-left: -.5rem;
		}

		.swiper-wrapper-con{
			overflow: visible!important;
		}

		.swiper-slide{
			padding: 0 1.25rem;

			@media (max-width: $md){
				padding: 0 .5rem;

				&:has(.m-item:nth-of-type(1)){
					width: 28rem!important;
				}

				&:has(.m-item:nth-of-type(2)){
					width: 28*2rem!important;
				}
				
				&:has(.m-item:nth-of-type(3)){
					width: 28*3rem!important;
				}

				&:has(.m-item:nth-of-type(4)){
					width: 28*4rem!important;
				}

				&:has(.m-item:nth-of-type(5)){
					width: 28*5rem!important;
				}

				&:has(.m-item:nth-of-type(6)){
					width: 28*6rem!important;
				}

				&:has(.m-item:nth-of-type(7)){
					width: 28*7rem!important;
				}

				&:has(.m-item:nth-of-type(7)){
					width: 28*7rem!important;
				}

				&:has(.m-item:nth-of-type(9)){
					width: 28*9rem!important;
				}
			}
		}

		.inner{
			width: calc(100% + 1.25rem + 1.25rem);
			max-width: calc(100% + 1.25rem + 1.25rem)!important;
			margin-left: -1.25rem;
			display: flex;

			@media (max-width: $md){
				width: calc(100% + .5rem + .5rem);
				max-width: calc(100% + .5rem + .5rem)!important;
				margin-left: -.5rem;
			}
		}

		.swiper-button-next,
		.swiper-button-prev{
			bottom: -5rem;
		}

		.swiper-scrollbar{
			bottom: -4.4rem;
		}


		.m-item{
			padding: 0 1.25rem;
			margin-bottom: 0rem;

			@media (max-width: $md){
				padding: 0 .5rem;
			}

			.inner-inner{
				width: 34.3rem;

				@media (max-width: $md){
					width: 27rem;
				}
			}

			img{
				aspect-ratio: 1/1;
				object-fit: cover;
				object-position: 50% 50%;
				width: 100%;
				margin-bottom: 2.3rem;
			}

			.year{
				@include font-size(20);
				@include line-height(20,30);
				color: $blue-light;
				font-weight: 500;
				margin-bottom: -.1em;

				@media (max-width: $md){
					@include font-size(16);
					@include line-height(16,25);
				}
			}

			h4{
				margin-bottom: .6em;

				@media (max-width: $md){
					@include font-size(20);
					@include line-height(20,25);
				}
			}
		}
	}
}

.hero-with-breadcrumb-2-section + .video-section{
	margin-top: -7.7rem;
	background-color: transparent;

	@media (max-width: $md){
		margin-top: 0;
	}
}

.video-section{

	.container{

		@media (max-width: $md){
			padding: 0;
		}
	}

	.video-con-con{

		.video-con{
			margin: 0 auto;
			width: 96.8rem;
			border-radius: .5rem;
			overflow: clip;

			@media (max-width: $md){
				border-radius: 0;
			}
		}

		a{
			position: relative;
			display: block;

			&:hover,
			&:focus-visible{
				
				i{

					&:before{
						content: '';
						background-color: rgba($blue-light,1);
					}
				}
			}

			i{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 8.2rem;
				height: 8.2rem;
				border-radius: 8.2rem;
				overflow: clip;

				@media (max-width: $md){
					width: 3.1rem;
					height: 3.1rem;
				}

				&:before{
					content: '';
					position: absolute;
					width: 100%;
					height: 100%;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 100%;
					background-color: rgba($white,.33);
				}

				&:after{
					content: '';
					position: absolute;
					top: 50%;
					left: 55%;
					transform: translate(-50%, -50%);
					border-style: solid;
					border-top: 1.6rem solid transparent;
					border-bottom: 1.6rem solid transparent;
					border-left: 2.8rem solid #D3D3D3;
					border-right: 0;

					@media (max-width: $md){
						border-top: .55rem solid transparent;
						border-bottom: .55rem solid transparent;
						border-left: .9rem solid #D3D3D3;
					}
				}
			}
		}
	}
}

.points-section{

	.container{
		max-width: 122.8rem;
	}

	.row{
		padding-top: 6.2rem;
		padding-bottom: 11.5rem;

		@media (max-width: $md){
			padding-top: 3.2rem;
			padding-bottom: 5rem;
		}
	}

	.point{
		border-bottom: 1px solid #D3D3D3;
		display: flex;
		padding-top: 3.5rem;
		flex-wrap: wrap;

		@media (max-width: $md){
			padding-top: 1.8rem;
		}
	}

	.icon-side{
		width: 9.9%;

		@media (max-width: $md){
			width: 25.8%;

			img{
				width: 6rem!important;
			}
		}
		
	}

	.title-side{
		padding-top: 1.5rem;
		width: 31.8%;

		h2{
			color: $red;
		}

		@media (max-width: $md){
			width: 64.2%;
			padding-top: 0;
			align-self: center;

			h2{
				@include font-size(20);
			}
		}
	}

	.text-side{
		padding-top: 1.9rem;
		width: 50.1%;
		padding-bottom: 5.4rem;

		@media (max-width: $md){
			width: 100%;
			padding-bottom: 2.5rem;
		}
	}

	p{

		&.medium{
			@include line-height(18,21);
		}
	}
}

.points-7-section + .points-6-section{
	margin-top: 6rem;

	@media (max-width: $md){
		margin-top: 0;
	}
}

.points-6-section{
	background-color: $blue-light!important;

	.container{
		max-width: 123.4rem;
	}

	.row{
		padding-top: 10.3rem;

		@media (max-width: $md){
			padding-top: 5.1rem;
		}

		&.bottom{
			padding-top: 9.8rem;
			padding-bottom: 9.8rem;

			@media (max-width: $md){
				padding-top: 3rem;
				padding-bottom: 5rem;
			}

			@media (min-width: $md + 1){
				column-count: 3;
				column-gap: 8.8rem - .8rem - .8rem;
				display: block;

				.single-employee-benefits &{
					padding-top: 4.6rem;
				}
			}
		}
	}

	h2{
		margin-bottom: .7em;

		@media (max-width: $md){
			margin-bottom: 1em;
		}
	}

	.text-con{
		text-align: center;
	}

	*{
		color: $white;
	}

	.point{
		border-bottom: 1px solid $blue-dark;
		margin-bottom: 4.8rem;
		break-inside: avoid;

		@media (max-width: $md){
			margin-bottom: 0;
		}

		.top{
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 2.5rem;

			@media (max-width: $md){
				padding: 2rem 2rem 2rem 0;
				align-items: center;
				position: relative;
				margin-bottom: 0;

				&.open{

					+ .bottom{
						max-height: 999rem;
						padding-bottom: 3.6rem;
					}

					&::after{
						transform: translateX(-50%) rotateX(180deg);
					}
				}

				&::after{
					content: '';
					position: absolute;
					top: 50%;
					right: 0;
					aspect-ratio: 12.905/7.513;
					width: 1.2905rem;
					background-image: url(../images/acc-arrow.svg);
					background-repeat: no-repeat;
					background-size: 100% auto;
					transform: translateX(-50%) rotateX(0deg);
					backface-visibility: visible!important;
					transition: $time;
				}
			}

			img{
				width: 7.5rem!important;
				margin-bottom: 3rem;

				@media (max-width: $md){
					width: 6rem!important;
					margin-right: 1.7rem;
					margin-bottom: 0;
				}
			}

			h4{
				width: 100%;
				font-weight: 600;

				@media (max-width: $md){
					width: calc(100% - 6rem - 1.7rem);
					margin-bottom: 0;
					@include font-size(20);
					@include line-height(20,25);
				}
			}
		}

		.bottom{
			padding-bottom: 3.6rem;

			@media (max-width: $md){
				max-height: 0;
				overflow: hidden;
				padding-bottom: 0rem;

				*{
					color: $blue-dark;
				}
			}
		}

		.link-con{
			margin-top: 4.7rem;
			margin-bottom: -1rem;

			.link{
				display: flex;
				align-items: center;
				color: $blue-dark;
				@include font-size(18);
				font-weight: 600;

				&:hover,
				&:focus-visible{
					
					i{
						margin-left: 4.4rem;
					}
				}

				i{
					max-width: 3.328rem;
					overflow: hidden;
					display: flex;
					justify-content: flex-end;
					margin-left: 3.4rem;
		
					svg{
						aspect-ratio: 33.28/12.91;
						width: 3.328rem;
						max-width: 3.328rem;
						min-width: 3.328rem;
						height: 1.291rem;

						*{
							fill: $blue-dark;
						}
					}
				}
			}
		}
	}

	ul{

		li{
			padding-left: 1.06em;
		}
	}
	
	li,
	p{

		&.medium{
			@include line-height(18,21);
		}
	}

	p{
		
		@media (min-width: $md + 1){
			padding-right: .7rem;
		}
	}
}

.points-8-section{
	background-color: $blue-dark!important;

	.container{
		max-width: 125.4rem;
	}

	.row{
		padding-top: 10.3rem;
		padding-bottom: 10.1rem;

		@media (max-width: $md){
			padding-top: 5.1rem;
			padding-bottom: 4.6rem;
		}
	}

	*{
		color: $white;
	}

	@media (min-width: $md + 1){
		
		.container,
		.col-12{
			padding-right: 2.05rem;
			padding-left: 2.05rem;
		}

		.row{
			margin-right: -2.05rem;
			margin-left: -2.05rem;
		}
	}

	.text-con{
		text-align: center;
		margin-bottom: 6.3rem;
	}

	.point{
		margin-bottom: 2rem;

		@media (min-width: $md + 1){
			text-align: center;
		}

		@media (max-width: $md){
			display: flex;
			flex-wrap: wrap;
			align-items: center;
		}
	}

	.icon-side{
		width: 100%;
		margin-bottom: 2.9rem;

		@media (max-width: $md){
			width: 6rem;
			margin-right: 2rem;
			margin-bottom: 0;
		}

		img{
			margin: 0 auto;
			width: 7.5rem!important;
		}
	}

	.title-side{
		width: 100%;
		margin-bottom: 2.5rem;

		@media (max-width: $md){
			width: calc(100% - 6rem - 2rem);
			margin-bottom: 0;

			h4{
				@include font-size(20);
				@include line-height(20,25);
			}
		}
	}

	.text-side{
		width: 100%;
		padding: 0 .7rem;

		@media (max-width: $md){
			padding: 0;
			margin-top: 2rem;
			padding-bottom: 2.3rem;
			border-bottom: 1px solid rgba($blue-light,.4);
		}
	}

	p{

		&.medium{
			@include line-height(18,21);
		}
	}

	.under{
		margin-top: .2rem;

		@media (max-width: $md){
			
			.btn-con{
				margin-top: .9rem;
				justify-content: flex-start;
			}
		}
	}
}

.points-7-section{

	.container{
		max-width: 129.2rem;
	}

	.row{

		&.top{
			padding-top: 10.2rem;
			padding-bottom: 6.3rem;

			@media (max-width: $md){
				padding-top: 4.1rem;
				padding-bottom: 4rem;
			}
		}

		&.bottom{
			padding-top: 6.2rem;
			padding-bottom: 3.7rem;
			background-color: $red;

			@media (max-width: $md){
				margin-right: -3.1rem;
				margin-left: -3.1rem;
				padding: 4.5rem 2.7rem 2.3rem;
			}
			
			@media (min-width: $md + 1){
				padding-left: 5.3rem;
				padding-right: 4.4rem;
			}

			*{
				color: $white;
			}
		}
	}

	h2{
		margin-bottom: .7em;

		@media (max-width: $md){
			margin-bottom: 1em;
		}
	}

	.text-side{

		@media (max-width: $md){
			margin-bottom: 3.2rem;	
		}

		@media (min-width: $md + 1){
			padding-left: 4.1rem;
			padding-right: 12rem;

			h2{
				padding-right: 5rem;
			}
		}
	}

	h2{
		margin-bottom: 1.1em;
	}

	.image-side-mob{
		position: relative;
		
		&:after{
			content: '';
			position: absolute;
			top: -3.3rem;
			left: 3.8%;
			width: 61.153%;
			background-image: url(../images/dots6.svg);
			background-repeat: no-repeat;
			aspect-ratio: 397/219;
			background-size: 100% auto;

			@media (max-width: $md){
				display: none;
			}
		}

		img{
			aspect-ratio: 311/210;
			object-fit: cover;
			object-position: 50% 50%;

			@media (min-width: $md + 1){
				display: none;
			}
		}
	}

	.points-side{

		@media (min-width: $md + 1){
			flex: 0 0 67.2%;
			max-width: 67.2%;
			padding-right: 4.2rem;
		}

		h4{
			@include font-size(22);
			@include line-height(22,27);
			font-weight: 600;
		}

		.title{
			margin-bottom: 3.5rem;

			@media (max-width: $md){
				margin-bottom: 2.9rem;
			}
		}
	}

	.image-side-desk{

		@media (min-width: $md + 1){
			flex: 0 0 32.8%;
			max-width: 32.8%;
			margin-top: -29rem;
		}

		@media (max-width: $md){
			display: none;
		}

	}

	.point{

		.inner{
			display: flex;
			align-items: center;
			margin-bottom: 2.4rem;

			@media (max-width: $md){
				margin-bottom: 2.7rem;
			}
		}
		
		.icon-con{
			width: 7rem;
			margin-right: 2rem;

			@media (max-width: $md){
				width: 6rem;
			}
		}

		.title-con{
			width: calc(100% - 7rem - 2rem);

			@media (min-width: $md + 1){
				padding-right: 2rem;
			}

			@media (max-width: $md){
				width: calc(100% - 6rem - 2rem);
			}
		}
	}

}

.points-4-section{
	background-color: $blue-dark!important;

	.container{
		max-width: 122.8rem;
	}

	.row{
		padding-top: 10.2rem;
		padding-bottom: 11.5rem;

		@media (max-width: $md){
			padding-top: 5rem;
			padding-bottom: 4.5rem;
		}
	}

	*{
		color: $white;
	}

	.point{
		border-bottom: 1px solid rgba($white,.4);
		display: flex;
		padding-top: 2.6rem;
		padding-bottom: 2.6rem;

		@media (max-width: $md){
			flex-wrap: wrap;
			border-bottom-color: rgba($blue-light,.4);
			padding-top: 1.9rem;
			padding-bottom: 4.4rem;
		}
	}

	.text-con{
		margin-bottom: 7.3rem;
		text-align: center;

		@media (max-width: $md){
			text-align: left;
			margin-bottom: 1.7rem;
		}

		.text{
			width: 54.4rem;
			margin-left: auto;
			margin-right: auto;
		}

		h2{
			margin-bottom: .45em;

			@media (max-width: $md){
				margin-bottom: .7em;
				@include font-size(35);
				@include line-height(35,40);
			}
		}
	}

	.icon-side{
		width: 9.8%;
		align-self: center;

		@media (max-width: $md){
			width: 5.5rem;
			margin-right: 1.7rem;
		}
	}

	.title-side{
		width: 31.5%;
		align-self: center;
		
		@media (max-width: $md){
			width: calc(100% - 5.5rem - 1.7rem);
		}

		h2{
			@include font-size(35);
			@include line-height(35,40);

			@media (max-width: $md){
				@include font-size(18);
				@include line-height(18,25);
			}
		}

		*{
			color: $blue-light;
		}
	}

	.text-side{
		width: 58.7%;
		
		@media (min-width: $md + 1){
			padding-left: 9.8rem;
			padding-right: 1rem;
		}

		@media (max-width: $md){
			width: 100%;
			margin-top: 2rem;
		}
	}

	p{

		&.medium{
			@include line-height(18,21);
		}
	}
}

.text-columns-section{
	background-color: $blue-lighter!important;

	&:has(+ .points-2-section){
		position: relative;

		&:after{
			content: '';
			position: absolute;
			left: 50%;
			bottom: 0;
			transform: translateX(-50%);
			border-bottom: 1px solid rgba($blue-light,.4);
			width: 121.8rem;
			max-width: 121.2rem;

			@media (max-width: $md){
				max-width: calc(100% - 3.1rem - 3.1rem);
			}
		}
	}

	.container{
		max-width: 122.8rem;
	}

	.row{
		padding-top: 10.3rem;
		padding-bottom: 8.5rem;

		@media (max-width: $md){
			padding-top: 5.1rem;
			padding-bottom: 4.5rem;
		}
	}

	.title-con{
		margin-bottom: 4.3rem;

		@media (max-width: $md){
			margin-bottom: 2.4rem;
		}
	}

	.col-md-4{

		@media (min-width: $md + 1){
			flex: 0 0 33.6%;
			max-width: 33.6%;
		}

		@media (max-width: $md){
			margin-bottom: 2.9rem;
			
			img{
				aspect-ratio: 310/210;
				object-fit: cover;
				object-position: 50% 50%;
			}
		}
	}

	.p-img{

		img{

			@media (max-width: $md){
				margin-top: 8.7rem;
			}
		}
	}

	.col-md-8{

		@media (min-width: $md + 1){
			flex: 0 0 66.4%;
			max-width: 66.4%;
			padding-left: 8.4rem;
		}

		@media (max-width: $md){
			
			img{
				aspect-ratio: 310/210;
				object-fit: cover;
				object-position: 50% 50%;
			}
		}
	}

	p:not(.p-img){

		@media (max-width: $md){
			padding-right: .4rem;
		}
	}

	* + h3{
		color: $red;
		margin-top: 2.3em;
		font-weight: 500;

		@media (min-width: $md + 1){
			padding-right: 4rem;
		}

		@media (max-width: $md){
			margin-top: 7.7rem;
		}
	}

	h3{
		@include line-height(30,40);

		@media (max-width: $md){
			@include font-size(20);
			@include line-height(20,25);
		}
	}
}

.text-columns-2-section{
	position: relative;

	&:after{
		content: '';
		position: absolute;
		bottom: 9.3rem;
		right: 0%;
		width: 49.953%;
		width: 69.9333rem;
		background-image: url(../images/dots3.svg);
		background-repeat: no-repeat;
		aspect-ratio: 700/182;
		background-size: 100% auto;
		
		@media (max-width: $md){
			display: none;
		}
	}

	.container{
		max-width: 122.8rem;
	}

	.row{
		padding-top: 9.7rem;
		padding-bottom: 10.6rem;

		@media (max-width: $md){
			padding-top: 4.1rem;
			padding-bottom: 4.8rem;
		}
	}

	.title-con{
		margin-bottom: 4.3rem;

		@media (max-width: $md){
			margin-bottom: 2.4rem;
		}
	}

	.col-md-4{

		@media (min-width: $md + 1){
			flex: 0 0 41.1%;
			max-width: 41.1%;

			p:not(.p-img){
				padding-right: 2rem;
			}
		}

		@media (max-width: $md){
			margin-bottom: 3.4rem;
			
			.p-img{

				img{
					margin-top: 6.9rem;
				}
			}
		}
	}

	.col-md-8{

		@media (min-width: $md + 1){
			flex: 0 0 58.9%;
			max-width: 58.9%;
			padding-left: 11.8rem;
			padding-right: 11.8rem;
		}

		@media (max-width: $md){
			
			h5{
				@include font-size(18);
				@include line-height(18,22);
				margin-bottom: 1.4em;
			}
		}
	}

	p{

		strong{
			color: $blue-light;
			font-weight: 600;
		}
	}

	* + h5{
		margin-top: 1.2em;
	}

	h5{
		color: $blue-light;
		margin-bottom: .95em;
	}
}

.text-columns-with-video-section{
	background-color: $red!important;
	position: relative;

	&:after{
		content: '';
		position: absolute;
		bottom: 9rem;
		right: -6.7%;	
		width: 49.953%;
		width: 69.9333rem;
		background-image: url(../images/dots3.svg);
		background-repeat: no-repeat;
		aspect-ratio: 700/182;
		background-size: 100% auto;
	}

	.container{
		max-width: 122.8rem;
	}

	.row{
		padding-top: 9.9rem;
		padding-bottom: 9.8rem;

		@media (max-width: $md){
			padding-top: 4.5rem;
			padding-bottom: 4.5rem;
		}
	}

	*{
		color: $white;
	}

	.title-con{
		margin-bottom: 4rem;

		@media (max-width: $md){
			margin-bottom: 2.3rem;
		}
	}

	.col-md-6{

		@media (min-width: $md + 1){

			&:nth-of-type(2){
				flex: 0 0 50%;
				max-width: 50%;
				padding-top: .2rem;
			}

			&:nth-of-type(3){
				flex: 0 0 50%;
				max-width: 50%;
				padding-left: 10.1rem
			}
		}

		@media (max-width: $md){
			
			&:nth-of-type(2){
				margin-bottom: 3rem;
			}

			.btn-con{
				margin-top: 6rem;

				.btn{
					width: 100%;
				}
			}
		}
	}

	p{

		&.large{
			@include line-height(20,24);

			@media (max-width: $md){
				@include font-size(18);
				@include line-height(18,24);
			}
		}
	}

	.video-con{
		margin: 0 auto;
		width: 100%;
		border-radius: .5rem;
		overflow: clip;
		margin-top: 7.2rem;

		@media (max-width: $md){
			margin-top: 2.4rem;
		}

		a{
			position: relative;
			display: block;
	
			&:hover,
			&:focus-visible{
				
				i{
	
					&:before{
						content: '';
						background-color: rgba($blue-light,1);
					}
				}
			}
	
			i{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 8.2rem;
				height: 8.2rem;
				border-radius: 8.2rem;
				overflow: clip;
	
				&:before{
					content: '';
					position: absolute;
					width: 100%;
					height: 100%;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 100%;
					background-color: rgba($white,.33);
				}
	
				&:after{
					content: '';
					position: absolute;
					top: 50%;
					left: 55%;
					transform: translate(-50%, -50%);
					border-style: solid;
					border-top: 1.6rem solid transparent;
					border-bottom: 1.6rem solid transparent;
					border-left: 2.8rem solid #D3D3D3;
					border-right: 0;
				}
			}
		}
	}
}

.points-2-section{
	background-color: $blue-lighter!important;

	.container{
		max-width: 122.8rem;
	}

	.row{
		padding-top: 6.3rem;
		padding-bottom: 10.2rem;

		@media (max-width: $md){
			padding-top: 5.1rem;
			padding-bottom: 6.2rem;
		}
	}

	.title-con{
		margin-bottom: 5.7rem;

		@media (max-width: $md){
			margin-bottom: .9rem;
		}
	}

	.col-12{

		.point{
			display: flex;
			align-items: center;

			*{
				transition: $time;
			}

			&.active{

				*{
					color: $red;
				}

				.content-image{

					img{
						opacity: 1;
						visibility: visible;
					}
				}
			}

			.content{
				border-bottom: 1px solid rgba($blue-light,.4);
				padding-top: 1.87rem;
				padding-bottom: 1.87rem;
				display: flex;
				align-items: center;
				cursor: pointer;
				
				@media (min-width: $md + 1){
					flex: 0 0 59.6%;
					max-width: 59.6%;
				}

				@media (max-width: $md){
					padding-top: 2.09rem;
					padding-bottom: 2.09rem;
				}
			}

			.content-image{
				position: relative;
				pointer-events: none;
				
				@media (min-width: $md + 1){
					flex: 0 0 40.4%;
					max-width: 40.4%;
				}

				img{
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					opacity: 0;
					visibility: hidden;
					transition: $time;
				}
			}
		}

		.number-side{
			width: 13.1%;

			@media (max-width: $md){
				width: 21.6%;
				
				h1{
					@include font-size(70);
					@include line-height(70,70);
				}
			}
		}

		.title-side{
			width: 86.9%;

			@media (max-width: $md){
				width: 78.4%;
			}
		}

		h3{
			font-weight: 500;

			@media (max-width: $md){
				@include font-size(20);
				@include line-height(20,25);
				font-weight: 600;
			}
		}
	}
}

.ioa-gives-section{

	.row{
		padding-top: 10.1rem;
		padding-bottom: 11rem;

		@media (max-width: $md){
			padding-top: 4rem;
			padding-bottom: 3.7rem;
		}
	}

	.text-con{
		text-align: center;
		margin-bottom: 13rem;

		@media (max-width: $md){
			margin-bottom: 5.7rem;
		}

		.text{
			width: 70rem;
			margin-left: auto;
			margin-right: auto;
		}
	}

	h2{
		margin-bottom: .61em;

		@media (max-width: $md){
			margin-bottom: 1em;
		}
	}

	p.medium{
		@include line-height(18,21);
	}

	.gives-slide-con{
		pointer-events: none;
	}

	.gives-slide{
		width: calc(100% + 3.5rem + 3.5rem);
		max-width: calc(100% + 3.5rem + 3.5rem);
		margin-left: -3.5rem;

		@media (max-width: $md){
			width: calc(100% + 1rem + 1rem);
			max-width: calc(100% + 1rem + 1rem);
			margin-left: -1rem;
		}

		.swiper-slide{
			padding: 0 3.5rem;

			@media (max-width: $md){
				padding: 0 1rem;
				width: 12rem!important;
			}
		}

		.swiper-wrapper-con{
			overflow: visible!important;
		}

		.swiper-wrapper{
			transition-timing-function: linear;
		}
	}

	.btn-bottom{
		margin-top: 6.5rem;

		@media (max-width: $md){
			margin-top: 0rem;

			.btn-con{
				margin-top: 3.9rem;
			}
		}
	}
}

.newsletter-section{
	padding-bottom: 7.8rem;

	@media (max-width: $md){
		padding-bottom: 4rem;
	}

	.container{
		max-width: 121.1rem;
		background-color: $red;

		@media (max-width: $md){
			max-width: calc(100% - 6.2rem);
			padding-left: 2.4rem;
			padding-right: 2.4rem;
		}
	}

	.row{
		padding: 5.5rem 6.2rem 3.9rem;

		@media (max-width: $md){
			padding: 3.5rem 0 2.7rem;
		}
	}

	.text-con{
		margin-bottom: 4.7rem;

		@media (max-width: $md){
			margin-bottom: 3.8rem;
		}

		*{
			color: $white;
		}
	}

	h2{
		margin-bottom: .38em;

		@media (max-width: $md){
			@include font-size(24);
			@include line-height(24,30);
			margin-bottom: .48em;
		}
	}

	p{

		@media (max-width: $md){
			@include font-size(14);
			@include line-height(14,16);
		}
	}

	input{
		border-radius: .3rem;
		border: .1rem solid $red-dark;
		background-color: $red-dark;
		@include font-size(18);
		color: $white;
		padding: .6rem 1.5rem;
	}

	::-webkit-input-placeholder {
		@include font-size(18);
		color: $white;
	}
	 
	:-moz-placeholder { /* Firefox 18- */
		@include font-size(18);
		color: $white;
	}
	
	::-moz-placeholder {  /* Firefox 19+ */
		@include font-size(18);
		color: $white;
	}
	
	:-ms-input-placeholder {
		@include font-size(18);
		color: $white;
	}

	.gform-body{
		width: calc(100% - 15.4rem);

		@media (max-width: $md){
			width: 100%;
		}
	}

	.validation_message{
		color: $white;
	}

	form{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;

		.gfield.gfield.gfield{

			@media (max-width: $md){
				margin-bottom: 1.96rem;
			}
		}
	}

	.gform_footer{
		width: 11.784rem;
		margin-top: 0;
		margin-bottom: 1.3rem;

		@media (max-width: $md){
			width: 100%;
		}

		[type="submit"]{
			width: 11.784rem;
			background-color: $white;
			border-color: $white;
			color: $red!important;
			border-radius: .1rem;
			padding-left: .5rem;
			padding-right: .5rem;

			@media (max-width: $md){
				width: 100%;
			}

			&:hover,
			&:focus-visible{
				background-color: darken($white, 4.5%);
				border-color: darken($white, 4.5%);
			}
		}
	}
}

.newsroom-archive-section{

	.container{
		max-width: 132.2rem;
	}

	.row{
		padding-top: 10rem;
		padding-bottom: 10rem;

		@media (max-width: $md){
			padding-top: 3rem;
			padding-bottom: 5.8rem;
		}
	}

	@media (min-width: $md + 1){
		
		.container,
		.col-12{
			padding-right: 1.5rem;
			padding-left: 1.5rem;
		}

		.row{
			margin-right: -1.5rem;
			margin-left: -1.5rem;
		}
	}
	
	.n-item{

		@media (max-width: $md){
			margin-bottom: 1.6rem;

			&.featured{
				margin-bottom: 4rem;
			}

			.inner.inner{
				
				.img-con{
					margin-bottom: 2.5rem;
				}
			}
		}
	}
}

.insights-archive-section{

	.container{
		max-width: 126.2rem;
	}

	.row{
		padding-top: 10rem;
		padding-bottom: 9.1rem;

		@media (max-width: $md){
			padding-top: 4rem;
			padding-bottom: 5.8rem;
		}
	}

	@media (min-width: $md + 1){
		
		.container,
		.col-12{
			padding-right: 2.55rem;
			padding-left: 2.55rem;
		}

		.row{
			margin-right: -2.55rem;
			margin-left: -2.55rem;
		}
	}

	.pagination.pagination.pagination{
		margin-top: 2.4rem;
	}

	.in-item{

		@media (max-width: $md){
			margin-bottom: 2.5rem;

			&.featured{
				margin-bottom: 4rem;

				.img-con{
					aspect-ratio: 311/200;
					object-fit: cover;
					object-position: 50% 50%;
					margin-bottom: 2.5rem;
				}

				.text{
					padding-bottom: 5.4rem;
				}
			}

			.inner.inner{

				h4{
					margin-bottom: 1em;
				}

				p{
					@include font-size(16);
					@include line-height(16,19);
				}
			}
		}
	}
}

.in-item,
.n-item{
	margin-bottom: 1.9rem;

	&.featured{

		@media (max-width: $md){
			
			.inner{

				h4{
					@include font-size(25);
					@include line-height(25,30);
					margin-bottom: 1.1em;
				}

				p{
					margin-bottom: 1.9em;
				}
			}
		}

		@media (min-width: $md + 1){
			margin-bottom: 8rem;
			
			.inner{
				display: flex;
				justify-content: space-between;
				padding-left: 4.1rem;
				padding-right: 12rem;
				align-items: flex-start;

				.img-con{
					order: 2;
					width: 26.5%;
					margin-bottom: 0;
				}

				.date{
					margin-bottom: 1.9rem;
				}

				.text{
					width: 57%;
				}

				h4{
					@include font-size(40);
					@include line-height(40,45);
					margin-bottom: 1.1em;
				}

				p{
					@include font-size(18);
					@include line-height(18,21);
					width: 56rem;
					display: -webkit-box;
					-webkit-line-clamp: 6;
					-webkit-box-orient: vertical;
					overflow: hidden;
				}
			}
		}
	}

	.inner{
		width: 100%;
		position: relative;

		a{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;

			&:hover,
			&:focus-visible{
				
				~ .text{

					h4{
						color: $blue-light;
					}

					.read-more{

						i{
							max-width: .7513rem;
						}
					}
				}
			}
		}

		.img-con{
			aspect-ratio: 1/1;
			object-fit: cover;
			object-position: 50% 50%;
			width: 100%;
			margin-bottom: 2.4rem;

			@media (max-width: $md){
				margin-bottom: 2.2rem;
			}
		}

		.date{
			color: $blue-light;
			margin-bottom: 1.2rem;

			@media (max-width: $md){
				@include font-size(14);
				@include line-height(14,16);
			}
		}

		h4{
			@include font-size(23);
			@include line-height(23,28);
			transition: $time;

			@media (max-width: $md){
				@include font-size(20);
				@include line-height(20,25);
			}
		}

		p{
			text-overflow: ellipsis;
		}

		.read-more{
			text-decoration: underline;
			color: $blue-light;
			display: inline-flex;
			align-items: center;
			margin-top: .2rem;
			font-weight: 600;
			cursor: pointer;

			i{
				max-width: 0;
				overflow: hidden;
				display: flex;
				justify-content: flex-end;
				margin-left: 1.1rem;
				transition: $time;
	
				svg{
					aspect-ratio: 7.513/12.905;
					width: .7513rem;
					max-width: .7513rem;
					min-width: .7513rem;
					height: 1.2905rem;
				}
			}
		}
	}
}

.in-item{
	margin-bottom: 6.4rem;

	&.featured{

		@media (min-width: $md + 1){
			margin-bottom: 8rem;
			
			.inner{
				display: flex;
				justify-content: space-between;
				padding-left: 0;
				padding-right: 0;
				align-items: flex-start;

				.img-con{
					width: 49.3%;
					aspect-ratio: 600/400;
				}

				.date{
					margin-bottom: 1.9rem;
				}

				.text{
					width: 40.4%;
				}

				h4{
					@include font-size(40);
					@include line-height(40,45);
					margin-bottom: 1.1em;
				}

				p{
					width: 56rem;
					-webkit-line-clamp: 6;
				}
			}
		}

		.text{
			position: relative;
			padding-bottom: 8.1rem;

			.read-more{
				position: absolute;
				right: -1.1rem;
				bottom: 1.7rem;
			}
		}

		.by{
			position: absolute;
			left: 0;
			bottom: 0;
			display: flex;
			align-items: center;
			width: calc(100% - 9.5rem);

			.by-img-side{
				width: 5rem;
				margin-right: 1.5rem;

				img{
					aspect-ratio: 1/1;
					object-fit: cover;
					object-position: 50% 50%;
					border-radius: 50%;
				}
			}

			.by-side{
				@include font-size(14);
				@include line-height(14,20);
				color: $grey;
				width: calc(100% - 6.5rem);

				.by-name{
					color: $blue-dark;
					font-weight: 500;
				}
			}
		}
	}

	@media (max-width: $md){
		
		&:not(.featured){

			.inner.inner.inner{

				.date{
					@include font-size(16);
				}

				p{
					@include font-size(18);
				}
			}
		}
	}

	.inner{

		.text{

			.date{

				span{

					&:has(+ span){

						&:after{
							content: '|';
							margin: 0 .3em;
						}
					}
				}
			}
		}

		h4{
			//margin-bottom: 2.47em;

			@media (max-width: $md){
				@include font-size(25);
				@include line-height(25,30);
			}
		}
		
		p{
			@include font-size(18);
			@include line-height(18,21);
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

.filter-con{
	position: relative;
	margin-bottom: 7.6rem;

	@media (max-width: $md){
		margin-bottom: 3.4rem;
	}

	.hide{
		display: none;
	}

	.filter{
		background-color: $blue-dark;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding: 3rem 6.6rem 2.2rem 5rem;

		@media (max-width: $md){
			padding: 2.5rem 3.2rem 1.8rem 3.2rem;
		}

		label{
			color: $white;
			width: auto;
			margin-right: 7.6rem;
			font-weight: 600;
			@include font-size(18);
			align-self: center;

			@media (max-width: $md){
				@include font-size(16);
				margin-right: 0;
				width: 100%;
				margin-bottom: 1.5rem;
			}
		}

		.facetwp-facet{
			flex: 1 1;

			@media (max-width: $md){
				flex: 1 1 100%;
				width: 100%;
			}

			&:has(+ .facetwp-facet){
				margin-right: 3.8rem;

				@media (max-width: $md){
					margin-right: 0;
				}
			}
		}

		select{
			background-color: #094172;
			border: .1rem solid #094172;
			@include font-size(18);
			color: white;
			border-radius: .3rem;
			line-height: 3.9rem - .1rem - .1rem;
			height: 3.9rem;
			padding-left: 1rem;
			padding-right: 3.8rem;
			background-position: right 1.75rem top 50%;

			option{
				color: $colour;
				background-color: $white;
			}
		}

		.facetwp-facet{
			margin-bottom: 1rem;
		}
	}
}

.single-section{

	.container{
		max-width: 122.7rem;
	}

	.row{
		padding-top: 5.5rem;
		padding-bottom: 9.4rem;

		@media (max-width: $md){
			padding-top: 4rem;
			padding-bottom: 3.8rem;
		}
	}

	.cat{
		@include font-size(20);
		@include line-height(20,24);
		color: #0074B8;
		margin-bottom: 2.4rem;

		@media (max-width: $md){
			@include font-size(16);
			@include line-height(16,19);
			margin-bottom: .8rem;
		}

		span{

			&:has(+ span){

				&:after{
					content: '|';
					margin: 0 .3em;
				}
			}
		}
	}

	.featured-image{
		aspect-ratio: 816/465;
		object-fit: cover;
		object-position: 50% 50%;
		width: 100%;
		border-radius: .5rem;
		margin-bottom: 5rem;

		@media (max-width: $md){
			margin-bottom: 0.2rem;
			border-radius: 0;
		}
	}

	.col-md-8{

		@media (min-width: $md + 1){
			flex: 0 0 67.8%;
			max-width: 67.8%;
		}

		@media (max-width: $md){
			display: flex;
			flex-wrap: wrap;

			> *{
				order: 9999
			}

			> .cat{
				order: 1;
			}

			> h1{
				order: 2;
			}

			.featured-image{
				order: 3;
			}

			.by.mob{
				order: 4;
			}

			.jump.mob{
				order: 6;

				+ .text{
					order: 5;
					margin-bottom: 12rem;
				}
			}
		}

		.by{
			@include font-size(18);
			@include line-height(18,26);

			@media (min-width: $md + 1){
				display: none;
			}

			h4{
				margin-bottom: 1.8rem;
				@include font-size(15);
				@include line-height(15,30);
			}

			span{
				color: rgba($blue-light,.6);
				display: inline-block;
				margin: 0 .35em;
			}
		}

		.jump{
			margin-top: 3.4rem;
			margin-bottom: 3.8rem;

			@media (min-width: $md + 1){
				display: none;
			}

			h4{
				margin-bottom: 1.8rem;
				@include font-size(18);
				@include line-height(18,30);
				margin-bottom: 1.5em;
			}

			a{
				display: block;
				position: relative;
				text-decoration: none;
				padding-left: 4rem;
				border-bottom: 1px solid rgba($blue-light,.4);
				padding-bottom: 1.6rem;
				margin-bottom: 1rem;
				padding-right: 3rem;
				background-image: url(../images/jump-arrow.svg);
				background-repeat: no-repeat;
				background-position: right .5rem top .2rem;
				background-size: 1.2552rem auto;

				&:nth-of-type(1){
					background-image: url(../images/jump-arrow-up.svg);
				}

				&:hover,
				&:focus-visible{

					.title{
						font-weight: 600;
					}
				}

				.number{
					position: absolute;
					top: -.05em;
					left: 0;
				}

				.number{
					@include font-size(20);
					@include line-height(20,26);
					color: $blue-light;
					font-weight: 600;
					margin-top: -.3rem;
				}

				.title{
					@include font-size(16);
					@include line-height(16,19);
					transition: $time;
				}
			}
		}
	}

	.col-md-4{

		@media (min-width: $md + 1){
			flex: 0 0 32.2%;
			max-width: 32.2%;
			padding-left: 7.2rem;
		}

		side{
			position: sticky;
			top: 14.3rem;
		}

		.by{
			margin-top: 4rem;
			@include font-size(18);
			@include line-height(18,26);

			@media (max-width: $md){
				display: none;
			}

			h4{
				margin-bottom: 1.8rem;
			}

			.name,
			.date{
				color: rgba($grey,.6);
				display: inline-block;
			}

			span{
				color: rgba($blue-light,.6);
				display: inline-block;
				margin: 0 .35em;
			}
		}

		.jump{
			margin-top: 3.4rem;

			@media (max-width: $md){
				display: none;
			}

			h4{
				margin-bottom: 1.8rem;
			}

			a{
				display: block;
				position: relative;
				text-decoration: none;
				padding-left: 4rem;
				border-bottom: 1px solid rgba($blue-light,.4);
				padding-bottom: 1rem;
				margin-bottom: 1rem;

				&:hover,
				&:focus-visible{

					.title{
						font-weight: 600;
					}
				}

				.number{
					position: absolute;
					top: -.05em;
					left: 0;
				}

				.number{
					@include font-size(20);
					@include line-height(20,26);
					color: $blue-light;
					font-weight: 600;
				}

				.title{
					@include font-size(18);
					@include line-height(18,26);
					transition: $time;
				}
			}
		}

		.btn-con{
			margin-top: 2rem;
			margin-bottom: 0;

			@media (max-width: $md){
				display: none;
			}

			.btn{
				width: 100%;
			}
		}

		.posts{
			margin-top: 3.5rem;

			@media (max-width: $md){
				margin-top: 5.4rem;
			}

			h4{
				margin-bottom: 3rem;

				@media (max-width: $md){
					@include font-size(25);
					@include line-height(25,30);
				}
			}

			.s-item{
				margin-bottom: 2rem;
				border-bottom: 1px solid rgba($blue-light,.4);
				padding-bottom: 1.7rem;
				margin-bottom: 1.5rem;

				.inner{
					display: flex;
					align-items: flex-start;
					width: 100%;
					justify-content: space-between;
					position: relative;

					a{
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						z-index: 1;

						&:hover,
						&:focus-visible{
							
							~ .text{

								h6{
									//color: $blue-light;
								}

								svg{
									margin-left: 1rem;
								}
							}
						}
					}

					.img-con{
						aspect-ratio: 1/1;
						object-fit: cover;
						object-position: 50% 50%;
						width: 43.7%;
					}

					.text{
						width: 51%;
						margin-bottom: 0;
						align-self: stretch;
						padding-bottom: 2rem;
						position: relative;

						h6{
							transition: $time;
							color: $black;
							font-weight: 400;

							@media (max-width: $md){
								@include font-size(18);
								@include line-height(18,24);
							}
						}

						svg{
							aspect-ratio: 33.28/12.91;
							width: 3.328rem;
							max-width: 3.328rem;
							min-width: 3.328rem;
							height: 1.291rem;
							position: absolute;
							bottom: 0;
							left: 0;
							transition: $time;
							
							*{
								fill: $blue-light;
							}
						}
					}
				}
			}

			.read-more{
				text-decoration: underline;
				color: $blue-light;
				display: inline-flex;
				align-items: center;
				margin-top: .2rem;
				font-weight: 600;
				cursor: pointer;
				@include font-size(18);
				font-weight: 600;
				margin-top: 1.2rem;
				margin-bottom: 4.3rem;

				@media (max-width: $md){
					margin-bottom: 0;
				}

				&:hover,
				&:focus-visible{
					color: $blue;
					
					i{
						max-width: .7513rem;

						*{
							fill: $blue;
						}
					}
				}

				i{
					max-width: 0;
					overflow: hidden;
					display: flex;
					justify-content: flex-end;
					margin-left: 1.1rem;
					transition: $time;

					svg{
						aspect-ratio: 7.513/12.905;
						width: .7513rem;
						max-width: .7513rem;
						min-width: .7513rem;
						height: 1.2905rem;
					}
				}
			}
		}
	}

	.text{
		width: 100%;
		margin-bottom: 3.7rem;

		@media (max-width: $md){
			margin-bottom: 20rem;
		}

		&:has(+ .btn-con){
			margin-bottom: 7.3rem;
		}

		h3{

			@media (max-width: $md){
				@include font-size(22);
				@include line-height(22,28);
			}
		}
	}

	.title-con{
		width: 100%;
		
		h3{
			margin-bottom: .8em;

			@media (max-width: $md){
				margin-bottom: 1.2em;
			}
		}
	}

	.image-with-text{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 5.8rem;

		.image-side{
			width: 49%;

			@media (max-width: $md){
				width: 100%;
				margin-bottom: 2.8rem;
			}

			img{
				width: 100%;
				border-radius: .5rem;

				@media (max-width: $md){
					aspect-ratio: 311/200;
					object-fit: cover;
					object-position: 50% 50%;
				}
			}
		}

		.text-side{
			width: 47.1%;

			@media (max-width: $md){
				width: 100%;
			}
		}
	}

	.btn-con{
		margin-bottom: 3.5rem;

		@media (max-width: $md){
			margin-top: -1.7rem;
			margin-bottom: 3rem;

			.btn{
				width: 100%;
			}
		}
	}

	.wide-image{
		margin-bottom: 5rem;

		@media (max-width: $md){
			margin-bottom: 1.6rem;
		}
		
		img{
			aspect-ratio: 815/470;
			object-fit: cover;
			object-position: 50% 50%;
			width: 100%;
			border-radius: .5rem;

			@media (max-width: $md){
				border-radius: 0;
				aspect-ratio: 311/200;
				object-fit: cover;
				object-position: 50% 50%;
			}
		}
	}

	.half-images{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: 3.4rem;

		@media (max-width: $md){
			margin-bottom: 1.4rem;
		}

		.img-con{
			width: calc(50% - .8rem);
			margin-bottom: 1.6rem;
		}

		img{
			aspect-ratio: 1/1;
			object-fit: cover;
			object-position: 50% 50%;
			width: 100%;
			border-radius: .5rem;
		}
	}

	h3{
		font-weight: 500;
		color: $blue-light;
		margin-bottom: .8em;

		@media (max-width: $md){
			margin-bottom: 1.2em;
			@include font-size(22);
			@include line-height(22,28);
		}
	}

	p,
	li{
		@include font-size(18);
		@include line-height(18,21);

		@media (max-width: $md){
			@include font-size(16);
			@include line-height(16,19);
		}
	}

	p{
		margin-bottom: 1.19em;
	}

	.share{
		border-top: 1px solid rgba($blue-light,.4);
		padding-top: 2.4rem;

		h4{
			margin-bottom: .6em;

			@media (max-width: $md){
				@include font-size(20);
				@include line-height(20,30);
			}
		}

		a{
			color: $blue-light;

			&:hover,
			&:focus-visible{
				color: $blue;

				i{
					color: $blue!important;
				}
			}
		}
	}
}

.quotes-section{
	background-color: $blue!important;

	.container{
		max-width: 122.7rem;
	}

	.row{
		padding-top: 10.1rem;
		padding-bottom: 16.2rem;

		@media (max-width: $md){
			padding-top: 4.6rem;
			padding-bottom: 7.1rem;
		}
	}

	.text-con{
		margin-bottom: 5.7rem;
		text-align: center;

		@media (max-width: $md){
			text-align: left;
			padding-right: 3rem;
			margin-bottom: 3.2rem;
		}

		.text{
			width: 70rem;
			margin-left: auto;
			margin-right: auto;
		}

		*{
			color: $white;
		}
	}

	h2{
		margin-bottom: .32em;

		@media (max-width: $md){
			margin-bottom: .95em;
		}
	}

	.quote-slide{

		@media (max-width: $md){
			width: calc(100% + .75rem);
			max-width: calc(100% + .75rem);
			margin-left: -2rem;

			.swiper-wrapper-con{
				overflow: visible!important;
			}
		}

		.swiper-wrapper{
			margin-bottom: 2.1rem;
		}

		.swiper-button-next,
		.swiper-button-prev{

			@media (max-width: $md){
				bottom: -3rem;
			}

			&:hover,
			&:focus-visible{
				
				svg{

					*{
						fill: $blue-dark;
					}
				}
			}
		}

		.swiper-scrollbar{

			@media (max-width: $md){
				bottom: -2.4rem;
			}
		}

		.swiper-scrollbar .swiper-scrollbar-drag{
			
			&:hover,
			&:focus-visible{
				background-color: $blue-dark;
			}
		}

		.swiper-slide{

			@media (max-width: $md){
				padding: 0 .75rem;
			}

			.inner{
				padding: .5rem;
				background-color: $white;
				padding: 6rem 4.6rem 21.3rem;
				position: relative;
				border-radius: .5rem;
				overflow: hidden;

				@media (max-width: $md){
					padding: 5rem 3.2rem 17.7rem;
				}

				&:hover,
				&:focus-visible{

					&:after{
						max-height: 100%;
					}
					
					*:before,
					*:after,
					*{
						color: $white!important;
					}

				}

				&:after{
					content: '';
					position: absolute;
					bottom: 0em;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: $blue-light;
					transition: $time;
					max-height: 6.9rem;

					@media (max-width: $md){
						max-height: 100%;
					}
				}

				.quote{
					margin-bottom: 5rem;
					text-indent: 2.7em;
					position: relative;
					z-index: 2;
					transition: $time;

					@media (max-width: $md){
						color: $white!important;
						margin-bottom: 4.8rem;
					}

					&:before,
					&:after{
						@include font-size(85);
						line-height: 0;
						color: $blue-light;
						transition: $time;

						@media (max-width: $md){
							color: $white!important;
						}
					}
				
					&:before{
						content: '“';
						position: absolute;
						top: .11em;
						left: -.56em;
					}

					&:after{
						content: '”';
						position: absolute;
						bottom: -.47em;
						right: -.07em;
					}
				}

				.by{
					@include font-size(18);
					font-weight: 500;
					color: $blue-light;
					position: relative;
					z-index: 2;
					transition: $time;
					margin-bottom: -4.6rem;
					padding-right: 16.6rem;

					@media (max-width: $md){
						color: $white!important;
						padding-right: 13.6rem;
					}
				}

				img{
					position: absolute;
					aspect-ratio: 1/1;
					object-fit: cover;
					object-position: 50% 50%;
					bottom: 0;
					right: 0;
					width: 21.2rem;
					z-index: 2;

					@media (max-width: $md){
						width: 21.2rem;
					}
				}
			}
		}
	}
}

.latest-jobs-section{
	background-color: $blue-light!important;

	.container{
		max-width: 122.7rem;
	}

	.row{
		padding-top: 10.2rem;
		padding-bottom: 8.9rem;

		@media (max-width: $md){
			padding-top: 4.4rem;
			padding-bottom: 4.5rem;
		}

		.single-location &{
			padding-bottom: 10rem;

			@media (max-width: $md){
				padding-bottom: 4.8rem;
			}
		}
	}

	.text-con{
		text-align: center;
		margin-bottom: 5.9rem;

		@media (max-width: $md){
			text-align: left;
			margin-bottom: 2.5rem;
		}

		*{
			color: $white;
		}
	}

	.under{

		@media (max-width: $md){
			display: none;
		}
	}

	.btn-con{
		margin-top: 5.2rem;

		@media (max-width: $md){
			margin-top: 2.6rem;
		}

		.single-location &{
			margin-top: 6.2rem;

			@media (max-width: $md){
				margin-top: 1.6rem;

				.btn{
					width: 100%;
				}
			}
		}
	}
}

.j-item{
	margin-bottom: 1.8rem;

	.inner{
		background-color: $white;
		padding: 2.9rem 3.9rem 3rem;

		@media (max-width: $md){
			padding: 2.9rem 3.1rem 3rem;
		}

		.type{
			display: inline-block;
			background-color: $blue-lighter;
			padding: .5rem .9rem;
			@include font-size(14);
			margin-bottom: 1.6rem;

			@media (max-width: $md){
				margin-bottom: 1.4rem;
			}
		}

		h3{
			color: $blue-light;
			margin-bottom: .64em;
		}

		p{

			@media (max-width: $md){
				//display: none;
				@include font-size(15);
				@include line-height(15,18);
			}
		}

		.btn{
			padding-left: 2.45rem;
			padding-right: 2.45rem;

			&:hover,
			&:focus-visible{
				background-color: $blue;
				border-color: $blue;
			}
		}

		.top{

			@media (max-width: $md){
				position: relative;

				&.open{

					&:after{
						transform: rotateX(180deg);
					}

					p{
						display: block;
					}
				}

				&::after{
					content: '';
					position: absolute;
					top: 1rem;
					right: .5rem;
					background-image: url(../images/select-icon.svg);
					background-repeat: no-repeat;
					background-size: 100% auto;
					width: 2.064rem;
					height: 1.132rem;
					backface-visibility: visible!important;
					transform: rotateX(0deg);
					transition: $time;
				}

				p{
					display: none;
				}
			}
		}

		.bottom{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
			margin-top: 3.4rem;

			@media (max-width: $md){
				margin-top: 2.4rem;
			}

			.left{
				display: flex;
				align-items: center;

				@media (max-width: $md){
					width: 100%;
					flex-wrap: wrap;
				}

				div{

					&:has(+ div){
						margin-right: 2rem;

						@media (max-width: $md){
							margin-right: 1rem;
							margin-bottom: 1.5rem;
						}
					}
				}
			}

			.remote-or-hybrid,
			.location{
				display: flex;
				align-items: center;

				@media (max-width: $md){
					
				}

				svg{
					margin-right: 1.1rem;

					@media (max-width: $md){
						width: 2.488rem;
						min-width: 2.488rem;
					}
				}
			}

			.remote-or-hybrid{

			}

			.location{

				@media (max-width: $md){
					margin-bottom: 1.5rem;
				}
				
				svg{
					aspect-ratio: 13.146/19.729;
					object-fit: cover;
					object-position: 50% 50%;
					width: 1.3146rem;
					min-width: 1.3146rem;

					@media (max-width: $md){
						aspect-ratio: 13.15/19.75;
						width: 1.315rem;
						min-width: 1.315rem;
					}
				}
			}

			.right{

				@media (max-width: $md){
					width: 100%;
					margin-top: .3rem;

					.btn{
						width: 100%;
					}
				}
			}
		}
	}
}

.join-section{
	background-color: $blue-dark!important;

	.container{
		max-width: 124rem;
	}

	.row.top{
		padding-top: 10rem;
		padding-bottom: 11rem;
		cursor: pointer;
		transition: $time;

		@media (max-width: $md){
			padding-top: 4.5rem;
		}

		&.open.open{
			padding-bottom: 8.6rem;

			@media (max-width: $md){
				padding-bottom: 5.3rem;
			}

			.title-side{

				svg{
					transform: translateY(0rem) rotateX(180deg);
				}
			}

			+ .row.bottom{
				max-height: 99rem;
			}
		}

		*{
			color: $white;
		}

		&:hover,
		&:focus-visible{
			
			.title-side{

				svg{
					transform: translateY(1rem) rotateX(0deg);
				}
			}
		}
	}

	.title-side{
		
		@media (min-width: $md + 1){
			flex: 0 0 49.5%;
			max-width: 49.5%;
			margin-top: .2rem;
		}

		@media (max-width: $md){
			margin-bottom: 2.5rem;
		}

		h2{
			margin-bottom: 0;
		}

		svg{
			aspect-ratio: 35.586/18.854;
			width: 3.5586rem;
			margin-top: 2.7rem;
			margin-left: .3rem;
			backface-visibility: visible!important;
			transition: $time;

			@media (max-width: $md){
				display: none;
			}
		}
	}

	.text-side{

		@media (min-width: $md + 1){
			flex: 0 0 50.5%;
			max-width: 50.5%;
			padding-right: 4.8rem;
		}

		svg{
			aspect-ratio: 35.586/18.854;
			width: 2.6586rem;
			margin-top: 4.2rem;
			margin-left: 0rem;
			backface-visibility: visible!important;
			transition: $time;

			@media (min-width: $md + 1){
				display: none;
			}
		}
	}

	.row.bottom{
		max-height: 0;
		overflow: hidden;
		transition: $time;
	}

	.form-con{
		padding-bottom: 10rem;

		@media (max-width: $md){
			padding-bottom: 4.5rem;
		}

		.gform-body{
		
			@media (min-width: $md + 1){
				column-count: 2;
				column-gap: 4.25rem;
				padding-right: 3rem;
			}
		}

		label{
			color: $white;

			@media (max-width: $md){
				@include font-size(18);
				margin-bottom: .95rem;
			}
		}

		.gfield{
			margin-bottom: 1.7rem;

			input, 
			input.input-text, 
			textarea, 
			.fake-select, 
			select{
				border-bottom-color: rgba($white,.4);
			}

			textarea{
				border-top: 1px solid rgba($white,.4);
				margin-top: 1rem;
				padding-top: 1.7rem;
			}
		}

		.gform_footer{

			@media (min-width: $md + 1){
				padding-right: 3rem;
				padding-left: 61.8rem;
			}
			
			[type="submit"]{
				border-color: $blue-light;
				background-color: $blue-light;

				&:hover,
				&:focus-visible{
					border-color: $blue;
					background-color: $blue;
				}
			}
		}

		.gfield--type-fileupload{

			input{
				color: white;
			}

			input::file-selector-button{
				background-color: #094172;
				border-color: #094172;

				&:hover,
				&:focus-visible{
					background-color: $blue-light;
					border-color: $blue-light;
				}
			}
	
			.gform_fileupload_rules{
				color: rgba($white,.4);
			}
		}

		.gform_wrapper .gform_footer{
			margin-top: -.2rem;

			@media (max-width: $md){
				margin-top: -1.5rem;

				[type="submit"]{
					width: 100%;
				}
			}
		}
	}
}

.text-with-link-section{
	background-color: $blue-light;

	.container{
		max-width: 122.7rem;
	}

	.row{
		padding-top: 8.3rem;
		padding-bottom: 12.1rem;

		@media (max-width: $md){
			padding-top: 4.4rem;
			padding-bottom: 4.4rem;
		}
	}

	*{
		color: $white;
	}

	.text-side{

		@media (min-width: $md + 1){
			padding-right: 8rem;
		}
	}

	.btn-side{

		@media (min-width: $md + 1){
			padding-left: 10.8rem;
		}
	}

	p.large{
		@include line-height(20,24);

		@media (max-width: $md){
			@include font-size(18);
			@include line-height(18,24);
		}
	}

	.btn-con{

		@media (min-width: $md + 1){
			margin-top: 0;
		}

		@media (max-width: $md){
			margin-top: 2.5rem;

			.btn{
				width: 100%;
			}
		}
	}
}

.points-3-section{
	background-color: $blue-dark!important;

	.container{
		max-width: 126.3rem;
	}

	.row{
		padding-top: 1.3rem;
		padding-bottom: 5.4rem;

		@media (max-width: $md){
			padding-bottom: 1.5rem;
		}
	}

	@media (min-width: $md + 1){
		
		.container,
		.col-12{
			padding-right: 2.565rem;
			padding-left: 2.565rem;
		}

		.row{
			margin-right: -2.565rem;
			margin-left: -2.565rem;
		}
	}

	*{
		color: $white;
	}

	.text-con{
		margin-bottom: 7.3rem;

		@media (max-width: $md){
			margin-bottom: 3.3rem;
		}
	}

	h1,
	h2{
		color: $blue-light;


	}

	.col-md-6{
	
		.inner{
			display: flex;
			align-items: center;
			padding-bottom: 2.4rem;
			margin-bottom: 2.6rem;
			border-bottom: 1px solid rgba($blue-light,.4);

			@media (max-width: $md){
				padding-bottom: 2rem;
				margin-bottom: 2rem;
			}
		}
		
		.icon-side{
			width: 8rem;
			margin-right: 3.2rem;

			@media (max-width: $md){
				width: 5.5rem;
				margin-right: 1.7rem;
			}
		}

		.title-side{
			width: calc(100% - 8rem - 3.2rem);

			@media (max-width: $md){
				width: calc(100% - 5.5rem - 1.7rem);

				h4{
					@include font-size(18);
					@include line-height(18,23);
				}
			}
		}
	}
}

.points-5-section{
	background-color: $blue-lighter!important;

	.post-type-archive-personal-insurance &{
		background-color: $blue!important;

		*{
			color: $white;
		}
	}

	.container{
		max-width: 130.2rem;
	}

	.row{
		padding-top: 10.2rem;
		padding-bottom: 8.7rem;
		align-items: center;

		@media (max-width: $md){
			padding-top: 4.1rem;
			padding-bottom: 0rem;
		}
	}

	@media (min-width: $md + 1){
		
		.container,
		.col-12,
		.col-6{
			padding-right: 4.5rem;
			padding-left: 4.5rem;
		}

		.row{
			margin-right: -4.5rem;
			margin-left: -4.5rem;
		}
	}

	.text-side{
		
		@media (min-width: $md + 1){
			flex: 0 0 36.5%;
			max-width: 36.5%;
		}

		@media (max-width: $md){
			
			h2{
				padding-right: 7rem;
			}
		}
	}

	.points-side{

		@media (min-width: $md + 1){
			flex: 0 0 63.5%;
			max-width: 63.5%;
		}
	}

	.container-fluid{

		@media (min-width: $md + 1){
			padding-right: 2.1rem;
		}

		.row{
			padding-top: 6.5rem;
			padding-bottom: 0;

			@media (max-width: $md){
				padding-top: 2.7rem;
			}
		}
	}

	.col-md-3{
	
		.inner{
			padding-bottom: 2.4rem;
			margin-bottom: 2.6rem;
			text-align: center;
		}
		
		.icon-con{
			width: 100%;
			margin-bottom: 1.5rem;
			display: flex;
			justify-content: center;
			align-items: center;

			@media (max-width: $md){
				margin-bottom: 2.5rem;
			}

			img{
				margin: 0 auto;

				@media (max-width: $md){
					width: 10rem!important;
				}
			}
		}

		.title-con{
			width: 100%;

			h4{
				font-weight: 600;

				@media (max-width: $md){
					@include font-size(24);
				}
			}

		}
	}

	.btn-con{
		margin-top: 6.5rem;

		@media (max-width: $md){
			margin-top: 4.7rem;
		}
	}
}

.points-9-section{
	background-color: $red!important;

	.container{
		max-width: 124.6rem;
	}

	.row{
		padding-top: 10.3rem;
		padding-bottom: 3.1rem;
		text-align: center;
		justify-content: center;

		@media (max-width: $md){
			padding-top: 5.1rem;

		}
	}

	@media (min-width: $md + 1){
		
		.container,
		.col-12{
			padding-right: 2.565rem;
			padding-left: 2.565rem;
		}

		.row{
			margin-right: -2.565rem;
			margin-left: -2.565rem;
		}
	}

	*{
		color: $white;
	}

	.text-con{
		margin-bottom: 7.3rem;

		@media (max-width: $md){
			margin-bottom: 3.3rem;
		}

		.text{
			width: 60rem;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.col-md-3{
		margin-bottom: 6.3rem;

		@media (max-width: $md){
			margin-bottom: 1.9rem;
		}
	
		.inner{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			padding: 0 1.5rem;

			@media (max-width: $md){
				padding: 0;
			}
		}
		
		.icon-con{
			width: 100%;
			margin-bottom: 2.9rem;

			@media (max-width: $md){
				width: 6rem;
				margin-bottom: 0;
				margin-right: 2rem;
			}

			img{
				width: 7.5rem!important;
				margin: 0 auto;
			}
		}

		.title-con{
			width: 100%;

			@media (max-width: $md){
				width: calc(100% - 6rem - 2rem);
				text-align: left;

				h4{
					@include font-size(20);
					@include line-height(20,25);
				}
			}
		}
	}
}

.journey-section{
	background-color: $blue-light!important;

	.container{
		max-width: 123rem;
	}

	.row{
		padding-top: 10.2rem;
		padding-bottom: 10.1rem;

		@media (max-width: $md){
			padding-top: 4.6rem;
			padding-bottom: 4.5rem;
		}
	}

	h2{
		color: $white;
	}

	h3{
		color: $white;
		margin-top: 1.25em;
		@include font-size(35);
		@include line-height(35,40);

		@media (max-width: $md){
			margin-top: .95em;
			@include font-size(25);
			@include line-height(25,30);
		}
	}


	.form-con{

		.gform_confirmation_message.gform_confirmation_message.gform_confirmation_message.gform_confirmation_message{
			text-align: center;
			color: white;
		}

		::-webkit-input-placeholder{
			color: $white;
		}
		 
		:-moz-placeholder{ /* Firefox 18- */
			color: $white;
		}
		
		::-moz-placeholder{  /* Firefox 19+ */
			color: $white;
		}
		
		:-ms-input-placeholder{
			color: $white;
		}

		.gform-body{
		
			// @media (min-width: $md + 1){
			// 	column-count: 2;
			// 	column-gap: 4.25rem;
			// 	padding-right: 3rem;
			// }
		}

		.gfield--type-html.gfield--type-html{
			margin-bottom: 7.2rem;

			@media (max-width: $md){
				margin-bottom: 3.2rem;
			}

			&:has(h3){
				margin-bottom: 3.6rem;
			}
		}

		legend,label{
			
			@media (max-width: $md){
				@include font-size(18);
				margin-bottom: 1.5rem;
			}
		}
		

		.gfield--width-half{

			@media (min-width: $md + 1){
				width: calc(50% - 6.35rem);
			}
		}

		.gchoice{

			@media (max-width: $md){
				width: 100%;
				margin-right: 0;
			}
		}

		input[type="radio"],
		input[type="checkbox"]{
			display: none;

			& + span,
			& + .wpcf7-list-item-label,
			& + label{

				&:before{
					border: .1rem solid rgba($blue-dark,.5);
				}

				&:after{
					border: .1rem solid $blue-dark;
					background-color: $blue-dark;
					background-image: url(../images/tick.svg);
				}
			}
		}

		.gfield{
			margin-bottom: 3.8rem;

			@media (max-width: $md){
				margin-bottom: 1.7rem;
			}

			&.half{

				@media (min-width: $md + 1){
					width: calc(50% - 6.35rem);
					margin-right: 50%;
				}
			}

			input, 
			input.input-text, 
			textarea, 
			.fake-select, 
			select{
				border-bottom-color: rgba($white,.4);
				color: $white;
			}
		}

		.gform_footer{

			@media (min-width: $md + 1){
				padding-right: 3rem;
				padding-left: 67rem;
				margin-top: -8.3rem;
			}
			
			[type="submit"]{
				background-color: $blue-dark;
				border-color: $blue-dark;

				@media (max-width: $md){
					width: 100%;
				}
	
				&:hover,
				&:focus-visible{
					background-color: $blue;
					border-color: $blue;
				}
			}
		}

		textarea{
			border-top: 1px solid rgba($white,.4);
			margin-top: 1rem;
			padding-top: 1.7rem;
			height: 17.6rem;
			min-height: 17.6rem;
		}

		.gfield--type-fileupload{
			margin-top: -2.5rem;
			margin-bottom: 8.1rem!important;

			+ .gfield--type-radio{
				margin-bottom: 7.4rem;
			}

			.gform-field-label{
				@include font-size(20);
			}

			input{
				color: white;
			}

			input::file-selector-button{
				background-color: $blue-dark;
				border-color: $blue-dark;

				&:hover,
				&:focus-visible{
					background-color: $blue;
					border-color: $blue;
				}
			}
	
			.gform_fileupload_rules{
				color: rgba($white,.7);
			}
		}
	}
}

.lets-connect-section{
	padding-bottom: 11.9rem;

	@media (max-width: $md){
		padding-bottom: 3.9rem;
	}

	.container{
		max-width: 121.2rem;
		background-color: $blue-lighter;
	}

	.row{
		padding-top: 7.2rem;
		padding-bottom: 5.3rem;
		padding-left: 6.1rem;
		padding-right: 6.1rem;

		@media (max-width: $md){
			padding-top: 4.6rem;
			padding-bottom: 4.4rem;
			padding-left: 0.2rem;
			padding-right: 0.2rem;
		}
	}

	h2{
		margin-bottom: .75em;

		@media (max-width: $md){
			margin-bottom: 1em;
		}
	}

	p.medium{
		@include line-height(18,21);
	}

	.text-con{
		margin-bottom: 6.4rem;

		@media (max-width: $md){
			margin-bottom: 3.4rem;
		}
	}

	.form-con{

		@media (min-width: $md + 1){
			column-count: 2;
			column-gap: 9.35rem;
		}

		.gfield{
			margin-bottom: 3.8rem;
			break-inside: avoid;

			@media (max-width: $md){
				margin-bottom: 1.5rem;
			}

			&.gfield--type-textarea{
				
				@media (min-width: $md + 1){
					margin-top: 6.2rem;
				}
			}

			&.gfield--type-radio{

				@media (min-width: $md + 1){
					margin-top: 6.2rem;
				}
			}

			.gchoice{
				
				@media (max-width: $md){
					width: 100%;
					margin-right: 0;
				}
			}

			label{

				@media (max-width: $md){
					@include font-size(18);
					margin-bottom: 1.1rem;
				}
			}
		}

		textarea{
			border-top: 1px solid rgba($blue,.4);
			margin-top: 1rem;
			padding-top: 1.7rem;
			height: 14.2rem;
			min-height: 14.2rem;
		}

		input[type="radio"],
		input[type="checkbox"]{
			display: none;

			& + span,
			& + .wpcf7-list-item-label,
			& + label{
				color: $blue-dark;
			}
		}

		.gform_footer{

			@media (max-width: $md){
				margin-top: -1.3rem;
			}
			
			[type="submit"]{
				border-color: $blue-dark;
				background-color: $blue-dark;

				@media (max-width: $md){
					width: 100%;
				}

				&:hover,
				&:focus-visible{
					border-color: $blue;
					background-color: $blue;
				}
			}
		}
	}
}

.connect-with-a-broker-section ~ .find-ioa-office-near-you-section,
.broker-loop-section ~ .find-ioa-office-near-you-section{

	.items{
		display: none;
	}
}

.find-ioa-office-near-you-section{

	.container{
		max-width: 122.8rem;
	}

	.facetwp-type-map{
		max-width: initial!important;

		*{
			max-width: initial!important;
		}

		.cluster0,
        .cluster1,
        .cluster2,
        .cluster3,
        .cluster4,
        .cluster5
        {
			background-size: 38px 38px!important;
			background-position: 50% 50%!important;
			background-repeat: no-repeat!important;
			font-size: 1.6rem!important;
			color: $white!important;
			font-family: $main-font!important;
			font-weight: 400!important;
		}
	}

	.filter-top{
		background-color: $blue-dark;
		padding: 4.9rem 8.6rem 5.2rem;
		position: relative;
		z-index: 3;

		@media (max-width: $md){
			padding: 3.5rem 2.5rem 3.9rem;
		}

		*{
			color: $white;
		}

		h5{
			font-weight: 400;
			color: $blue-light;
		}

		h2{
			margin-bottom: 1.04em;

			@media (max-width: $md){
				@include font-size(24);
				margin-bottom: .9em;
			}
		}

		::-webkit-input-placeholder {
			@include font-size(18);
			color: $white;
		}
		 
		:-moz-placeholder { /* Firefox 18- */
			@include font-size(18);
			color: $white;
		}
		
		::-moz-placeholder {  /* Firefox 19+ */
			@include font-size(18);
			color: $white;
		}
		
		:-ms-input-placeholder {
			@include font-size(18);
			color: $white;
		}

		label{
			@include font-size(16);
			color: $blue-light;
			font-weight: 400;
			margin-bottom: 1rem;
		}

		.facetwp-icon.locate-me{
			aspect-ratio: 15.743/14.443;
			width: 1.5743rem;
			height: auto;
			top: 50%;
			transform: translateY(-50%);
			right: 2.3rem;
			opacity: 1;
			transition: $time;
			@include font-size(0);
			
			&:hover,
			&:focus-visible{
				opacity: .4;
			}

			&:before{
				background-image: url(../images/location-search.svg);
				background-repeat: no-repeat;
				aspect-ratio: 15.743/14.443;
				background-size: 100% auto;
				width: 1.5743rem;
				height: auto;
				background-position: 50% 50%;
			}
		}

		.location-results{

			*{
				color: $colour;
			}

			.location-attribution{
				display: none;
			}
		}

		.facetwp-input-wrap{
			width: 100%;
			
			input{
				color: $white;
				@include font-size(18);
				background-color: #094172;
				border: .1rem solid #094172;
				padding: .6rem 4.5rem .6rem 1.5rem;
			}
		}
	}

	.bottom-con{
		position: relative;
		margin-top: -11.1rem;
		
		.facetwp-map-filtering{
			display: none;
		}

		.fwpl-layout{
			display: block;
		}

		.gm-style-iw-c.gm-style-iw-c.gm-style-iw-c.gm-style-iw-c{
			padding: 0 1.2rem!important;

			.gm-style-iw-chr{
				margin-right: -1.2rem;
			}

			.gm-style-iw-d{

				h4{
					font-weight: 600;
					margin-bottom: .25em;

					a{
						text-decoration: none;

						&:hover,
						&:focus-visible{
							color: $blue-light;
						}
					}
				}

				.btn-con{
					margin: 2.4rem -.5rem .4rem;
					max-width: calc(100% + .5rem + .5rem);
					justify-content: space-between;

					.btn{
						padding: 1rem 2.4rem;
						font-size: 1.6rem!important;
						font-weight: 500;
						margin: 0 .5rem 1rem;

						&.tel{

							i{
								aspect-ratio: 12.78/12.71;
								width: 1.278rem;
								margin-right: .8rem;
							}
						}

						&.pin{
							padding: 1rem 2.1rem;

							i{
								aspect-ratio: 9.14/13.71;
								width: .914rem;
								margin-right: .8rem;
							}
						}
					}
				}
			}
		}

		.items{
			position: absolute;
			top: 0;
			right: 0;
			height: 66.2rem - 14rem - 3.3rem;
			width: 36.9rem;
			z-index: 2;
			overflow: auto;
			margin-top: 14rem;

			@media (max-width: $md){
				display: none;
			}

			.m-item{
				margin-bottom: 1.9rem;
				
				.inner{
					background-color: $white;
					padding: 3.4rem 2.8rem 0rem;
					position: relative;

					.distance{
						background-color: $blue-light;
						color: $white;
						position: absolute;
						top: 0;
						right: 0;
						padding: .25rem 1.4rem;
						font-weight: 500;
					}

					h4{
						font-weight: 600;
						margin-bottom: .25em;

						a{
							text-decoration: none;

							&:hover,
							&:focus-visible{
								color: $blue-light;
							}
						}
					}

					.btn-con{
						margin: 2.4rem -.5rem .4rem;
						max-width: calc(100% + .5rem + .5rem);
						justify-content: space-between;

						.btn{
							padding: 1rem 2.4rem;
							font-size: 1.6rem!important;
							font-weight: 500;
							margin: 0 .5rem 1rem;

							&.tel{

								i{
									aspect-ratio: 12.78/12.71;
									width: 1.278rem;
									margin-right: .8rem;
								}
							}

							&.pin{
								padding: 1rem 2.1rem;

								i{
									aspect-ratio: 9.14/13.71;
									width: .914rem;
									margin-right: .8rem;
								}
							}
						}
					}
				}

				.more-info{
					background-color: $blue-dark;
					margin-left: -2.8rem;
					margin-right: -2.8rem;
					width: calc(100% + 2.8rem + 2.8rem);
					max-width: calc(100% + 2.8rem + 2.8rem);

					*{
						color: $white;
					}

					.top{
						display: flex;
						align-items: center;
						padding: .75rem 3rem;
						cursor: pointer;
						position: relative;

						&:after{
							content: '';
							position: absolute;
							left: 2.8rem;
							right: 2.8rem;
							height: 1px;
							background-color: rgba($blue-light,.4);
							bottom: 0;
							opacity: 0;
							visibility: hidden;
							transition: $time;
						}

						&.open{

							&:after{
								opacity: 1;
								visibility: visible;
							}

							i{
								transform: rotateX(180deg);
							}

							+ .bottom{
								max-height: 40rem;
							}
						}

						i{
							aspect-ratio: 12.345/6.88;
							width: 1.2345rem;
							margin-left: 1rem;
							backface-visibility: visible!important;
							transform: rotateX(0deg);
						}
					}

					.bottom{
						max-height: 0;
						overflow: hidden;
						transition: $time;
						
						.inner-inner{
							padding: 1.9rem 3rem 5rem;
						}

						h6{
							color: $blue-light;
							margin-bottom: .9em;
						}

						dl{
							display: flex;
							flex-wrap: wrap;

							dt{
								width: 11.5rem;
								padding-right: .5em;

								&.closed{
									color: $blue-light;

									+ dd{
										color: $blue-light;
									}
								}
							}

							dd{
								width: calc(100% - 11.5rem);
								margin: 0;
							}
						}
					}
				}
			}
		}

		.facetwp-type-map{
			width: 100vw;
			height: 62.9rem;
			
			@media (min-width: $md + 1){
				margin-left: -9.4rem;
			}

			@media (min-width: 1400px + 1){
				margin-left: calc((100vw - 121.2rem)/-2);
			}

			@media (max-width: $md){
				margin-left: -3.1rem;
				height: 40rem;
			}
		}
	}
}

.emergency-response-center-section{
	background-color: $red!important;

	.container{
		max-width: 123rem;
	}

	.row{
		padding-top: 10.6rem;
		padding-bottom: 9.2rem;

		@media (max-width: $md){
			padding-top: 5.1rem;
			padding-bottom: 4.9rem;
		}
	}

	*{
		color: $white;
	}

	.text-side{

		@media (min-width: $md + 1){
			flex: 0 0 58.8%;
			max-width: 58.8%;
			padding-top: .2rem;
			padding-right: 19rem;
		}

		@media (max-width: $md){
			margin-bottom: 3rem;
		}
	}

	.image-side{

		@media (min-width: $md + 1){
			flex: 0 0 41.2%;
			max-width: 41.2%;
			padding-right: 6.2rem;
		}

		img{

			@media (max-width: $md){
				aspect-ratio: 315/201;
				object-fit: cover;
				object-position: 50% 50%;
			}
		}
	}

	h2{
		//margin-bottom: 1em;

		+ .btn-con{
			margin-top: 3rem;

			.btn{
				padding: .9rem 1.8rem;
				font-weight: 600;
			}
		}
	}

	ul,
	p{
		width: 43rem;
	}

	.btn-con{
		margin-top: 4.5rem;

		@media (max-width: $md){
			margin-top: 5.7rem;

			.btn{
				padding-left: .5rem;
				padding-right: .5rem;
				width: 100%;
			}
		}

		&:has(+ *){
			margin-bottom: 3rem;
		}
	}
}

.hero-location-section{
	background-color: $blue-light!important;

	.container{
		max-width: 123rem;
	}

	.text-side{
		padding-top: 5.9rem;
		padding-bottom: 6rem;

		@media (min-width: $md + 1){
			flex: 0 0 56.1%;
			max-width: 56.1%;
		}

		@media (max-width: $md){
			padding-top: 3.5rem;
			padding-bottom: 2.7rem;
		}
	}

	.image-side{
		padding: 0;

		@media (min-width: $md + 1){
			flex: 0 0 43.9%;
			max-width: 43.9%;
		}

		.acf-map{
			width: 100%;
			height: 100%;
			border: none;
			margin: 0;

			@media (min-width: $md + 1){
				width: calc(100% + ((100vw - 123rem)/2));
				max-width: calc(100% + ((100vw - 123rem)/2));
			}


			@media (max-width: $md){
				aspect-ratio: 375/211;
				object-fit: cover;
				object-position: 50% 50%;
				margin-left: -2.7rem;
				width: 100dvw;
				max-width: 100dvw;
			}

			*{
				max-width: initial!important;
			}
		}
	}

	*{
		color: $white;
	}

	h1{
		margin-bottom: .7em;

		@media (max-width: $md){
			@include font-size(40);
			@include line-height(40,45);
			margin-bottom: .52em;
		}
	}

	.fax,
	address{
		padding-left: 4.7rem;
		position: relative;
		@include font-size(20);
		@include line-height(20,28);
		font-weight: 500;
		margin-bottom: .8em;

		@media (max-width: $md){
			padding-left: 3.6rem;
		}

		svg{
			position: absolute;
			aspect-ratio: 13.15/19.73;
			width: 1.315rem;
			top: .4rem;
			left: 1.2rem;

			@media (max-width: $md){
				left: .2rem;
			}

			*{
				fill: $blue-dark;
			}
		}
	}

	.fax{

		svg{
			aspect-ratio: 19.768/19.427;
			width: 1.9768rem;
		}
	}

	.btn-con{
		padding-left: 1rem;
		margin: 4.5rem -.965rem 0;
		max-width: calc(100% + .965rem + .965rem);

		@media (max-width: $md){
			padding-left: 0;
			margin: 2.8rem -.965rem 0;

			.btn{
				width: 100%;
			}
		}

		.btn.btn.btn.btn{
			margin: 0 .965rem 1rem;

			&:hover,
			&:focus-visible{
				background-color: $blue;
				border-color: $blue;
			}
		}
	}
}

.location-under-section{
	background-color: $blue-dark!important;

	.container{
		max-width: 118.8rem;
	}

	.row{
		padding-top: 3.5rem;
		padding-bottom: 3.6rem;

		@media (max-width: $md){
			padding-top: 3rem;
			padding-bottom: 3.1rem;
		}
	}

	*{
		color: $white;
	}

	.open-side{

		@media (min-width: $md + 1){
			flex: 0 0 56.1%;
			max-width: 56.1%;
		}

		@media (max-width: $md){
			margin-bottom: 1.3rem;
		}
	}

	.line-side{
		
		@media (min-width: $md + 1){
			flex: 0 0 43.9%;
			max-width: 43.9%;
			padding: 0;
		}
	}

	h3{
		margin-bottom: .7em;
	}

	dl{
		display: flex;
		flex-wrap: wrap;
		@include font-size(20);
		@include line-height(20,33);
		font-weight: 500;

		@media (max-width: $md){
			@include font-size(16);
			@include line-height(16,26);
		}

		*{
			@include font-size(20);
			@include line-height(20,33);
			font-weight: 500;

			@media (max-width: $md){
				@include font-size(16);
				@include line-height(16,26);
			}
		}

		dt{
			width: 24rem;
			padding-right: .5em;

			@media (max-width: $md){
				width: 17.6rem;
			}
		}

		dd{
			width: calc(100% - 24rem);
			margin: 0;

			@media (max-width: $md){
				width: calc(100% - 17.6rem);
			}
		}
	}

	.ticks{
		@include font-size(18);
		@include line-height(18,21);
		display: flex;
		margin-top: 2.7rem;

		@media (max-width: $md){
			flex-wrap: wrap;
			margin-top: 2.1rem;
		}

		@media (min-width: $md + 1){
			width: calc(100% + ((100vw - 123rem)/2));
			max-width: calc(100% + ((100vw - 123rem)/2));
		}

		.tick{
			@include font-size(18);
			@include line-height(18,21);
			padding-left: 1.3em;
			position: relative;
			margin-right: 1.8rem;
			font-family: 500;

			@media (max-width: $md){
				@include font-size(16);
				@include line-height(16,26);
				padding-left: 1.1em;
				margin-right: .8rem;
			}

			&:before{
				content: '';
				position: absolute;
				left: .15em;
				top: .2em;
				border-radius: 4rem;
				background-image: url(../images/tick-blue.svg);
				background-repeat: no-repeat;
				aspect-ratio: 11.296/11.275;
				width: 1.1296rem;
				background-size: 100% auto;

				@media (max-width: $md){
					top: .5em;
				}
			}
		}
	}
}

.points-9-section + .connect-with-section,
.ioas-employee-benefits-products-section + .connect-with-section,
.points-6-section + .connect-with-section{

	.row{
		padding-top: 10.1rem;

		@media (max-width: $md){
			padding-top: 4.9rem;
		}
	}
}

.connect-with-section{

	.container{
		max-width: 122rem;
		max-width: 122rem;
	}

	.row{
		padding-top: 6.2rem;
		padding-bottom: 1.9rem;
		justify-content: center;

		@media (max-width: $md){
			padding-top: 2.8rem;
			padding-bottom: 3.8rem;
		}
	}

	@media (min-width: $md + 1){
		
		.container,
		.col-12,
		.col-6{
			padding-right: 3.5rem;
			padding-left: 3.5rem;
		}

		.row{
			margin-right: -3.5rem;
			margin-left: -3.5rem;
		}
	}

	.text-con{
		text-align: center;
		margin-bottom: 5.5rem;

		@media (max-width: $md){
			margin-bottom: 4.5rem;
		}

		.single-industries &,
		.single-employee-benefits &,
		.single-business-insurance &{
			margin-bottom: 9.7rem;

			@media (max-width: $md){
				margin-bottom: 5.3rem;
			}
		}

		.text{
			width: 66rem;
			margin-left: auto;
			margin-right: auto;

			.single-industries &,
			.single-employee-benefits &,
			.single-business-insurance &{
				width: 78rem;

				h2{
					color: $red;

					@media (max-width: $md){
						@include font-size(25);
						@include line-height(25,30);
						margin-bottom: .99em;
					}
				}
			}
		}
	}

	h2{
		margin-bottom: .69em;

		@media (max-width: $md){
			@include font-size(35);
			@include line-height(35,40);
		}
	}


	.btn-con{
		margin-top: -1.6rem;
		margin-bottom: 8.2rem;

		@media (max-width: $md){
			margin-top: 0.6rem;
			margin-bottom: 1.2rem;
		}
	}
}

.notice-top-section{
	position: fixed;
	top: 10rem;
	width: 100%;

	@media (max-width: $md){
		top: 6.82rem;
	}
	

	&.close{
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
	}

	&.emergency{
		background-color: $red!important;
		z-index: 5;

		.col-12{
			padding-left: 5.5rem;

			@media (max-width: $md){
				padding-left: 3.2rem;
			}
		}
	}

	&.information-reminder{
		background-color: $blue-light!important;
		z-index: 4;
	}

	&.general-maintenance{
		background-color: $blue-dark!important;
		z-index: 3;
	}

	.container{
		max-width: 121.2rem;
		max-width: 130.6rem;
		position: relative;

		.close{
			position: absolute;
			transform: translateY(-50%);
			aspect-ratio: 15.963/15.963;
			width: 1.5963rem;
			top: 50%;
			right: 1.1rem;

			@media (max-width: $md){
				top: 0.5rem;
				right: 0.4rem;
			}

			&:hover,
			&:focus-visible{
				
				svg{

					*{
						fill: $blue;
					}
				}
			}

			svg{
				width: 100%;

				*{
					fill: $white;
				}
			}
		}
	}

	.row{
		align-items: center;
		padding-top: 1.45rem;
		padding-bottom: 1.45rem;

		@media (max-width: $md){
			padding-top: 2.25rem;
			padding-bottom: 1.65rem;
		}
	}

	.col-12{
		padding-right: 4.7rem;

		@media (max-width: $md){
			padding-right: 2.3rem;
		}
		
		&:has(.btn-side){
			display: flex;
			align-items: center;
			justify-content: space-between;

			.text-side{
				width: 69%;
			}

			.btn-side{

				.btn{
					padding-left: 2.2rem;
					padding-right: 2.2rem;

					&:hover,
					&:focus-visible{
						background-color: $blue;
						border-color: $blue;
					}
				}
			}
		}
	}

	*{
		color: $white;
	}

	p{
		font-weight: 500;

		@media (max-width: $md){
			@include font-size(14);
			@include line-height(14,20);
		}
	}

	.text-side{
		position: relative;

		img{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: -4.8rem;
			width: 2.546rem;
			aspect-ratio: 25.46/22.8;
			object-fit: cover;
			object-position: 50% 50%;

			@media (max-width: $md){
				left: -2.8rem;
				width: 1.846rem;
				top: .6rem;
			}
		}

		a{
			display: inline-flex;
			align-items: center;

			&:hover,
			&:focus-visible{
				color: $white;
				text-decoration: underline;

				&:after{
					margin-left: .8rem;
				}
			}

			&:after{
				content: '';
				margin-left: .6rem;
				aspect-ratio: 5.976/9.706;
				background-size: 100% auto;
				width: .5976rem;
				background-image: url(../images/link-arrow-simple.svg);
				background-repeat: no-repeat;
			}
		}
	}
}

.connect-with-section + .notice-section,
.connect-with-section + .notice-top-section + .notice-section{
	padding-top: 0;
}

.notice-section{
	padding-top: 8rem;
	padding-bottom: 10.6rem;

	@media (max-width: $md){
		padding-bottom: 4.3rem;
	}

	&:has(+ .notice-top-section),
	&:has(+ .notice-section){
		padding-bottom: 8rem;

		@media (max-width: $md){
			padding-bottom: 4rem;
		}
	}

	+ .notice-top-section + .notice-section,
	+ .notice-section{
		padding-top: 0;
	}

	&.emergency{

		.container{
			background-color: $red;
		}
	}

	&.information-reminder{

		.container{
			background-color: $blue-light;
		}
	}

	&.general-maintenance{

		.container{
			background-color: $blue-dark;
		}
	}

	.container{
		max-width: 121.2rem;
		padding: 5.5rem 7rem 7.1rem;

		@media (max-width: $md){
			max-width: calc(100vw - 6.2rem);
			padding: 2.3rem 2rem 2.6rem;
		}
	}

	.row{
		align-items: center;
	}

	*{
		color: $white;
	}

	.left{

		@media (min-width: $md + 1){
			flex: 0 0 53.4%;
			max-width: 53.4%;
		}

		@media (max-width: $md){
			padding-bottom: 1.4rem;
		}

		&:after{
			content: '';
			position: absolute;
			top: -.7rem;
			bottom: -2.3rem;
			width: 1px;
			background-color: rgba($white,.4);
			right: 0;

			@media (max-width: $md){
				left: .4rem;
				width: calc(100% - .4rem - .4rem);
				top: 100%;
				height: 1px;
			}
		}

		.text{
			width: 45rem;
		}
	}

	.right{
		text-align: center;

		@media (min-width: $md + 1){
			flex: 0 0 46.6%;
			max-width: 46.6%;
			padding-left: 4.6rem;
			padding-top: .8rem;
		}

		@media (max-width: $md){
			padding-top: 1.4rem;
			padding-left: 1.6rem;
			padding-right: 1.6rem;

			p{
				margin-bottom: 2.3em;
			}
		}
	}

	.tel-link{
		display: flex;
		justify-content: center;
		@include font-size(30);
		text-decoration: none;
		align-items: center;
		font-weight: 600;
		margin-top: -1.8rem;

		@media (max-width: $md){
			@include font-size(22);
		}

		i{
			aspect-ratio: 22.65/22.54;
			width: 2.265rem;
			margin-right: 1.7rem;

			@media (max-width: $md){
				width: 1.688rem;
				margin-right: 1.5rem;
			}

			svg{
				width: 100%;
			}
		}
	}

	h2{

		@media (max-width: $md){
			@include font-size(20);
			@include line-height(20,25);
			margin-bottom: 1.5em;
		}
	}

	h4{
		margin-bottom: .6em;

		@media (max-width: $md){
			@include font-size(20);
			margin-bottom: .8em;
		}
	}

	p{

		&.medium{
			@include line-height(18,21);

			@media (max-width: $md){
				@include font-size(16);
				@include line-height(16,22);
			}

			strong{
				@include line-height(18,23);

				@media (max-width: $md){
					@include font-size(16);
					@include line-height(16,22);
				}
			}
		}
	}
}

.emergency-response-center-hero-and-contacts-section{
	background-color: $red!important;

	.container{
		max-width: 122.8rem;
	}

	.row{
		min-height: 44.8rem;
		padding-top: 12.6rem;
		padding-bottom: 4.52rem;

		@media (max-width: $md){
			min-height: 0;
			padding-top: 6.2rem;
			padding-bottom: 3.12rem;
		}
	}

	*{
		color: $white;
	}

	h1{
		margin-bottom: .99em;

		@media (max-width: $md){
			@include font-size(40);
			@include line-height(40,45);
			margin-bottom: .72em;
		}
	}

	.btn-con{
		margin-top: -.7rem;

		.btn{

			@media (max-width: $md){
				width: 100%;
				font-size: 1.8rem!important;
				@include line-height(18,21);
			}

			&.blue-dark{

				&:hover,
				&:focus-visible{
					background-color: $blue;
					border-color: $blue;
				}
			}

			&.line-white{

				&:hover,
				&:focus-visible{
					background-color: transparent;
					border-color: $white;
				}
			}
		}
	}
}

.contacts-under-section{
	overflow-x: clip!important;
	overflow-y: visible!important;

	.container{
		max-width: 122.8rem;
	}

	.row{
		padding-top: 7.5rem;
		padding-bottom: 4.5rem;
		align-items: flex-start;

		@media (max-width: $md){
			padding-top: 3.9rem;
			padding-bottom: .3rem;
		}
	}

	h2{
		margin-bottom: .58em;

		@media (max-width: $md){
			@include font-size(35);
			@include line-height(35,40);

		}
	}

	.image-side{

		@media (min-width: $md + 1){
			padding-left: 11.7rem;
			margin-top: -43.7rem;
		}

		@media (max-width: $md){
			order: 1;
			margin-bottom: 2.9rem;
		}

		img{
			width: 100%;
			aspect-ratio: 489/556;
			object-fit: cover;
			object-position: 50% 50%;
		}
	}

	.text-side{

		@media (max-width: $md){
			order: 2;

			svg{
				display: none;
			}
		}
	}
}

.contacts-section{

	.container{
		max-width: 122.8rem;
	}

	.row{
		padding-bottom: 3rem;

		@media (max-width: $md){
			padding-bottom: .4rem;
		}
	}

	.contacts-top{
		margin-bottom: 4.8rem;
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		@media (max-width: $md){
			justify-content: center;
			margin-bottom: 2.8rem;
		}

		label,
		a,
		span{
			@include font-size(18);
			@include line-height(18,23);

			@media (max-width: $md){
				@include font-size(18);
				@include line-height(18,23);
			}
		}

		label{
			width: auto;
			margin-bottom: 0;
			font-weight: 400;
			margin-right: 1.3rem;

			@media (max-width: $md){
				@include font-size(16);
				@include line-height(16,19);
				margin-bottom: .8rem;
				margin-right: 0;
				width: 100%;
			}

			+ span,
			+ a{
				margin-left: 0rem;
			}
		}

		a,
		span{
			display: inline-block;
			text-decoration: none;
			padding: .1rem .2rem;
			margin-left: 1.845rem;
			text-transform: uppercase;

			@media (max-width: $md){
				margin-left: 0;
				width: 14.2857142857%;
				width: 5.5%;
				margin-right: 10.2%;
				text-align: center;
				margin-bottom: 1.6rem;

				&:nth-of-type(7n + 7){
					margin-right: 0;
				}

				&:last-of-type{
					margin-right: 0;
				}
			}
		}

		a{
			border-bottom: .2rem solid transparent;

			&:hover,
			&:focus-visible{
				border-bottom: .2rem solid $blue-light;
			}

			&.open{
				font-weight: 600;
				border-bottom: .2rem solid $blue-light;
			}
		}

		span{
			opacity: .4;
			pointer-events: none;

			@media (max-width: $md){
				display: none;
			}
		}
	}

	.contacts-bottom.contacts-bottom.contacts-bottom.contacts-bottom.contacts-bottom{

		.inner{
			display: none;

			&.open{
				display: block;
			}
		}

		table{

			@media (max-width: $md){
				display: block;
			}

			tr{

				@media (max-width: $md){
					display: block;
					padding-bottom: .6rem;
					border-bottom: 1px solid rgba($grey,.3);
					margin-bottom: .6rem;
				}
	
				td{
					padding: .57rem 0;
					padding-right: .57rem;
					border: none;
					border-bottom: 1px solid rgba($grey,.3);

					@media (max-width: $md){
						display: block;
						padding: 0;
						border-bottom: none;

						p.medium{
							@include font-size(18);
							@include line-height(18,21);
						}
					}

					&:last-of-type{
						padding-right: 0;
					}

					&:nth-of-type(1){
						width: 58.2%;

						@media (max-width: $md){
							width: 100%;
							margin-bottom: .65rem;
						}
					}

					&:nth-of-type(2){
						width: 15%;

						@media (max-width: $md){
							width: 100%;
							margin-bottom: .65rem;
						}
					}

					*{
						text-transform: none;
					}

					a{
						@include font-size(18);
						@include line-height(18,26);
						font-weight: 600;
						text-decoration: none;
						color: $blue-light;

						&:hover,
						&:focus-visible{
							text-decoration: underline;
							color: $blue-dark;
						}

					}
				}
			}
		}
	}
}

.current-closures-and-delays-section{
	padding-bottom: 6rem;

	@media (max-width: $md){
		padding-bottom: 4rem;
	}

	.container{
		background-color: $blue-light;
		max-width: 121.2rem;
		padding: 5.8rem 7rem 6.8rem;

		@media (max-width: $md){
			max-width: calc(100dvw - 6.2rem);
			padding: 2.3rem 2rem 2.2rem;
		}
	}

	.row{
		align-items: center;
	}

	.top-text{
		margin-bottom: 2rem;

		@media (max-width: $md){
			margin-bottom: 1.6rem;
		}

		*{
			color: $white;
		}
	}

	.table-con.table-con.table-con.table-con{

		table{
			border: none;
			margin-bottom: 2.1rem;

			@media (max-width: $md){
				display: block;
				margin-bottom: 1.3rem;
			}
	
			tr{
				border: none;

				@media (max-width: $md){
					display: block;
					margin-bottom: 2.2rem;

					&:last-of-type{
						margin-bottom: 0;
					}
				}
	
				td{
					padding: 0 0;
					padding-right: .57rem;
					border: none;

					@media (max-width: $md){
						display: block;
						padding: 0;
					}
	
					&:last-of-type{
						padding-right: 0;
					}
	
					&:nth-of-type(1){
						width: 39%;

						@media (max-width: $md){
							width: 100%;
						}
					}
	
					*{
						text-transform: none;
						color: $white;
					}

					p.medium{
						@include line-height(18,23);

						@media (max-width: $md){
							@include line-height(16,22);
						}
					}
				}
			}
		}
	}
	
	.under-text{
		
		a{
			display: inline-flex;
			align-items: center;

			&:hover,
			&:focus-visible{
				color: $blue-dark;
				text-decoration: underline;

				&:after{
					margin-left: .8rem;
				}
			}

			&:after{
				content: '';
				margin-left: .6rem;
				aspect-ratio: 5.976/9.706;
				background-size: 100% auto;
				width: .5976rem;
				background-image: url(../images/link-arrow-simple-blue.svg);
				background-repeat: no-repeat;
			}
		}
	}

	h2{
		margin-bottom: 1.05em;

		@media (max-width: $md){
			@include font-size(20);
			@include line-height(20,25);
			margin-bottom: 1.35em;
		}
	}

	p{

		strong{
			font-weight: 600;
		}

		@media (max-width: $md){
			
			&.medium{
				@include line-height(16,22);
			}
		}
	}
}

.recent-natural-disaster-resources-section{
	background-color: $blue-dark!important;

	.container{
		max-width: 123rem;
	}

	.row{
		padding-top: 8.3rem;
		padding-bottom: 1.8rem;

		@media (max-width: $md){
			padding-top: 3rem;
			padding-bottom: 3.5rem;
		}
	}

	*{
		color: $white;
	}

	.text-top{
		text-align: center;
		margin-bottom: 7.8rem;

		@media (max-width: $md){
			text-align: left;
			margin-bottom: 3rem;

			h2{
				@include font-size(30);
				@include line-height(30,35);
			}
		}
	}

	.sections{
		margin-bottom: 6.2rem;

		@media (max-width: $md){
			margin-bottom: 4.1rem;
			
			&:last-of-type{
				margin-bottom: 0;
			}
		}

		h3{
			margin-bottom: 1.15em;

			@media (max-width: $md){
				@include font-size(20);
				@include line-height(20,25);
				margin-bottom: .75em;
			}
		}
		
		.link-con{
			border-bottom: 1px solid rgba($white,.4);
			padding: 1.26rem 0;

			

			.link{
				text-decoration: none;
				@include font-size(20);
				@include line-height(20,30);
				font-weight: 500;

				@media (max-width: $md){
					@include font-size(16);
					@include line-height(16,22);
					text-decoration: underline;
				}

				&:hover,
				&:focus-visible{
					color: $blue-light;
				}
			}
		}

		.highlighted{
			background-color: $blue-light;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
			padding: 1.8rem 2.8rem;
			text-align: center;
			margin-top: 5.2rem;
			margin-bottom: 4rem;

			@media (max-width: $md){
				margin-top: 2.5rem;
				margin-bottom: 1.5rem;
				padding: 1.9rem 1.3rem 1rem;
			}

			.inner{
				margin: 0 1.75rem .5rem;

				@media (max-width: $md){
					margin: 0 0 1rem;
					width: 100%;
				}
			}

			h4{
				font-weight: 600;
				margin-bottom: .4rem;

				@media (max-width: $md){
					@include font-size(18);
					@include line-height(18,26);
					margin-bottom: .1rem;
				}
			}

			a{
				@include font-size(25);
				@include line-height(25,35);
				font-weight: 600;
				text-decoration: none;
				display: flex;
				align-items: center;
				justify-content: center;

				@media (max-width: $md){
					@include font-size(18);
					@include line-height(18,26);
				}

				&:hover,
				&:focus-visible{
					
					i{

						svg{

							*{
								fill: $blue-dark;
							}
						}
					}
				}

				i{
					margin-right: 1.7rem;

					svg{
						width: 100%;

						*{
							fill: $white;
						}
					}
				}

				&.tel{
					@include font-size(30);
					@include line-height(30,35);

					@media (max-width: $md){
						@include font-size(18);
						@include line-height(18,26);
					}

					i{
						aspect-ratio: 22.65/22.54;
						width: 2.265rem;

						@media (max-width: $md){
							width: 1.377rem;
							margin-right: 1rem;
						}
					}
				}

				&.mail{

					i{
						aspect-ratio: 22.29/16.1;
						width: 2.229rem;
						margin-right: .8rem;

						@media (max-width: $md){
							width: 1.612rem;
							margin-right: .7rem;
						}
					}
				}
			}
		}
	}
}

.important-notice-section{
	padding-top: 6rem;
	padding-bottom: 6rem;

	@media (max-width: $md){
		padding-top: 4rem;
		padding-bottom: 4rem;
	}

	.container{
		background-color: $red;
		max-width: 121.2rem;
		padding: 5.2rem 6.9rem 6.2rem;

		@media (max-width: $md){
			max-width: calc(100dvw - 6.2rem);
			padding: 2.7rem 2rem 2.2rem;
		}
	}

	*{
		color: $white;
	}

	h2{
		display: flex;
		align-items: center;
		margin-bottom: 1em;

		@media (max-width: $md){
			@include font-size(20);
			@include line-height(20,25);
			margin-bottom: 1.4em;
		}

		svg{
			margin-right: 2rem;
			aspect-ratio: 35/32.408;
			width: 3.5rem;

			@media (max-width: $md){
				margin-right: 1.5rem;
				width: 2.7rem;
			}
		}
	}

	p{

		&.medium{
			@include line-height(18,21);

			@media (max-width: $md){
				@include line-height(16,22);
			}
		}

		&.large{

			@media (max-width: $md){
				@include line-height(16,22);
			}
		}

		strong{
			font-weight: 600;
		}
	}

	h3{
		margin-bottom: 1.15em;

		@media (max-width: $md){
			@include font-size(20);
			@include line-height(20,22);
			margin-bottom: 1.15em;
		}
	}

	.text-top{
		margin-bottom: 4.4rem;

		@media (min-width: $md + 1){
			padding-right: 5rem;
		}

		@media (max-width: $md){
			margin-bottom: 2rem;
		}
	}

	.text-left{

		@media (min-width: $md + 1){
			flex: 0 0 47.2%;
			max-width: 47.2%;
			padding-right: 6rem;
		}

		@media (max-width: $md){
			margin-bottom: 1.5rem;
		}
	}

	.text-right{

		@media (min-width: $md + 1){
			flex: 0 0 52.8%;
			max-width: 52.8%;
		}
	}
}

.personal-insurance-section{

	.container{
		max-width: 130.8rem;
	}

	.row{
		padding-top: 9.9rem;
		padding-bottom: 9.9rem;

		@media (max-width: $md){
			padding-top: 3.9rem;
			padding-bottom: 3.9rem;
		}
	}

	.title-side{
		
		@media (min-width: $md + 1){
			flex: 0 0 50%;
			max-width: 50%;
			padding-top: .3rem;
			padding-left: 5rem;
			padding-right: 19rem;
		}

		@media (max-width: $md){
			margin-bottom: 2.5rem;
		}
	}

	.text-side{
		margin-bottom: 14.3rem;
		
		@media (min-width: $md + 1){
			flex: 0 0 50%;
			max-width: 50%;
			padding-right: 13rem;
		}

		@media (max-width: $md){
			margin-bottom: 5rem;
		}
	}

	.b-item{

		@media (max-width: $md){
			
			.bottom{
				display: flex;
				flex-direction: column;

				.over-text{
					order: 2;
				}

				.img-con{
					order: 1;
				}

				svg{
					order: 3;
				}

			}
		}
	}
}

.employee-benefits-section{

	.container{
		max-width: 130.8rem;
	}

	.row{
		padding-top: 5.7rem;
		padding-bottom: 10rem;

		@media (max-width: $md){
			padding-top: 1.9rem;
			padding-bottom: 5rem;
		}

		.b-item{

			.bottom{

				@media (max-width: $md){
					display: flex;
					flex-direction: column;
	
					.over-text{
						order: 2;
					}
	
					.img-con{
						order: 1;
					}
	
					svg{
						order: 3
					}
				}
			}
			
		}
	}
}

.business-insurance-section{

	&:has(.b-item:first-of-type:last-of-type){

		.facetwp-type-search{
			display: none;
		}
	}

	.container{
		max-width: 130.8rem;
	}

	.row{
		padding-top: 9.9rem;
		padding-bottom: 6.1rem;

		@media (max-width: $md){
			padding-top: 5.1rem;
			padding-bottom: 4.8rem;
		}
	}

	.title-side{
		
		@media (min-width: $md + 1){
			flex: 0 0 50%;
			max-width: 50%;
			padding-top: .3rem;
			padding-left: 5rem;
		}

		@media (max-width: $md){
			margin-bottom: 2.4rem;
		}
	}

	.text-side{
		
		@media (min-width: $md + 1){
			flex: 0 0 50%;
			max-width: 50%;
			padding-right: 13rem;
		}
	}

	.filter-top{
		margin-top: 10.7rem;
		display: flex;
		flex-wrap: wrap;

		.facetwp-type-radio{
			display: flex;
			width: 100%;
			margin-bottom: 6rem;

			@media (max-width: $md){
				margin-bottom: 1.1rem;
				flex-wrap: wrap;
			}

			.facetwp-counter{
				display: none;
			}

			.facetwp-radio{
				background-image: none;
				padding: 3.2rem 1rem;
				text-align: center;
				border-bottom: 1px solid #D3D3D3;
				position: relative;
				@include font-size(25);
				font-weight: 600;
				margin-bottom: 0;
				flex: 1 1;

				@media (max-width: $md){
					padding: 1rem 1rem;
					border: none;
					flex: 1 1 100%;
				}

				&[data-value=""]{
					display: none;
				}

				&:after{
					content: '';
					position: absolute;
					bottom: -1.5px;
					left: 0;
					right: 0;
					height: 2px;
					background-color: $blue-light;
					opacity: 0;
					visibility: hidden;

					// @media (max-width: $md){
					// 	display: none;
					// }
				}

				&.checked{
					color: $blue-light;

					@media (max-width: $md){
						//display: block;
					}
					
					&:after{
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}

		.facetwp-counts{
			align-self: center;
			width: 25.4%;
			@include line-height(16,40);

			@media (max-width: $md){
				width: 100%;
			}
		}

		.facetwp-type-autocomplete,
		.facetwp-type-search{
			width: 74.6%;

			@media (max-width: $md){
				width: 100%;
			}

			.fcomplete-wrap{
				
				@media (min-width: $md + 1){
					min-width: 73.7% !important;
					width: 73.7%;
				}
			}

			.facetwp-autocomplete{
				background-color: $blue-lighter;
				border: .1rem solid $blue-lighter;
				color: $blue-light;
				padding: .8rem 5.5rem .8rem 2.5rem;
				background-image: url(../images/search-blue.svg);
				background-size: 2.01rem auto;
				background-position: top 50% right 1rem;
				background-repeat: no-repeat;

				&:focus-visible{
					border-bottom-color: transparent!important;
				}
			}

			.facetwp-autocomplete-update{
				display: none;
			}

			.fcomplete-results{
				background-color: $blue-lighter;
				border: .1rem solid $blue-lighter;
				padding: .8rem 5.5rem .8rem 2.5rem;
				
				&:before{
					content: '';
					top: 0;
					position: absolute;
					left: 2.5rem;
					right: 5.5rem;
					height: 1px;
					background-color: $blue-light;
				}
			}

			.fcomplete-result{
				padding: .5rem 0;

				&:hover,
				&:focus-visible{
					background-color: transparent;
					color: $blue-light;
					transition: $time;
				}
			}
			

			.facetwp-input-wrap{
				width: 100%;

				input{
					background-color: $blue-lighter;
					border: .1rem solid $blue-lighter;
					color: $blue-light;
					padding: .8rem 5.5rem .8rem 2.5rem;
				}

				.facetwp-icon{
					width: 5rem;
					opacity: 1;

					&:before{
						background-image: url(../images/search-blue.svg);
						background-repeat: no-repeat;
						background-size: 2.01rem auto;
						aspect-ratio: 20.1/18.5;
					}
				}
			}
		}
	}

	.facetwp-template{
		margin-top: 5.7rem;
		display: flex;
		flex-wrap: wrap;
		width: 100%;

		@media (max-width: $md){
			margin-top: 2.7rem;
		}
	}
}

.industries-section{

	// &:has(.b-item:first-of-type:last-of-type){

	// 	.facetwp-type-search{
	// 		display: none;
	// 	}
	// }

	.container{
		max-width: 130.8rem;
	}

	.row{
		padding-top: 9.9rem;
		padding-bottom: 6.1rem;

		@media (max-width: $md){
			padding-top: 3.9rem;
			padding-bottom: 4rem;
		}
	}

	.title-side{
		
		@media (min-width: $md + 1){
			flex: 0 0 50%;
			max-width: 50%;
			padding-top: .3rem;
			padding-left: 5rem;
		}

		@media (max-width: $md){
			margin-bottom: 2.6rem;
		}
	}

	.text-side{
		
		@media (min-width: $md + 1){
			flex: 0 0 50%;
			max-width: 50%;
			padding-right: 13rem;
		}
	}

	.filter-top{
		margin-top: 10.7rem;
		display: flex;
		flex-wrap: wrap;

		@media (max-width: $md){
			margin-top: 6.3rem;
		}

		.facetwp-counts{
			align-self: center;
			width: 25.4%;
			@include line-height(16,40);

			@media (max-width: $md){
				width: 100%;
			}
		}

		// .facetwp-type-search{
		// 	width: 74.6%;

		// 	@media (max-width: $md){
		// 		width: 100%;
		// 	}

		// 	.facetwp-input-wrap{
		// 		width: 100%;

		// 		input{
		// 			background-color: $blue-lighter;
		// 			border: .1rem solid $blue-lighter;
		// 			color: $blue-light;
		// 			padding: .8rem 5.5rem .8rem 2.5rem;
		// 		}

		// 		.facetwp-icon{
		// 			width: 5rem;
		// 			opacity: 1;

		// 			&:before{
		// 				background-image: url(../images/search-blue.svg);
		// 				background-repeat: no-repeat;
		// 				background-size: 2.01rem auto;
		// 				aspect-ratio: 20.1/18.5;
		// 			}
		// 		}
		// 	}
		// }

		.facetwp-type-autocomplete,
		.facetwp-type-search{
			width: 74.6%;

			@media (max-width: $md){
				width: 100%;
			}

			.fcomplete-wrap{
				
				@media (min-width: $md + 1){
					min-width: 73.7% !important;
					width: 73.7%;
				}
			}

			.facetwp-autocomplete{
				background-color: $blue-lighter;
				border: .1rem solid $blue-lighter;
				color: $blue-light;
				padding: .8rem 5.5rem .8rem 2.5rem;
				background-image: url(../images/search-blue.svg);
				background-size: 2.01rem auto;
				background-position: top 50% right 1rem;
				background-repeat: no-repeat;

				&:focus-visible{
					border-bottom-color: transparent!important;
				}
			}

			.facetwp-autocomplete-update{
				display: none;
			}

			.fcomplete-results{
				background-color: $blue-lighter;
				border: .1rem solid $blue-lighter;
				padding: .8rem 5.5rem .8rem 2.5rem;
				
				&:before{
					content: '';
					top: 0;
					position: absolute;
					left: 2.5rem;
					right: 5.5rem;
					height: 1px;
					background-color: $blue-light;
				}
			}

			.fcomplete-result{
				padding: .5rem 0;

				&:hover,
				&:focus-visible{
					background-color: transparent;
					color: $blue-light;
					transition: $time;
				}
			}
			

			.facetwp-input-wrap{
				width: 100%;

				input{
					background-color: $blue-lighter;
					border: .1rem solid $blue-lighter;
					color: $blue-light;
					padding: .8rem 5.5rem .8rem 2.5rem;
				}

				.facetwp-icon{
					width: 5rem;
					opacity: 1;

					&:before{
						background-image: url(../images/search-blue.svg);
						background-repeat: no-repeat;
						background-size: 2.01rem auto;
						aspect-ratio: 20.1/18.5;
					}
				}
			}
		}
	}

	.facetwp-template{
		margin-top: 5.7rem;
		display: flex;
		flex-wrap: wrap;
		width: 100%;

		@media (max-width: $md){
			margin-top: 2.7rem;
		}
	}
}

.b-item{
	margin-bottom: 2.9rem;
	
	@media (max-width: $md){
		margin-bottom: 0;
	}

	.inner{

		@media (max-width: $md){
			padding-bottom: 0rem;
			border-bottom: 1px solid rgba($blue-light,.4);
		}

		a.over{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 4;

			@media (max-width: $md){
				display: none;
			}

			&:hover,
			&:focus-visible{
				
				~ .bottom{

					.over-text{
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}
		
		.top{
			padding: 1.4rem 0;

			@media (max-width: $md){
				padding: 1.4rem 1.4rem 1.4rem 0;
				position: relative;
				cursor: pointer;

				&.open{

					+ .bottom{
						max-height: 999rem;
					}

					&::after{
						transform: translateX(-50%) rotateX(180deg);
					}
				}

				&::after{
					content: '';
					position: absolute;
					top: 50%;
					right: 0;
					aspect-ratio: 12.905/7.513;
					width: 1.2905rem;
					background-image: url(../images/acc-arrow.svg);
					background-repeat: no-repeat;
					background-size: 100% auto;
					transform: translateX(-50%) rotateX(0deg);
					backface-visibility: visible!important;
					transition: $time;
				}
			}

			h4{
				@include font-size(22);
				font-weight: 600;

				@media (max-width: $md){
					@include font-size(20);
				}
			}
		}

		.bottom{
			width: 100%;
			position: relative;

			@media (min-width: $md + 1){
				aspect-ratio: 311/221.79;
			}

			@media (max-width: $md){
				max-height: 0;
				overflow: hidden;
				transition: $time;
			}

			a.over-bottom{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 4;

				@media (min-width: $md + 1){
					display: none;
				}
			}

			.over-text{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				background-color: $blue-dark;
				z-index: 2;
				padding: 3rem 2.3rem 5rem;
				opacity: 0;
				visibility: hidden;
				transition: $time;

				@media (max-width: $md){
					background-color: transparent;
					position: static;
					opacity: 1;
					visibility: visible;
					padding: 0 0 .9rem;

					svg{
						display: none;
					}
				}
				
				*{

					@media (min-width: $md + 1){
						color: white;
						fill: $white;
					}
				}

				@media (min-width: $md + 1){
					aspect-ratio: 311/221.79;

					svg{
						aspect-ratio: 33.28/12.91;
						width: 3.328rem;
						max-width: 3.328rem;
						min-width: 3.328rem;
						height: 1.291rem;
						position: absolute;
						bottom: 2.4rem;
						left: 2.3rem;
					}
				}
			}

			.img-con{
				aspect-ratio: 311/221.79;
				object-fit: cover;
				object-position: 50% 50%;

				@media (max-width: $md){
					margin-bottom: 1.7rem;
				}

				+ svg{
					display: none;
					aspect-ratio: 33.28/12.91;
					width: 3.328rem;
					max-width: 3.328rem;
					min-width: 3.328rem;
					height: 1.291rem;
					margin-bottom: 2rem;

					@media (max-width: $md){
						display: block;
					}

					*{
						fill: $blue-light;
					}
				}
			}
		}
	}
}

.text-with-accordion-section{

	.container{
		max-width: 122.8rem;
	}

	.row{
		padding-top: 11.1rem;
		padding-bottom: 10rem;

		@media (max-width: $md){
			padding-top: 4.1rem;
			padding-bottom: 4.9rem;
		}
	}

	.text-side{

		@media (max-width: $md){
			margin-bottom: 1.2rem;

		}

		@media (min-width: $md + 1){
			padding-right: 6rem;

			p{
				width: 49rem;
			}
		}

		img{
			width: 100%;
			margin-top: 8.3rem;

			@media (min-width: $md + 1){
				width: 51.2rem;
			}

			@media (max-width: $md){
				margin-top: 6.4rem;
			}
		}
	}

	.accordion-side{

		@media (min-width: $md + 1){
			padding-top: 11rem;
		}

		dl.accordion{

			dt{
				padding: 1.7rem 11rem 1.7rem 0;

				@media (max-width: $md){
					padding: 1.1rem 2rem 1.1rem 0;
				}

				&.open{

					+ dd{
						margin-top: -.6rem;

						@media (max-width: $md){
							margin-top: .2rem;
						}
					}
				}

				&:first-of-type{
					border-top: none;
				}

				h3{
					color: $blue;
					font-weight: 600;
					margin-bottom: 0;
				}

				i.arrow{
					width: 2.5789rem;
					right: 0;

					@media (max-width: $md){
						width: 1.2789rem;
						right: .3rem;					
					}

					svg{

						*{
							fill: $blue-dark;
						}
					}

				}
			}

			dd{

				.inner.inner.inner{
					padding: 0rem 2rem 1.5rem 0rem;

					@media (max-width: $md){
						padding: 0rem 0rem 2.9rem 0rem;
					}
				}
			}
		}
	}
}

.ioas-employee-benefits-products-section,
.our-personal-insurance-products-section{

	.container{
		max-width: 123.6rem;
	}

	.row{
		padding-top: 10.2rem;
		padding-bottom: 16.4rem;

		@media (max-width: $md){
			padding-top: 5.2rem;
			padding-bottom: 9rem;
		}
	}

	.title-con{
		margin-bottom: 6.4rem;

		@media (max-width: $md){
			margin-bottom: 3rem;
		}
	}

	.personal-slide-con{

		.swiper-wrapper-con{
			overflow: visible!important;
		}

		.latest-slide{
			width: calc(100% + 1.25rem + 1.25rem);
			max-width: calc(100% + 1.25rem + 1.25rem);
			margin-left: -1.25rem;
		}

		.swiper-slide{
			padding: 0 1.25rem;
			margin-bottom: .8rem;

			.inner{

				@media (max-width: $md){
					border-bottom: none;
				}
			}

			.top{

				@media (max-width: $md){

					h4{
						min-height: 0!important;
					}
					
					
					&:after{
						display: none;
					}
				}
			}

			.bottom{

				@media (max-width: $md){
					max-height: 99999rem;
					display: flex;
					flex-direction: column;

					.over-text{
						order: 2;
					}

					.img-con{
						order: 1;
					}

					svg{
						order: 3
					}
				}
				

			}

			h4{
				@include font-size(25);
				@include line-height(25,30);

				@media (max-width: $md){
					@include font-size(22);
					@include line-height(22,30);
				}
			}

			.text{

				.date{
	
					span{
	
						&:has(+ span){
	
							&:after{
								content: '|';
								margin: 0 .3em;
							}
						}
					}
				}
			}
		}
	}
}

.ioas-employee-benefits-products-section{
	background-color: $blue-lighter!important;
}

.number-points-section{

	.container{
		max-width: 122.6rem;
	}

	.row{
		padding-top: 10.2rem;
		padding-bottom: 10.1rem;

		@media (max-width: $md){
			padding-top: 5rem;
			padding-bottom: 4.4rem;
		}
	}

	.text-con{
		margin-bottom: 9.1rem;

		@media (max-width: $md){
			margin-bottom: 3.6rem;
		}
	}

	.point{
		margin-bottom: 2rem;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		position: relative;
		padding-bottom: 2.2rem;
		margin-bottom: 4.8rem;

		@media (max-width: $md){
			margin-bottom: 2.1rem;
			padding-bottom: 3rem;
		}

		&:after{
			content: '';
			position: absolute;
			bottom: -1px;
			left: .8rem;
			right: .8rem;
			height: 1px;
			background-color: #D3D3D3;
		}
	}

	.number-side{
		width: 5.7%;
		margin-bottom: 2.9rem;

		@media (max-width: $md){
			width: 5.3rem;
		}

		.number{
			@include font-size(100);
			line-height: .8;
			font-weight: 600;
			color: $red;

			@media (max-width: $md){
				@include font-size(70);
			}
		}
	}

	.title-side{
		width: 20.3%;

		@media (max-width: $md){
			width: calc(100% - 5.3rem);
		}

		*{
			color: $red;
		}

		h3{
			@include font-size(35);
			@include line-height(35,40);
			font-weight: 600;

			@media (max-width: $md){
				@include font-size(25);
				@include line-height(25,30);
				width: 15rem;
			}
		}
	}

	.text-side{
		width: 74%;
		padding-top: .4rem;

		@media (max-width: $md){
			width: 100%;
		}

		&:has(ul){
			display: flex;
			flex-wrap: wrap;

			p{
				width: 39%;

				@media (max-width: $md){
					width: 100%;

					&.large{
						margin-bottom: 2em;
					}
				}

				@media (min-width: $md + 1){
					padding-right: 5rem;
				}
			}

			ul{
				width: 61%;

				@media (max-width: $md){
					width: 100%;
				}

				li{
					padding-left: 1.1em;
				}
			}
		}
	}

	p{
		font-weight: 500;

		&.large{
			@include line-height(20,24);
		}
	}

	ul li{
		font-weight: 500;
		margin-bottom: .15em;

		&.medium{
			@include line-height(18,21);
		}

		strong{
			font-weight: 600;
		}
	}

	*{

		strong{
			font-weight: 600;
		}
	}
	
	.under{
		margin-top: .3rem;
	}

	.btn-con{
		margin-top: .2rem;

		@media (max-width: $md){
			margin-top: 2.6rem;
			justify-content: flex-start;
		}
	}
}

.riskscore-results-section{
	background-color: $red!important;
	position: relative;

	&:after{
		content: '';
		position: absolute;
		top: 3.7rem;
		right: -20.3%;
		width: 49.953%;
		width: 69.9333rem;
		background-image: url(../images/dots-8.svg);
		background-repeat: no-repeat;
		aspect-ratio: 700/182;
		background-size: 100% auto;

		@media (max-width: $md){
			display: none;
		}
	}

	.container{
		max-width: 130.8rem;
	}

	.row{
		padding-top: 10.3rem;
		padding-bottom: 10rem;

		@media (max-width: $md){
			padding-top: 4.6rem;
			padding-bottom: 3.8rem;
		}
	}

	*{
		color: $white;
		text-align: center;
	}

	h2{
		margin-bottom: .68em;

		@media (max-width: $md){
			margin-bottom: 1em;
		}
	}

	.text-con{
		margin-bottom: 6.4rem;

		@media (max-width: $md){
			margin-bottom: 3.9rem;
		}
	}

	.col-md-3{
		margin-bottom: 1.6rem;
		display: flex;

		@media (max-width: $md){
			margin-bottom: 2.3rem;
		}

		.inner{
			background-color: $red-dark;
			border-radius: .3rem;
			padding: 4rem 2rem 3.1rem;
			width: 100%;
		}

		.title{
			background-color: $blue-dark;
			text-transform: uppercase;
			padding: .2rem 1.2rem;
			border-radius: .3rem;
			display: inline-block;
			margin: 0 auto 3.9rem;
		}

		h3 + p{
			margin-bottom: .3em;
		}

		p{
			margin-bottom: 1.45em;

			&.medium{

				@media (max-width: $md){
					@include font-size(18);
					line-height: 1.44444;
				}
			}
		}

		h3{
			margin-bottom: -.11em;

			@media (max-width: $md){
				@include font-size(30);
				@include line-height(30,35);
			}
		}
	}

	.btn-con{
		margin-top: 5.8rem;

		@media (max-width: $md){
			margin-top: 1.8rem;
		}
	}
}

.riskscore-form-section{
	position: relative;

	&:after{
		content: '';
		position: absolute;
		top: -.8rem;
		left: -24%;
		width: 49.953%;
		width: 69.9333rem;
		background-image: url(../images/dots3.svg);
		background-repeat: no-repeat;
		aspect-ratio: 700/182;
		background-size: 100% auto;

		@media (max-width: $md){
			display: none;
		}
	}

	.container{
		max-width: 122.8rem;
	}

	.row{
		padding-top: 10.2rem;
		padding-bottom: 10rem;

		@media (max-width: $md){
			padding-top: 4rem;
			padding-bottom: 4rem;
		}
	}

	.text-con{
		text-align: center;
		margin-bottom: 7rem;

		@media (max-width: $md){
			text-align: left;
			margin-bottom: 4.9rem;
		}

		.text{
			width: 51rem;
			margin-left: auto;
			margin-right: auto;
		}
	}

	h2{
		margin-bottom: .73em;

		@media (max-width: $md){
			margin-bottom: 1.03em;
		}
	}

	p{

		&.medium{
			@include line-height(18,21);
		}
	}

	.form-con{

		@media (min-width: $md + 1){
			column-count: 2;
			column-gap: 1.6rem;
		}

		.gform_confirmation_message.gform_confirmation_message.gform_confirmation_message.gform_confirmation_message{
			text-align: center;
			column-span: all;
		}

		.gfield{
			break-inside: avoid;
			margin-bottom: 1.9rem;
		}

		legend,
		label{
			@include font-size(19);
			margin-bottom: 1.3rem;
		}

		.gform_footer{
			display: flex;
			flex-direction: row-reverse;
			margin-top: .9rem;

			@media (max-width: $md){
				margin-top: 1.9rem;
				flex-direction: row;
			}

			[type="submit"]{
				border-color: $blue-dark;
				background-color: $blue-dark;

				&:hover,
				&:focus-visible{
					border-color: $blue;
					background-color: $blue;
				}
			}
		}
	}
}

.tabs-points-section{
	background-color: $blue-lighter!important;

	.container{
		max-width: 130.8rem;
	}

	.row{
		padding-top: 10.2rem;
		padding-bottom: 3.9rem;

		@media (max-width: $md){
			padding-top: 5.2rem;
		}
	}

	.text-con{
		text-align: center;
		margin-bottom: 3rem;

		.text{
			width: 70rem;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.tab-container{

		.tabs{
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			position: static;

			.tab{
				background-image: none;
				padding: 3.2rem 1rem;
				text-align: center;
				border-bottom: 1px solid #D3D3D3;
				position: relative;
				@include font-size(25);
				font-weight: 600;
				margin-bottom: 0;
				flex: 1 1;

				@media (max-width: $md){
					display: none;
				}

				&[data-value=""]{
					display: none;
				}

				&:hover,
				&:focus-visible{

					span{
						color: $blue-light;
					}
				}

				span{
					transition: $time;
				}

				&:after{
					content: '';
					position: absolute;
					bottom: -1.5px;
					left: 0;
					right: 0;
					height: 2px;
					background-color: $blue-light;
					opacity: 0;
					visibility: hidden;
				}

				&.active{
					color: $blue-light;
					
					&:after{
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}

		.tab-header{

			@media (max-width: $md){
				@include font-size(22);

				&.active{
					color: $blue-light;

					&:after{
						background-image: url(../images/select-icon.svg);
					}
				}
			}
		}

		.tab-contents{
			width: 100%;
			padding-left: 0;

			.row{
				padding-top: 0;
				padding-bottom: 0;
			}

			.inner{
				padding-top: 5.8rem;

				@media (max-width: $md){
					padding-top: .9rem;
					padding-bottom: 0;
					
				}
			}

			.tab-content{

				@media (max-width: $md){
					border-bottom: 1px solid rgba($blue-light, .4);
				}
			}
		}

		.point{
			margin-bottom: 4.2rem;

			@media (max-width: $md){
				margin-bottom: 1.9rem;
			}

			.inner-inner{
				display: flex;
				align-items: center;

				@media (max-width: $md){
					
				}
			}

			.icon-side{
				width: 8rem;
				margin-right: 2.9rem;

				@media (max-width: $md){
					width: 6rem;
					margin-right: 2rem;
				}

				img{
					width: 100%;
				}
			}

			.title-side{
				width: calc(100% - 8rem - 2.9rem);

				@media (max-width: $md){
					width: calc(100% - 6rem - 2rem);
				}

				@media (min-width: $md + 1){
					padding-right: 4rem;
				}

				h4{
					@include font-size(22);
					@include line-height(22,27);
					font-weight: 400;

					@media (max-width: $md){
						@include font-size(20);
						@include line-height(20,25);
					}
				}
			}
		}
	}
}

.text-blocks-section{

	.container{
		max-width: 123.8rem;
	}

	.row{
		padding-top: 10.2rem;
		padding-bottom: 10.1rem;

		@media (max-width: $md){
			padding-top: 4.3rem;
			padding-bottom: 5.5rem;
		}
	}

	.text-con{
		text-align: center;
		margin-bottom: 4rem;

		.text{
			width: 70rem;
			margin-left: auto;
			margin-right: auto;
		}
	}

	h2{
		margin-bottom: .65em;

		@media (max-width: $md){
			margin-bottom: 1em;
		}
	}

	.blocks-con{

		@media (min-width: $md + 1){
			column-count: 3;
			column-gap: 8.8rem;
		}

		.blocks{
			margin-bottom: 4rem;
			break-inside: avoid;

			@media (max-width: $md){
				margin-bottom: 0rem;
			}

			.top{
				padding: 1rem 3.5rem 1rem 0;
				position: relative;
				
				@media (max-width: $md){
					cursor: pointer;
				}

				&.open{
		
					i.arrow{
						transform: rotateX(180deg) translateY(-50%);
					}

					+ .bottom{
						max-height: 999rem;
					}
				}
		
				i.arrow{
					position: absolute;
					top: 50%;
					transform: rotateX(0deg) translateY(-50%);
					transform-origin: 50% 0%;
					right: 0rem;
					aspect-ratio: 20.789/11.455;
					width: 2.0789rem;
					backface-visibility: visible!important;
					transition: $time;

					@media (max-width: $md){
						width: 1.3789rem;
						right: .2rem;
					}

					@media (min-width: $md + 1){
						display: none;
					}

					svg{
						width: 100%;

						*{
							fill: $blue-dark;
						}
					}
				}

				h4{
					font-weight: 600;
					margin-bottom: 0;

					@media (max-width: $md){
						@include font-size(20);
						@include line-height(20,25);
					}
				}
			}

			.bottom{
				border-bottom: 1px solid $red;

				@media (max-width: $md){
					max-height: 0;
					overflow: hidden;		
				}

				.inner{
					padding: 1.5rem 0 4.2rem;

					@media (max-width: $md){
						padding: .5rem 0 2.7rem;
					}

					p{

						&.medium{
							@include line-height(18,21);
						}

					}
				}
			}
		}
	}
}

.broker-loop-section{

	.container{
		max-width: 124.7rem;
	}

	.row{
		padding-top: 23.4rem;
		padding-bottom: 10rem;

		@media (max-width: $md){
			padding-top: 5.5rem;
		}
	}

	@media (min-width: $md + 1){
		
		.container,
		.col-12{
			padding-right: 3.5rem;
			padding-left: 3.5rem;
		}

		.row{
			margin-right: -3.5rem;
			margin-left: -3.5rem;
		}
	}

	.title-side{
		padding-top: .2rem;

		@media (min-width: $md + 1){
			flex: 0 0 47.9%;
			max-width: 47.9%;
		}

		@media (max-width: $md){
			text-align: center;
			margin-bottom: 2.3rem;
		}

		h2{

			@media (min-width: $md + 1){
				padding-left: .4rem;
			}

			@media (max-width: $md){
				@include font-size(40);
				@include line-height(40,45);
			}
		}
	}

	.text-side{
		margin-bottom: 8.5rem;

		@media (min-width: $md + 1){
			flex: 0 0 52.1%;
			max-width: 52.1%;
		}

		@media (max-width: $md){
			text-align: center;
			margin-bottom: 5.8rem;
		}

	}

	.facetwp-template{
		display: flex;
		flex-wrap: wrap;
		width: 100%;
	}
}

.br-item{
	margin-bottom: 6rem;

	@media (max-width: $md){
		margin-bottom: 3.9rem;
	}

	@media (min-width: $md + 1){
		flex: 0 0 20%;
		max-width: 20%;
	}

	.inner{
		width: 100%;
		text-align: center;
	}

	&:has(.a-over){

		.a-over{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;

			&:hover,
			&:focus-visible{
				
				~ .img-outer{

					.img-con{
						transform: scale(1.1);
					}
				}

				~ .profile-link{

					&:after{
						max-width: .5976rem;
					}
				}
			}
		}

		.img-outer{
			aspect-ratio: 1/1;
			overflow: hidden;
			width: 17.4rem;
			border-radius: 50%;
			aspect-ratio: 1/1;

			@media (max-width: $md){
				width: 14rem;
				margin: 0 auto 1.9rem;
			}

			.img-con{
				margin: 0 auto;
				transform: scale(1);
				transition: $time;
			}
		}
	}

	.img-con{
		aspect-ratio: 1/1;
		object-fit: cover;
		object-position: 50% 50%;
		width: 17.4rem;
		border-radius: 17.4rem;
		margin: 0 auto 2.3rem;

		@media (max-width: $md){
			width: 14rem;
			margin: 0 auto 1.9rem;
		}
	}

	h4{
		font-weight: 400;
		margin-bottom: .7em;

		@media (max-width: $md){
			@include font-size(20);
			@include line-height(20,25);
			font-weight: 500;
			margin-bottom: .1em;
		}

		a{
			text-decoration: none;

			&:hover,
			&:focus-visible{
				text-decoration: underline;
			}
		}
	}

	.location{
		margin-top: -1.7rem;
		margin-bottom: 2.1rem;

		@media (max-width: $md){
			//display: none;
			margin-top: .4rem;
			@include font-size(14);
			margin-bottom: 1.5rem;
		}
		
		span{
			color: $grey;

			&:has(+ span){

				&:after{
					content: ', ';
				}
			}
		}
	}

	.profile-link{
		font-weight: 600;
		color: $blue-light;

		@media (max-width: $md){
			@include font-size(14);
		}

		&:after{
			content: '';
			margin-left: .6rem;
			aspect-ratio: 5.976/9.706;
			background-size: .5976rem auto;
			width: .5976rem;
			background-image: url(../images/menu-arrow.svg);
			background-repeat: no-repeat;
			display: inline-block;
			max-width: 0rem;
			transition: $time;
		}

		&:hover,
		&:focus-visible{
			text-decoration: none;
		}
	}

	.tel-link{
		display: flex;
		justify-content: center;
		text-decoration: none;
		align-items: center;
		font-weight: 500;
		color: $blue-light;
		margin-bottom: .45rem;

		@media (max-width: $md){
			margin-top: 1.4rem;
		}

		&:hover,
		&:focus-visible{
			text-decoration: underline;
		}

		i{
			aspect-ratio: 15.37/15.29;
			width: 1.537rem;
			margin-right: .7rem;

			svg{
				width: 100%;
				
				*{
					fill: $blue-light;
				}
			}
		}
	}

	.email-link{
		text-decoration: none;
		font-weight: 500;
		@include font-size(14);

		@media (max-width: $md){
			@include font-size(11);
		}

		&:hover,
		&:focus-visible{
			text-decoration: underline;
		}
	}

	.btn-con{
		margin-top: 2.3rem;
		margin-bottom: 0;

		@media (max-width: $md){
			margin-top: 1.7rem;

		}

		.btn{
			width: 100%;

			&:hover,
			&:focus-visible{
				border-color: $blue;
				background-color: $blue;
			}
		}
	}
}

.broker-hero-section{
	background-color: $blue-lighter!important;
	overflow-x: clip!important;
	overflow-y: visible!important;

	&.header-two{
		background-color: $blue-light!important;
		position: relative;

		@media (min-width: $md + 1){
			
			&::before{
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 10.4rem;
				height: 100%;
				background-color: $red;
			}
		}

		.row{
			padding-top: 0;
			padding-bottom: 0;
		}

		blockquote{
			line-height: 1.25;
		}

		*{
			color: $white;
		}

		.title-side{
			padding-top: 8.4rem;
			padding-bottom: 7.8rem;

			@media (min-width: $md + 1){
				flex: 0 0 70.65%;
				max-width: 70.65%;
				padding-left: 15.4rem;
				padding-right: 10rem;
			}

			@media (max-width: $md){
				padding-top: 3.8rem;
				padding-bottom: 6.7rem;
				margin-bottom: 0;
				position: relative;

				&::before{
					content: '';
					position: absolute;
					bottom: 0;
					margin-left: -3.1rem;
					width: 100vw;
					max-width: 100vw;
					height: 2.8rem;
					background-color: $red;
				}
			}

			blockquote{

				@media (max-width: $md){
					@include font-size(25);
					@include line-height(25,30);
					margin-bottom: 1.1rem;

					~ .by{
						@include font-size(16);
					}
				}
			}
		
		}

		.img-side{
			padding: 0;

			@media (min-width: $md + 1){
				flex: 0 0 29.35%;
				max-width: 29.35%;
			}

			.img-con{
				height: 100%;
				width: 100%;

				@media (min-width: $md + 1){
					width: calc(100% + 4.6rem);
					max-width: calc(100% + 4.6rem);
				}
	
				@media (min-width: 1400px + 1){
					width: calc(100% + ((100vw - 130.8rem)/2));
					max-width: calc(100% + ((100vw - 130.8rem)/2));
				}

				@media (max-width: $md){
					aspect-ratio: 375/210;
					object-fit: cover;
					object-position: 50% 50%;
					margin-left: -2.7rem;
					width: 100vw;
					max-width: 100vw;
				}
			}

		}
	}

	&.red{
		background-color: $red!important;

		.title-side{

			*{
				color: $white;
			}
		}

		.info-side{

			*{
				color: $white;
			}

			.btn{

				&.line-blue-dark{
					border-color: $white;
					background-color: transparent;
					color: $white!important;
					
					i{

						svg{

							*{
								fill: $white!important;
							}
						}
					}

					&:hover,
					&:focus-visible{
						border-color: $blue;
						background-color: $blue;
						color: $white!important;
					}
				}
			}
		}
	}

	&.blue{
		background-color: $blue-light!important;

		.title-side{

			*{
				color: $white;
			}
		}

		.info-side{

			*{
				color: $white;
			}

			.btn{

				&.line-blue-dark{
					border-color: $white;
					background-color: transparent;
					color: $white!important;
					
					i{

						svg{

							*{
								fill: $white!important;
							}
						}
					}

					&:hover,
					&:focus-visible{
						border-color: $blue;
						background-color: $blue;
						color: $white!important;
					}
				}
			}
		}
	}

	&.blue-dark{
		background-color: $blue-dark!important;

		.title-side{

			*{
				color: $white;
			}
		}

		.info-side{

			*{
				color: $white;
			}

			.btn{

				&.line-blue-dark{
					border-color: $white;
					background-color: transparent;
					color: $white!important;
					
					i{

						svg{

							*{
								fill: $white!important;
							}
						}
					}

					&:hover,
					&:focus-visible{
						border-color: $blue;
						background-color: $blue;
						color: $white!important;
					}
				}

				&.blue-dark{
					border-color: $red;
					background-color: $red;
					color: $white!important;

					i{

						svg{

							*{
								fill: $white!important;
							}
						}
					}
				}
			}
		}
	}

	&.blue-gradient{
		background-image: url(../images/blue-gradient.jpg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;

		.title-side{

			*{
				color: $blue-dark;
			}
		}

		.info-side{

			*{
				color: $blue-dark;
			}

			.btn{

				&.line-blue-dark{
					border-color: $red;
					background-color: $red;
					color: $white!important;
					
					i{

						svg{

							*{
								fill: $white!important;
							}
						}
					}

					&:hover,
					&:focus-visible{
						border-color: $blue;
						background-color: $blue;
						color: $white!important;
					}
				}
			}
		}
	}

	&.blue-dark-gradient{
		background-image: url(../images/blue-dark-gradient.jpg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;

		.title-side{

			*{
				color: $white;
			}
		}

		.info-side{

			*{
				color: $white;
			}

			.btn{

				&.line-blue-dark{
					border-color: $red;
					background-color: $red;
					color: $white!important;
					
					i{

						svg{

							*{
								fill: $white!important;
							}
						}
					}

					&:hover,
					&:focus-visible{
						border-color: $blue;
						background-color: $blue;
						color: $white!important;
					}
				}

				&.blue-dark{
					border-color: $blue-light;
					background-color: $blue-light;
					color: $white!important;

					i{

						svg{

							*{
								fill: $white!important;
							}
						}
					}
				}
			}
		}
	}

	.container{
		max-width: 130.8rem;
	}

	.row{
		padding-top: 13.8rem;
		padding-bottom: 12.8rem;

		@media (max-width: $md){
			padding-top: 6.1rem;
			padding-bottom: 10.6rem;
		}
	}

	.title-side{

		@media (min-width: $md + 1){
			flex: 0 0 58.35%;
			max-width: 58.35%;
			margin-top: .3rem;
		}

		@media (max-width: $md){
			margin-bottom: 2.1rem;
		}

		h1{

			@media (max-width: $md){
				@include font-size(40);
			}

			em{
				font-style: normal;
				color: $red;
			}
		}

		blockquote{
			margin-top: 0;
			padding-left: 0;

			&:before{
				position: static;
				display: inline-block;
			}
		}
	}

	.info-side{

		@media (min-width: $md + 1){
			flex: 0 0 41.65%;
			max-width: 41.65%;
		}

		p{

			@media (min-width: $md + 1){
				padding-right: 12rem;
			}

			&.medium{
				@include line-height(18,21);
			}
		}
	}

	.btn-con{
		margin-top: 5.3rem;

		@media (max-width: $md){
			margin-top: 4.1rem;
		}

		.btn{

			&.tel{
				
				@media (max-width: $md){
					padding-left: 0.7rem;
					padding-right: 0.7rem;
					flex: 1;
				}
			}

			&.blue-dark{

				@media (max-width: $md){
					padding-left: .6rem;
					padding-right: .6rem;
				}

				&:hover,
				&:focus-visible{
					background-color: $blue;
					border-color: $blue;
					color: $white!important;

					svg{

						*{
							fill: $white!important;
						}
					}
				}
			}
		}
	}

	.img-con{

		.img{
			aspect-ratio: 1/1;
			object-fit: cover;
			object-position: 50% 50%;
			width: 20rem;
			margin-top: 2.3rem;
			margin-bottom: -22.3rem;

			@media (max-width: $md){
				margin: 3rem auto -18rem;
				width: 15rem;
			}
		}
	}
}

.broker-hero-section.header-two:has(+ .broker-section){

	@media (max-width: $md){
		display: none;
	}
}

.broker-hero-section.header-two + .broker-section{

	.row{
		padding-top: 6rem;

		@media (max-width: $md){
			padding-top: 1.3rem;
		}
	}

	.col-md-3.left{

		.broker-hero-section.header-two{
			margin-top: 5.3rem;
			margin-left: -3.1rem;
			width: 100vw;
			max-width: 100vw;

			.row{
				padding-top: 0;

				.title-side{
					margin-top: 0;
				}
			}

			@media (min-width: $md + 1){
				display: none;
			}

			*{
				color: $white;
			}
		}

		.img-con{
			aspect-ratio: 1/1;
			object-fit: cover;
			object-position: 50% 50%;
			border-radius: 50%;
			margin-bottom: 3.5rem;
			width: 20rem;
			margin-top: 1rem;

			@media (max-width: $md){
				width: 15.5rem;
				margin: 1rem auto 2.2rem;
			}

			.img{
				aspect-ratio: 1/1;
				width: 100%;
			}
		}

		.img-side{
			margin-top: 0;
			margin-left: -2.7rem;
			width: 100vw;
			max-width: 100vw;

			.img-con{
				margin: 0;
				border-radius: 0;
				aspect-ratio: 375/210;
				object-fit: cover;
				object-position: 50% 50%;
				width: 100vw;
				max-width: 100vw;
			}
		}
	}
}

.broker-section{

	.container{
		max-width: 130.8rem;
	}

	.row{
		padding-top: 13.2rem;
		padding-bottom: 9.8rem;

		@media (max-width: $md){
			padding-top: 10.8rem;
			padding-bottom: 4rem;
		}
	}

	.col-md-3{

		&.right{

			.info{
				padding: 1.7rem 1.7rem 2.5rem;
			}
			
		}

		h2{
			margin-bottom: .55em;

			@media (max-width: $md){
				text-align: center;
				@include font-size(35);
				margin-bottom: 0.35em;

				+ p {
					text-align: center;
				}
			}
		}

		p{

			&.medium{
				@include line-height(18,21);
			}
		}

		.role{

			@media (min-width: $md + 1){
				padding-right: 4rem;
			}
		}

		h4.title{
			color: $red;
			font-weight: 600;
			margin-top: .5em;

			+ .info{
				margin-top: 2.2rem;
			}
		}

		.btn-con{
			margin-top: 3.1rem;

			@media (max-width: $md){
				margin-top: 2.1rem;
				justify-content: center;
			}
		}
	}

	.review{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin-bottom: .2rem;

		@media (max-width: $md){
			justify-content: center;
		}

		span{
			@include font-size(20);
			@include line-height(20,30);
		}

		.starts{
			aspect-ratio: 129.101/19.992;
			width: 12.9101rem;
			background-size: 12.9101rem auto;
			background-image: url(../images/stars-back.svg);
			background-repeat: no-repeat;
			position: relative;
			margin-right: 1.3rem;

			div{
				aspect-ratio: 129.101/19.992;
				background-size: 12.9101rem auto;
				background-image: url(../images/stars-front.svg);
				background-repeat: no-repeat;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
	}

	.reviews-link{
		@include font-size(18);
		@include line-height(18,30);

		@media (max-width: $md){
			margin: 0 auto;
			display: block;
			text-align: center;
			@include font-size(16);
		}
	}
	
	.specialty{
		background-color: $blue-lighter;
		padding: 2.5rem 2.5rem;
		margin-top: 4rem;

		h4{
			font-weight: 600;
		}

		span{
			@include font-size(18);
			@include line-height(18,21);

			&:has(+ span){

				&:after{
					content: ', ';
				}
			}
		}
	}

	.info{
		background-color: $blue-lighter;
		padding: 2.5rem 2.5rem;
		margin-top: 4rem;

		&.mob-none{

			@media (max-width: $md){
				display: none;
			}
		}

		&.desk-none{

			@media (min-width: $md + 1){
				display: none;
			}
		}

		h4{
			font-weight: 600;
			margin-bottom: .55em;
		}

		.accomplishments{
			margin-bottom: 7.8rem;

			span{
				display: inline-block;
				@include font-size(14);
				padding: .7rem .7rem;
				background-color: $red;
				position: relative;
				color: $white;

				&:hover{
					
					div{
						opacity: 1;
						visibility: visible;
					}
				}

				div{
					position: absolute;
					top: calc(100% + 1.1rem);
					left: 0;
					@include font-size(15);
					padding: .9rem 1.3rem;
					background-color: $blue;
					border-radius: .1rem;
					max-width: none;
					white-space: nowrap;
					color: $white;
					pointer-events: none;
					opacity: 0;
					visibility: hidden;
					transition: $time;

					&:before{
						content: '';
						left: 1.5rem;
						bottom: 100%;
						width: 0;
						height: 0;
						border-style: solid;
						border-right: .5rem solid transparent;
						border-left: .5rem solid transparent;
						border-bottom: .9rem solid $blue;
						border-top: 0;
						position: absolute;
					}
				}

			}
		}

		*{

			&:last-child{
				margin-bottom: 0!important;
			}
		}

		.education,
		.license{
			margin-bottom: 2.7rem;

			p{

				&.medium{
					@include line-height(18,24);
				}
			}
		}

		.membership{

			div{
				border-bottom: 1px solid rgba($blue-light,.3);
				padding-bottom: .5rem;
				margin-bottom: .8rem;
				@include font-size(17);
				@include line-height(17,23);

				&:last-of-type{
					padding-bottom: 0;
					border-bottom: none;
				}
			}
		}

		.acf-map{
			width: 100%;
			height: 100%;
			border: none;
			margin: 0;
			aspect-ratio: 1/1;
			margin-bottom: 2.95rem;

			*{
				max-width: initial!important;
			}
		}

		.address.address{
			@include line-height(18,28);
			margin-bottom: 1.6rem;
		}

		.link{
			display: flex;
			align-items: center;
			@include font-size(18);
			@include line-height(18,21);
			font-weight: 500;
			margin-bottom: 1.04rem;
			text-decoration: none;

			&:hover,
			&:focus-visible{
				text-decoration: underline;
			}
			
			&.direction-link{
				margin-bottom: 3.35rem;

				svg{
					aspect-ratio: 13.15/19.73;
					width: 1.315rem;
				}
			}

			&.tel-link{

				svg{
					aspect-ratio: 15.37/15.29;
					width: 1.537rem;

				}
			}

			&.fax-link{

				svg{
					aspect-ratio: 17.133/16.837;
					width: 1.7133rem;
				}
			}

			&.email-link{

				svg{
					aspect-ratio: 17.012/12.286;
					width: 1.7012rem;
				}
			}

			i{
				margin-right: .7rem;
				width: 2.2rem;
				display: flex;
				justify-content: center;

				svg{

					*{
						fill: $red;
					}
				}
			}
		}

		.hours{
			margin-top: 4rem;
		}

		.social{
			margin-top: 4.5rem;

			a{
				@include font-size(22);

				&:hover,
				&:focus-visible{
					color: $blue-light;
				}

				&:has(+ a){
					margin-right: 1.8rem;
				}
			}
		}
	}

	.col-md-6{

		@media (min-width: $md + 1){
			padding-left: 5rem;
			padding-right: 7.6rem;
		}

		@media (max-width: $md){
			margin-top: 2rem;
		}

		h4.title{
			color: $red;
			font-weight: 600;
			margin-top: .5em;
			margin-bottom: .8em;

			@media (max-width: $md){
				@include font-size(25);
				margin-bottom: 0.3em;
			}
		}

		.text{

			@media (max-width: $md){
				max-height: 64.8rem;
				overflow: hidden;
				position: relative;

				&:after{
					content: 'Read more';
					padding: 16rem 0 1rem;
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 80%);
					text-align: center;
					text-decoration: underline;
					color: $blue-light;
					cursor: pointer;
				}

				&.open{
					max-height: 999999rem;

					&:after{
						display: none;
					}
				}
			}
		}

		p{
			margin-bottom: 1.6em;

			&.medium{
				@include line-height(18,28);
			}
		}

		.video-con{
			margin: 4.2rem auto 5rem;
			width: 96.8rem;
			border-radius: .5rem;
			overflow: clip;

			@media (max-width: $md){
				margin: 6.4rem auto 3.9rem;
			}

			a{
				position: relative;
				display: block;
	
				&:hover,
				&:focus-visible{
					
					i{
	
						&:before{
							content: '';
							background-color: rgba($blue-light,1);
						}
					}
				}
	
				i{
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 4.4rem;
					height: 4.4rem;
					border-radius: 8.2rem;
					overflow: clip;
	
					&:before{
						content: '';
						position: absolute;
						width: 100%;
						height: 100%;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						width: 100%;
						background-color: rgba($white,.33);
					}
	
					&:after{
						content: '';
						position: absolute;
						top: 50%;
						left: 55%;
						transform: translate(-50%, -50%);
						border-style: solid;
						border-top: .8rem solid transparent;
						border-bottom: .8rem solid transparent;
						border-left: 1.7rem solid #F8F8F8;
						border-right: 0;
					}
				}
			}
		}

		blockquote{
			@include line-height(40,50);
			color: $blue-light;
			padding-left: 0;

			&:before{
				position: static;
			}
		}
	}
}

.customers-section{
	background-color: $blue-light;

	.container{
		max-width: 114rem;
	}

	.row{
		padding-top: 8.7rem;
		padding-bottom: 9.8rem;
	}

	*{
		color: $white;
	}

	.title-con{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 8.6rem;

		h2{
			margin-bottom: 0;
		}
	}

	.customers-con{
		min-height: 38.5rem;
	}
}

.spotlight-section{
	background-color: $red!important;

	.container{
		max-width: 131.2rem;
	}

	.row{
		padding-top: 8.7rem;
		padding-bottom: 7rem;

		@media (max-width: $md){
			padding-top: 4rem;
			padding-bottom: 4rem;
		}
	}

	*{
		color: $white;
	}

	.title-con{
		margin-bottom: 6.1rem;

		@media (max-width: $md){
			margin-bottom: 3.5rem;

			h2{
				@include font-size(30);
			}
			
		}
	}

	.spotlight{
		margin-bottom: 1.5rem;

		.inner{
			background-color: $red-dark;
			display: flex;
			align-items: center;
			padding: 2.9rem 3.5rem 2.2rem 3rem;
			border-radius: .3rem;
			justify-content: space-between;
			position: relative;
			transition: $time;

			@media (max-width: $md){
				flex-wrap: wrap;
			}

			&:has(a:hover),
			&:has(a:focus-visible){
				background-color: $white;

				*{
					color: $red;
				}
			}

			*{
				transition: $time;
			}

			a{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 3;

				&:hover,
				&:focus-visible{
					
					~ .right{

						.btn{
							background-color: $red;
							border-color: $red;
							color: $white!important;

							i{

								svg{
					
									*{
										fill: $white;
									}

									&:nth-of-type(2){
										transform: translate(.15rem, -.15rem);
									}
								}
							}
						}
					}
				}
			}

			.date{
				margin-bottom: .8rem;
			}

			h4{
				font-weight: 600;
			}

			.left{
				flex: 1 1;
				padding-right: 2rem;

				@media (max-width: $md){
					flex: 1 1 100%;
					padding-right: 0;
				}
			}

			.right{

				@media (max-width: $md){
					flex: 1 1 100%;
					margin-top: 1rem;

					.btn{
						width: 100%;
					}
				}
			}
		}
	}
}

.contact-broker-section{
	background-color: $blue-lighter!important;
	min-height: 55rem;
	
	&:not(.blue-light){

		.container{
			max-width: 122.8rem;
		}

		.row{
			padding-top: 14.3rem;
			padding-bottom: 10.8rem;

			@media (max-width: $md){
				padding-top: 6.1rem;
				padding-bottom: 4.1rem;
			}
		}

		.title-side{

			@media (min-width: $md + 1){
				flex: 0 0 41.2%;
				max-width: 41.2%;
			}

			@media (max-width: $md){
				margin-bottom: 4rem;
			}

			h1{
				color: $blue-dark;
				font-weight: 600;

				@media (max-width: $md){
					@include font-size(40);
					@include line-height(40,45);
				}

				em{
					color: $blue-light;
					font-style: normal;
				}
			}

			.tel-link{
				margin-top: 6.7rem;

				@media (max-width: $md){
					margin-top: 3.9rem;
					@include font-size(24);

					i{
						width: 2.215rem;
					}
				}
			}
		}

		.form-side{

			@media (min-width: $md + 1){
				flex: 0 0 53.1%;
				max-width: 53.1%;
			}
		}
	}

	// .gform_confirmation_message{
	// 	position: fixed;
	// 	top: 13rem;
	// 	left: 50%;
	// 	transform: translateX(-50%);
	// 	padding: 5.0rem;
	// 	background-color: #fff;
	// 	border-left: 4.5rem solid #2b99d9;
	// 	z-index: 999;
	// 	color: $blue-dark;

	// 	@media (max-width: $md){

	// 		top: 12rem;
	// 		padding: 6.5rem 4.0rem;
	// 		border-left: 0;
	// 		width: 70vw;
	// 	}
	// }

	.gform_confirmation_wrapper {
		position: relative;
		&:after {
		  content: "";
		  position: fixed;
		  top: 0;
		  right: 0;
		  bottom: 0;
		  left: 0;
		  z-index: 99;
		  background-color: #f2faff;
		  backdrop-filter: blur(.5rem);
		  opacity: 0.8;
		}
		.gform_confirmation_message {
		  position: fixed;
		  top: 13rem;
		  left: 50%;
		  transform: translateX(-50%);
		  padding: 5rem;
		  background-color: #fff;
		  border-left: 4.5rem solid #2b99d9;
		  z-index: 999;
		  color: $blue-dark;
		  @media (max-width: $md){
			top: 12rem;
			padding: 6.5rem 4rem 4.6rem;
			border-left: 0;
			width: 84vw;
		  }
		}
		.form_popup_close {
		  position: absolute;
		  top: 2.5rem;
		  right: 2.5rem;
		  width: 2.2rem;
		  height: 2.2rem;
		  background-image: url(../images/popup-close-icon.svg);
		  background-size: contain;
		  background-position: center;
		  background-repeat: no-repeat;
		}
		h2 {
		  font-size: 5rem;
		  line-height: 6rem;
		  margin-bottom: 5rem;
		  @media (max-width: $md){
			font-size: 3rem;
			line-height: 3.5rem;
			margin-bottom: 3rem;
		  }
		}
		p {
		  font-size: 2rem;
		  line-height: 3rem;
		  color: #002a57;
		  @media (max-width: $md){
			font-size: 1.6rem;
			line-height: 1.9rem;
		  }
		}
	  }

	.back-con{
		margin-top: 15.8rem;

		@media (max-width: $md){
			margin-top: 9.5rem;
		}

		a{
			display: flex;
			align-items: center;
			@include font-size(18);
			text-decoration: none;

			@media (max-width: $md){
				@include font-size(16);
			}

			&:hover,
			&:focus-visible{
				font-weight: 500;

				svg{
					transform: translateX(-1.8rem);
				}
			}

			svg{
				aspect-ratio: 33.282/12.905;
				width: 3.3282rem;
				margin-right: 1.8rem;
				margin-left: .8rem;

				@media (max-width: $md){
					margin-left: .1rem;
				}
			}
		}
	}

	.container{
		max-width: 121.2rem;
	}

	.row{
		padding-top: 9.8rem;
		padding-bottom: 8.1rem;

		@media (max-width: $md){
			padding-top: 4.9rem;
			padding-bottom: 6.4rem;
		}
	}

	.title-side{

		@media (max-width: $md){
			
			h1{

				br{
					display: none;
				}
			}
		}

		*{
			color: $white;
		}

		.tel-link{
			display: flex;
			@include font-size(40);
			text-decoration: none;
			align-items: center;
			font-weight: 600;
			color: $blue-dark;
			margin-top: 8.1rem;

			@media (max-width: $md){
				@include font-size(25);
				margin-top: 4.1rem;
				margin-bottom: 4rem;
			}

			&:hover,
			&:focus-visible{
				text-decoration: underline;
			}
	
			i{
				aspect-ratio: 30.83/30.68;
				width: 3.083rem;
				margin-right: 1.4rem;

				@media (max-width: $md){
					width: 2.03rem;
				}
	
				svg{
					width: 100%;

					*{
						fill: $blue-dark;
					}
				}
			}
		}
	}


	&.blue-light{
		background-color: $blue-light!important;

		.text-side{

			*{
				color: $white;
			}

			h1{
				font-weight: 600;
			}

			h3{

				a{
					color: $blue-dark;
				}
			}
		}

		.form-side{

			.gfield{

				::-webkit-input-placeholder {
					color: $white;
				}
				 
				:-moz-placeholder { /* Firefox 18- */
					color: $white;
				}
				
				::-moz-placeholder {  /* Firefox 19+ */
					color: $white;
				}
				
				:-ms-input-placeholder {
					color: $white;
				}
	
				.gfield_label{
					border-bottom-color: rgba($white,.4);
				}

				input, 
				input.input-text, 
				textarea, 
				.fake-select, 
				select{
					border-bottom-color: rgba($white,.4);
					color: $white;
				}

				select{
					color: $blue-dark;
					background-image: url(../images/select-icon-white.svg);

					.gf_placeholder{
						color: $blue-dark;
					}
				}
			}

			.gform_footer{
			
				[type="submit"]{
					border-color: $blue-dark;
					background-color: $blue-dark;
	
					&:hover,
					&:focus-visible{
						border-color: $blue;
						background-color: $blue;
					}
				}
			}
		}
	}

	h1{
		font-weight: 500;
	}

	.form-side{

		.gfield{

			.gfield_label{
				width: auto;
				padding: .8rem 0rem;
				@include font-size(16);
				line-height: 1.417;
				border: .1rem solid rgba($blue,.4);
				border-top: none;
				border-left: none;
				border-right: none;
				margin-bottom: 0;
				padding-right: .5rem;
				font-weight: 400;

				@media (max-width: $md){
					padding: 0.79rem 0rem;
				}

				.gfield_required{
					display: none;
				}
			}

			.gfield_validation_message{
				width: 100%;
			}
	
			.ginput_container{
				width: auto;
				flex-grow: 1;

				@media (max-width: $md){
					flex: 1;

					&.ginput_container_textarea{
						flex: auto;
					}
				}
			}

			select{
				color: $blue-dark;

				.gf_placeholder{
					color: $blue-dark;
				}
			}
		}

		.gform_footer{
			margin-top: .5rem;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row-reverse;

			[type="submit"]{
				border-color: $blue-light;
				background-color: $blue-light;
				padding-left: 2.4rem;
				padding-right: 2.4rem;

				&:hover,
				&:focus-visible{
					border-color: $blue;
					background-color: $blue;
				}
			}
		}
	}
}

.get-the-latest-section{

	.container{
		max-width: 131.6rem;
	}

	.row{
		padding-top: 8.5rem;
		padding-bottom: 11.5rem;
	}

	.title-con{
		margin-bottom: 4.2rem;
	}

	.get-slide-con{

		.swiper-wrapper-con{
			overflow: visible!important;
		}

		.latest-slide{
			width: calc(100% + 1.25rem + 1.25rem);
			max-width: calc(100% + 1.25rem + 1.25rem);
			margin-left: -1.25rem;
		}

		.swiper-slide{
			padding: 0 1.25rem;
			margin-bottom: 0;
		}

		.swiper-button-next, 
		.swiper-button-prev{
			bottom: -3.5rem;
		}

		.swiper-scrollbar{
			bottom: -2.9rem;
		}
	}
}


.connect-with-a-broker-section{
	position: relative;

	.filter-top{
		background-color: $blue-dark;
		position: absolute;
		top: 0rem;
		left: 0;
		width: 100%;
		z-index: 4;
		transition: $time;

		&.open{

			.top-top{
				display: none;
			}

			.under{
				max-height: 50rem;
			}
		}

		&:has(.facetwp-facet-broker_insurance_type [value="personal-insurance"]:checked){
			
			.filter-item.select.specialty{
				display: none;
			}

			.filter-item.select.coverage{
				display: block;
			}

			&:has(.coverage [value="vehicle-insurance"]:checked){

				button.btn{
					display: none;
				}

				a.btn.vehicle-insurance{
					display: inline-flex;
				}
			}

			&:has(.coverage [value="individual-insurance"]:checked){

				button.btn{
					display: none;
				}

				a.btn.individual-insurance{
					display: inline-flex;
				}
			}

			&:has(.coverage [value="personal-life"]:checked){

				button.btn{
					display: none;
				}

				a.btn.personal-life{
					display: inline-flex;
				}
			}

			&:has(.coverage [value="high-net-worth"]:checked){

				button.btn{
					display: none;
				}

				a.btn.high-net-worth{
					display: inline-flex;
				}
			}

			&:has(.coverage [value="property-insurance"]:checked){

				button.btn{
					display: none;
				}

				a.btn.property-insurance{
					display: inline-flex;
				}
			}

			&:has(.coverage [value="medicare-insurance"]:checked){

				button.btn{
					display: none;
				}

				a.btn.medicare-insurance{
					display: inline-flex;
				}
			}
		}

        &:has(.facetwp-facet-broker_insurance_type [value="employee-benefits"]:checked){
			.filter-item.select.specialty{
				display: none;
			}
        }

		.top-top{
			display: flex;
			justify-content: center;
			align-items: center;
			color: $white;
			padding: 1rem 1rem;
			width: 100%;
			cursor: pointer;

			@media (max-width: $md){
				padding: .8rem 1rem;
			}

			svg{
				aspect-ratio: 13.61/6.81;
				width: 1.521rem;
				margin-left: 2.3rem;

				*{
					fill: $blue-light;
				}
			}
		}

		.under{
			overflow: hidden;
			max-height: 0;
			transition: $time;
			max-width: 130.2rem;
			margin: 0 auto;
		}

		a.btn{
			display: none;
		}

		.coverage{
			display: none;
			
			select{
				margin-bottom: 1rem;
			}
		}
		
		.top{
			padding: 8.88rem 5.31rem 3.52rem;
			position: relative;

			@media (max-width: $md){
				padding: 3.78rem 3.21rem 4.72rem;
			}
	
			i.arrow{
				position: absolute;
				top: 6rem;
				transform: rotateX(0deg) translateY(-50%);
				transform-origin: 50% 0%;
				right: 4.6rem;
				aspect-ratio: 20.789/11.455;
				width: 2.0789rem;
				backface-visibility: visible!important;
				transition: $time;
				cursor: pointer;

				&:hover{
					
					svg{

						*{
							fill: $white;
						}
					}
				}
	
				svg{
					width: 100%;

					*{
						fill: $blue-light;
						transition: $time;
					}

					&:nth-of-type(2){
						aspect-ratio: 22.857/23.635;
						width: 2.2857rem;
						display: none;
					}
				}
			}

			h5{
				color: $blue-light;
				font-weight: 400;
				margin-bottom: .48em;
			}

			h2{
				color: $white;
				margin-bottom: 0;
			}
		}

		.bottom{
			display: flex;
			flex-wrap: wrap;
			align-items: flex-end;
			padding: 0 5.31rem 8.12rem;
			transition: $time;
			left: 0;
			width: 100%;
			background-color: $blue-dark;

			@media (max-width: $md){
				padding: 0 3.01rem 2.92rem;
			}

			.btn{
				margin-bottom: 1rem;
				border-radius: 0;
				padding: .834rem 2.45rem;
				margin-left: 1rem;

				@media (max-width: $md){
					margin-left: 0;
					width: 100%;
				}
			}

			[data-type="search"]{

				.facetwp-icon{
					aspect-ratio: 15.743/14.443;
					width: 1.5743rem;
					height: auto;
					top: 50%;
					transform: translateY(-50%);
					right: 1.6rem;
					opacity: 1;
					transition: $time;
					@include font-size(0);
					
					&:hover,
					&:focus-visible{
						opacity: .4;
					}
		
					&:before{
						background-image: url(../images/location-search.svg);
						background-repeat: no-repeat;
						aspect-ratio: 15.743/14.443;
						background-size: 100% auto;
						width: 1.5743rem;
						height: auto;
						background-position: 50% 50%;
					}
				}

				input{
					padding-right: 4rem;
				}
			}

			.filter-item{
				flex: 1 1;

				@media (max-width: $md){
					flex: 1 1 100%;
					margin-bottom: .5rem;
				}

				&:has(+ .filter-item){
					margin-right: 1rem;

					@media (max-width: $md){
						margin-right: 0;
					}
				}

				&.search{
					min-width: 65.803rem;
				}
			}

			.facetwp-icon.locate-me{
				aspect-ratio: 15.743/14.443;
				width: 1.5743rem;
				height: auto;
				top: 50%;
				transform: translateY(-50%);
				right: 1.6rem;
				opacity: 1;
				transition: $time;
				@include font-size(0);

				+ input{
					padding-right: 4rem;
				}
				
				&:hover,
				&:focus-visible{
					opacity: .4;
				}
	
				&:before{
					background-image: url(../images/location-search.svg);
					background-repeat: no-repeat;
					aspect-ratio: 15.743/14.443;
					background-size: 100% auto;
					width: 1.5743rem;
					height: auto;
					background-position: 50% 50%;
				}
			}

			label{
				color: $blue-light;
				width: auto;
				font-weight: 400;
				@include font-size(16);
				margin-bottom: .9rem;

				@media (max-width: $md){
					@include font-size(14);
				}
			}

			.facetwp-facet{
				flex: 1 1;

				@media (max-width: $md){
					flex: 1 1 100%;
				}

				&:has(+ .facetwp-facet){
					margin-right: 3.8rem;

					@media (max-width: $md){
						margin-right: 0;
					}

				}
			}

			::-webkit-input-placeholder {
				@include font-size(18);
				color: $white;
			}
			 
			:-moz-placeholder { /* Firefox 18- */
				@include font-size(18);
				color: $white;
			}
			
			::-moz-placeholder {  /* Firefox 19+ */
				@include font-size(18);
				color: $white;
			}
			
			:-ms-input-placeholder {
				@include font-size(18);
				color: $white;
			}

			input{
				background-color: #094172;
				border: .1rem solid #094172;
				color: white;
				padding: .617rem 1.2rem;
				@include font-size(18);
				border-radius: .3rem;
			}

			.facetwp-input-wrap{
				width: 100%;
			}

			select{
				background-color: #094172;
				border: .1rem solid #094172;
				@include font-size(18);
				color: white;
				border-radius: .3rem;
				line-height: 3.9rem - .1rem - .1rem;
				height: 3.9rem;
				padding-left: 1rem;
				padding-right: 3.8rem;
				background-position: right 1.75rem top 50%;

				option{
					color: $colour;
					background-color: $white;
				}
			}

			.facetwp-facet{
				margin-bottom: 1rem;
			}
		}
	}


	.find-con.find-con{
		margin-top: 2.5rem;
		
		@media (min-width: $md + 1){
			padding-left: 3.8rem;
			padding-right: 3.8rem;
		}

		.inner{
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 1.4rem 3rem;
			background-color: $blue-light;

			@media (max-width: $md){
				padding: 2.8rem 2.4rem 3.2rem;
				flex-wrap: wrap;
				justify-content: center;

				.btn{
					width: 95%;
					margin-top: 3rem;
				}
			}

			p{
				margin-bottom: 0;
			}

			*{
				color: $white;
			}
		}
	}

	.container{
		max-width: 128.8rem;
	}

	.row{
		padding-top: 12.4rem;
		padding-bottom: 5.7rem;

		@media (max-width: $md){
			padding-top: 7.7rem;
			padding-bottom: 1.1rem;
		}
	}

	@media (min-width: $md + 1){
		
		.container,
		.col-12,
		.col-6{
			padding-right: 3.5rem;
			padding-left: 3.5rem;
		}

		.row{
			margin-right: -3.5rem;
			margin-left: -3.5rem;
		}
	}

	.facetwp-template{
		display: flex;
		flex-wrap: wrap;
		width: 100%;
	}

	.text-con{
		text-align: center;
		margin-bottom: 8.4rem;

		@media (max-width: $md){
			margin-bottom: 5.6rem;
		}

		a{
			color: $blue-light;
		}

		br{

			@media (max-width: $md){
				display: none;
			}
		}

		p{

			@media (max-width: $md){
				padding-left: .5rem;
				padding-right: .5rem;
			}
		}
	}

	h1{
		margin-bottom: .25em;

		@media (max-width: $md){
			@include font-size(40);
			@include line-height(40,45);
			margin-bottom: .55em;
		}
	}
}

.search-section{
	opacity: 0;
	visibility: hidden;
	position: fixed;
	top: 10rem;
	left: 0;
	width: 100%;
	z-index: 6;
	transition: $time;

	@media (max-width: $md){
		top: 6.8rem;
	}

	&.open{
		opacity: 1;
		visibility: visible;
	}

	.container{
		max-width: 100%;
		
		@media (max-width: $md){
			padding-left: .4rem;
			padding-right: .4rem;
		}
	}

	.row{
		height: calc(100dvh - 10rem);

		@media (max-width: $md){
			height: calc(100dvh - 6.8rem);
			align-items: flex-start;
		}
	}

	.search-side{
		background-color: $blue-dark;
		padding: 8.7rem 5.2rem;

		@media (min-width: $md + 1){
			flex: 0 0 42.8%;
			max-width: 42.8%;
		}

		@media (max-width: $md){
			padding: 7.2rem 3.2rem 3.6rem;
			min-height: 25.3rem;

			& + .results-side{
				max-height: calc(100dvh - 25.3rem - 6.735rem);
				height: calc(100dvh - 25.3rem - 6.735rem);

				.results{
					max-height: calc(100dvh - 45.4rem);
				}
			}

			&:has(.top.open){
				min-height: 42.744rem;

				& + .results-side{
					max-height: calc(100dvh - 42.744rem - 6.735rem);

					.results{
						max-height: calc(100dvh - 62.8rem);
					}
				}
			}
		}

		.facetwp-facet-search{

			.facetwp-input-wrap{
				width: 100%;
			}

			.facetwp-icon{
				aspect-ratio: 18.87/17.2;
				width: 1.987rem;
				height: auto;
				top: 50%;
				transform: translateY(-50%);
				right: .5rem;
				opacity: 1;
				transition: $time;
				@include font-size(0);

				@media (max-width: $md){
					right: 0;
					top: 55%;
				}

				+ input{
					padding-right: 4rem;
				}
				
				&:hover,
				&:focus-visible{
					
					&:before{
						background-image: url(../images/location-search-red.svg);
					}
				}
	
				&:before{
					background-image: url(../images/location-search.svg);
					background-repeat: no-repeat;
					aspect-ratio: 18.87/17.2;
					background-size: 100% auto;
					width: 1.987rem;
					height: auto;
					background-position: 50% 50%;
					transition: $time;
				}
			}

			::-webkit-input-placeholder {
				@include font-size(30);
				color: rgba($white,.5);

				@media (max-width: $md){
					@include font-size(20);
					color: rgba($white,1);
				}
			}
			 
			:-moz-placeholder { /* Firefox 18- */
				@include font-size(30);
				color: rgba($white,.5);

				@media (max-width: $md){
					@include font-size(20);
					color: rgba($white,1);
				}
			}
			
			::-moz-placeholder {  /* Firefox 19+ */
				@include font-size(30);
				color: rgba($white,.5);

				@media (max-width: $md){
					@include font-size(20);
					color: rgba($white,1);
				}
			}
			
			:-ms-input-placeholder {
				@include font-size(30);
				color: rgba($white,.5);

				@media (max-width: $md){
					@include font-size(20);
					color: rgba($white,1);
				}
			}

			input{
				@include font-size(30);
				color: $blue-light;
				border-bottom-color: $white;
				padding: .9rem 0;
			}
		}

		.suggested{
			margin-top: 11.1rem;

			@media (max-width: $md){
				margin-top: 4.2rem;
			}

			.top{
				@include font-size(18);
				@include line-height(18,24);
				font-weight: 600;
				color: $blue-light;
				margin-bottom: 1.95rem;

				

				@media (max-width: $md){
					@include font-size(16);
					display: flex;
					align-items: center;

					&.open{

						& + .bottom{
							max-height: 9999px;
						}

						&:after{
							transform: rotateX(0deg);
						}
					}

					& + .bottom{
						max-height: 0;
						overflow: hidden;
						transition: $time;
					}

					&:after{
						content: '';
						aspect-ratio: 12.552/6.983;
						background-image: url(../images/menu-arrow-open.svg);
						background-repeat: no-repeat;
						background-size: 1.2552rem auto;
						backface-visibility: visible!important;
						transform: rotateX(180deg);
						margin-left: 2rem;
						transition: $time;
						width: 1.2552rem;
					}
				}
			}

			.link-con{

				.link{
					@include font-size(22);
					@include line-height(22,35);
					color: $white;
					text-decoration: none;
					font-weight: 400;

					@media (max-width: $md){
						@include font-size(20);
						@include line-height(20,35);
					}

					&:hover,
					&:focus-visible{
						color: $blue-light;
					}
				}
			}

		}
	}

	.results-side{
		background-color: $blue-lighter;
		padding: 10rem 5rem 0;
		

		@media (max-width: $md){
			padding: 3.6rem 3.1rem 0;
			max-height: calc(100dvh - 42.744rem - 6.735rem);
		}

		@media (min-width: $md + 1){
			flex: 0 0 57.2%;
			max-width: 57.2%;
		}

		h3{
			font-weight: 400;

			@media (max-width: $md){
				@include font-size(20);
				@include line-height(20,25);
				margin-bottom: 1.7rem;

			}
		}

		.results{
			margin-top: 6.6rem;
			overflow: auto;
			max-height: calc(100dvh - 33.5rem);

			@media (max-width: $md){
				margin-top: 3.9rem;
				max-height: calc(100dvh - 62.8rem);
			}
		}

		.facetwp-counts{

			@media (max-width: $md){
				@include font-size(14);
				@include line-height(14,16);
			}
		}

		.se-item{
			padding-bottom: 2.3rem;
			margin-bottom: 2.3rem;
			border-bottom: 1px solid rgba($blue-light,.4);

			&.Brokers{

				.img-side{
					border-radius: 50%;
				}
			}

			@media (max-width: $md){
				padding-bottom: 1.9rem;
				margin-bottom: 1.9rem;
			}

			a{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;

				&:hover,
				&:focus-visible{

					~ .img-side{
	
						.img-con{
							transform: scale(1.1);
						}

					}
					
					~ .text-side{

						&:before{
							transform: translateX(-1rem);
						}

						h4{
							color: $blue-light;
						}
					}
				}
			}

			.inner{
				display: flex;
				flex-wrap: wrap;

				.type{
					width: 100%;
					margin-bottom: 1rem;

					@media (max-width: $md){
						@include font-size(14);
						@include line-height(14,16);
						margin-bottom: .6rem;

						&:has(+ .img-side){
							margin-bottom: .8rem;
						}
					}
				}

				.img-side{
					width: 19.8%;
					margin-right: 1.8rem;
					overflow: hidden;
					aspect-ratio: 1/1;
					align-self: start;

					@media (max-width: $md){
						width: 11rem;
						margin-right: 1.6rem;
					}

					.img-con{
						aspect-ratio: 1/1;
						object-fit: cover;
						object-position: 50% 50%;
						transition: $time;
						transform: scale(1);
					}

					+ .text-side{
						width: calc(100% - 19.8% - 1.8rem);

						@media (max-width: $md){
							width: calc(100% - 11rem - 1.6rem);
							padding-right: 0rem;
							padding-bottom: 2.2rem;

							p{
								display: none;
							}

							&:before{
								right: auto;
								left: 0
							}

							h4{
								@include font-size(18);
								@include line-height(18,22);
							}
						}
					}
				}

				.text-side{
					width: 100%;
					padding-right: 5rem;
					position: relative;
					align-self: stretch;

					&:before{
						content: '';
						position: absolute;
						bottom: 0rem;
						right: .3rem;
						background-image: url(../images/slide-next.svg);
						background-repeat: no-repeat;
						background-size: 100% auto;
						aspect-ratio: 33.3/12.9;
						width: 3.33rem;
						transform: translateX(0);
						transition: $time;
					}

					h4{
						margin-bottom: 0;
						transition: $time;
						font-weight: 600;

						@media (max-width: $md){
							@include font-size(20);
							@include line-height(20,30);
						}
					}

					p{
						margin-top: 1.8rem;

						@media (max-width: $md){
							margin-top: 1.3rem;
						}
					}
				}
			}
		}
	}
}


.simple-text-section{

	.row{
		padding-top: 10.1rem;
		padding-bottom: 10.1rem;

		@media (max-width: $md){
			padding-top: 4.3rem;
			padding-bottom: 4.3rem;
		}
	}
}






[data-offset]{
	@media (max-width: $md){
		transform: none!important;
	}
}


.four-zero-four-section{
	
	.row{
		padding-top: 5rem;
		padding-bottom: 5rem;
		text-align: center;

		@media (min-width: $md + 1){
			min-height: calc(100dvh - 13.906rem - 79.602rem);
		}
	}
}

//
//!!BLOCKS END
//

@media (min-width: $md + 1){
	
	.container{
		max-width: ($base-widthWO + $grid-gutter-widthWO) / 10 + rem;
	}

	.container,
	.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto{
		padding-right: $grid-gutter-widthWO / 2 / 10 + rem;
		padding-left: $grid-gutter-widthWO / 2 / 10 + rem;
	}

	.row{
		margin-right: -$grid-gutter-widthWO / 2 / 10 + rem;
		margin-left: -$grid-gutter-widthWO / 2 / 10 + rem;
	}
}

@media (max-width: $md){

	.container,
	.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto{
		padding-right: .4rem;
		padding-left: .4rem;
	}

	.container{
		padding-right: 3.1rem;
		padding-left: 3.1rem;
	}

	.row{
		margin-right: -.4rem;
		margin-left: -.4rem;
	}
}


.parallax-mirror.parallax-mirror.parallax-mirror.parallax-mirror.parallax-mirror.parallax-mirror{

	@media (max-width: $md){
		
		.parallax-slider{
			// width: 100vw!important;
			// max-width: 100vw!important;
			object-fit: cover;
			object-position: -60000% 0;
		}
	}
}