// TABS
// <div class="tab-container">
// 	<div class='tabs'>
// 		<div class="tab active" rel="tab1">Tab1</div>
// 		<div class="tab" rel="tab2">Tab2</div>
// 	</div>

//	<div class="tab-header active" rel="tab1">Tab1</div>
// 	<div id="tab1" class="tab-content active">
// 		<div class="inner">
// 			content here
// 		</div>
// 	</div>

//	<div class="tab-header" rel="tab2">Tab2</div>
// 	<div id="tab2" class="tab-content">
// 		<div class="inner">
// 			content here
// 		</div>
// 	</div>
// </div>

// $(document).on('click', '.tabs .tab, .tab-header', function() {
// 	var thisOne = $(this);
// 	thisOne.parents('.tab-container').find('.tab-content, .tabs .tab, .tab-header').removeClass('active');
// 	var activeTab = $(this).attr("rel");
// 	thisOne.parents('.tab-container').find('#'+activeTab).addClass('active');
// 	$(this).addClass('active');
// 	thisOne.parents('.tab-container').find('.tabs .tab[rel^='+activeTab+'], .tab-header[rel^='+activeTab+']').addClass('active');

// 	setTimeout(function(){
// 		Waypoint.refreshAll();
// 	}, 500);
// });

////hash tabs
//
////add hash to url
//$('ul.tabs li').click(function(event) {
//	var activeTab = $(this).attr("rel");
//	window.location.hash = 'tab-' + activeTab;
//});
//
//checks for new hash
//$(window).on('hashchange',function(){
//	var newHash = location.hash.slice(5);
//	$('.tab-container .tabs li').removeClass('active')
//	$('.tab-container .tab-content').removeClass('active')
//	$("#"+newHash).addClass('active');
//	$("ul.tabs li[rel='"+newHash+"']").addClass("active");
//});
//
////on load check for a hash
//var hashLoad = window.location.hash.slice(5);
//if(hashLoad){
//	$('.tab-container .tabs li').removeClass('active')
//	$('.tab-container .tab-content').removeClass('active')
//	$("#"+hashLoad).addClass('active');
//	$("ul.tabs li[rel='"+hashLoad+"']").addClass("active");
//}

.tab-container.tab-container{
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;

	@media (max-width: $md){
		padding-bottom: 0;
	}

	&.alt{

		.tabs{

			.tab{

				span{
					width: auto;
					max-width: calc(100% - 3.328rem - 2rem);
				}

				i{
					margin-right: 0!important;
					margin-left: 2rem;
				}
			}
		}
	}

	.tabs{
		text-align: center;
		width: 31.35%;
		position: relative;
		position: sticky;
		top: 14.3rem;

		@media (min-width: $md + 1){
			margin-bottom: 1.7rem;
		}

		@media (max-width: $md){
			display: none;
		}

		.tab{
			padding: 1.95rem 0;
			@include font-size(30);
			text-decoration: none!important;
			line-height: 1;
			text-align: left;
			text-decoration: none;
			cursor: pointer;
			transition: $time;
			font-weight: 600;
			margin: 0;
			color: $colour;
			position: relative;
			display: flex;
			align-items: center;
			cursor: pointer;
			border-bottom: 1px solid rgba($blue-light, .4);

			i{
				max-width: 0;
				overflow: hidden;
				display: flex;
				justify-content: flex-end;
				margin-right: 0rem;
				transition: $time;
	
				svg{
					aspect-ratio: 33.28/12.91;
					width: 3.328rem;
					max-width: 3.328rem;
					min-width: 3.328rem;
					height: 1.291rem;

					*{
						fill: $red;
					}
				}
			}

			span{
				width: calc(100% - 3.328rem - 1rem);
			}

			&.active{
				color: $red;
				cursor: default;

				i{
					margin-right: 1rem;
					max-width: 3.328rem;
				}
			}

			&:hover,
			&:focus-visible{
				
				&:not(.active){
					color: $red;
				}
			}
		}
	}

	.tab-contents{
		width: 68.65%;

		@media (min-width: $md + 1){
			padding-left: 10.9rem;
		}

		@media (max-width: $md){
			width: 100%;
		}
	}

	.tab-header{
		@include font-size(25);
		font-weight: 600;
		cursor: pointer;
		position: relative;
		padding: 1.5rem 3rem 1.5rem 0;

		@media (min-width: $md + 1){
			display: none;
		}

		&.active{
			color: $red;

			&:after{
				transform: rotateX(180deg);
			}
		}

		&:after{
			content: '';
			position: absolute;
			top: 2.5rem;
			transform: rotateX(0deg);
			transform-origin: 50% 0%;
			right: .3rem;
			aspect-ratio: 12.905/7.513;
			width: 1.2905rem;
			backface-visibility: visible!important;
			transition: $time;
			background-image: url(../images/acc-arrow.svg);
			background-repeat: no-repeat;
			transform-origin: 0 50%;
			
			svg{
				width: 100%;
			}
		}
	}

	.tab-content{
		max-height: 0;
		overflow: hidden;

		@media (max-width: $md){
			//margin-bottom: .1rem;
		}

		&.active{
			max-height: 9999px;
		}

		.inner{
			padding: 1.7rem 0;
			width: 100%;
			max-width: 100%;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			margin: 0 auto;

			@media (max-width: $md){
				padding: .9rem 0 1.8rem;
			}
		}

		h4{
			@include line-height(25,32);
		}
	}
}